
// @mui icons
import Icon from "@mui/material/Icon";
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';

// Images
import UserProfileSetting from "layouts/basicSetting/accountProfileSetting";
import GlobalVarSetting from "./layouts/basicSetting/globalVarSetting";
import ProductBasicSetting from "./layouts/product/productBasicSetting";
import OrderBasicSetting from "./layouts/order/orderBasicSetting";
import FaultReportSetting from "./layouts/faultReport/faultReportSetting"
import PaymentMethodSetting from "./layouts/paymentMethod/paymentMethodSetting";
import ReserveBasicSetting from "./layouts/reserve/reserveBasicSetting";
import HandOverSetting from "./layouts/handOver/handOverSetting";

import RoomBasicSetting from "./layouts/product/roomBasicSetting";
import MainProductsBasicSetting from "./layouts/product/mainProductsSetting";

import AccessControlBasicSetting from "./layouts/accessControl/accessControlBasicSetting";
import DeviceControlBasicSetting from "./layouts/deviceControl/deviceControlBasicSetting";

import TicketBasicSetting from "./layouts/ticket/ticketBasicSetting";
import SalesChannelBasicSetting from "./layouts/ticket/salesChannelBasicSetting";
import SmartBookingSetting from "./layouts/smartBooking/smartBookingSetting";

import OtherProductsBasicSetting from "./layouts/otherProduct/otherProductsBasicSetting";
import PricesBasicSetting from "./layouts/prices/pricesBasicSetting";

import Dashboard from "./layouts/dashboard";
import SalesReport from "./layouts/reportControl/salesReport"
import ResourceSalesReport from "./layouts/reportControl/resourceSalesReport"
import ProductsReport from "./layouts/reportControl/productsReport"
import SellerReport from "./layouts/reportControl/sellerReport"
import SalesTargetReport from "./layouts/reportControl/salesTargetReport"
import QuesAndAnsSetting from "./layouts/quesAndAns/quesAndAnsSetting"
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import Login from "./layouts/login";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop,faTable,faHotel,faScroll,faTicket,faScrewdriverWrench,faSwatchbook,faUsers,faTableList } from '@fortawesome/free-solid-svg-icons';

const routes = [
    {
        type: "collapse",
        name: "即時狀態表",
        key: "dashboard",
        icon: <FontAwesomeIcon icon={faTable} />,
        route: "/dashboard",
        component: <Dashboard />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "訂單處理",
        key: "ordBasicSet",
        icon: <FontAwesomeIcon icon={faTableList} />,
        route: "/systemMang/order/orderBasicSetting",
        component: <OrderBasicSetting/>,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "產品管理",
        key: "productControl",
        icon: <FontAwesomeIcon icon={faHotel} />,
        collapse: [
            {
                name: "房型設定",
                key: "mainProductsSet",
                route: "/systemMang/products/mainProducts",
                component: <MainProductsBasicSetting/>
            },
            {
                name: "房間設定",
                key: "roomBasicSet",
                route: "/systemMang/product/roomBasicSetting",
                component: <RoomBasicSetting/>
            },
            {
                name: "價格設定",
                key: "pricesBasicSet",
                route: "/systemMang/prices/pricesBasicSetting",
                component: <PricesBasicSetting/>
            },
            {
                name: "加價產品設定",
                key: "othPrdBasicSet",
                route: "/systemMang/otherProduct/otherProductsBasicSetting",
                component: <OtherProductsBasicSetting/>
            },
            {
                name: "故障通報",
                key: "falRepSet",
                route: "/systemMang/faultReport/faultReportSetting",
                component: <FaultReportSetting/>
            },
            {
                name: "付款方式設定",
                key: "payMethSet",
                route: "/systemMang/paymentMethod/paymentMethodSetting",
                component: <PaymentMethodSetting/>
            },
            {
                name: "保留設定",
                key: "resBasicSet",
                route: "/systemMang/reserve/reserveBasicSetting",
                component: <ReserveBasicSetting/>
            },
            // {
            //     name: "交班設定",
            //     key: "handOverSet",
            //     route: "/systemMang/handOver/handOverSetting",
            //     component: <HandOverSetting/>
            // },
            {
                name: "常見問題設定",
                key: "quesAndAnsSet",
                route: "/systemMang/quesAndAns/quesAndAnsSetting",
                component: <QuesAndAnsSetting/>
            },

            // {
            //     name: "Illustration",
            //     key: "illustration",
            //     route: "/authentication/sign-in/illustration",
            //     component: <SignInIllustration />,
            // },
            // {
            //     name: "Login",
            //     key: "login",
            //     route: "/login",
            //     component: <Login />,
            // },
            // {
            //     name: "登出",
            //     key: "logout",
            //     route: "/login",
            //     component: <Login />,
            //     // component: <Login />,
            // },
        ],
    },
    {
        type: "collapse",
        name: "門鎖管理",
        key: "accAndDevBasicSet",
        icon: <FontAwesomeIcon icon={faDesktop} />,
        collapse: [
            {
                name: "門鎖設定",
                key: "accCtlBasicSet",
                route: "/systemMang/accessControl/accessControlBasicSetting",
                component: <AccessControlBasicSetting/>
            },
            // {
            //     name: "其他設定",
            //     key: "devCtlBasicSet",
            //     route: "/systemMang/deviceControl/deviceControlBasicSetting",
            //     component: <DeviceControlBasicSetting/>
            // },
        ],
    },
    {
        type: "collapse",
        name: "報表管理",
        key: "reportControl",
        icon: <FontAwesomeIcon icon={faScroll} />,
        collapse: [
            {
                name: "銷售報表",
                key: "salesReport",
                route: "/systemMang/reportControl/salesReport",
                component: <SalesReport/>
            },
            {
                name: "訂單來源銷售額統計報表",
                key: "resourceSalesReport",
                route: "/systemMang/reportControl/resourceSalesReport",
                component: <ResourceSalesReport/>
            },
            {
                name: "業務員銷售額統計報表",
                key: "sellerReport",
                route: "/systemMang/reportControl/sellerReport",
                component: <SellerReport/>
            },
            {
                name: "各館別產品銷售額統計表",
                key: "productsReport",
                route: "/systemMang/reportControl/productsReport",
                component: <ProductsReport/>
            },
            {
                name: "銷售目標統計表",
                key: "salesTargetReport",
                route: "/systemMang/reportControl/salesTargetReport",
                component: <SalesTargetReport/>
            },
            // {
            //     name: "銷售目標統計表",
            //     key: "mangReport",
            //     route: "",
            //     component: <UserProfileSetting/>
            // },
        ],
    },
    // {
    //     type: "collapse",
    //     name: "報表管理",
    //     key: "reportControl",
    //     icon: <FontAwesomeIcon icon={faScroll} />,
    //     collapse: [
    //         {
    //             name: "管理報表",
    //             key: "mangReport",
    //             route: "",
    //             component: <UserProfileSetting/>
    //         },
    //         {
    //             name: "財務報表",
    //             key: "financialReports",
    //             route: "",
    //             component: <UserProfileSetting/>
    //         },
    //     ],
    // },
    {
        type: "collapse",
        name: "快捷式訂房設定",
        key: "smartBookingControl",
        icon: <FontAwesomeIcon icon={faSwatchbook} />,
        collapse: [
            {
                name: "快捷式訂房基本資料維護",
                key: "smartBookingSet",
                route: "/systemMang/smartBooking/smartBookingSetting",
                component: <SmartBookingSetting/>
            },
        ],
    },
    // {
    //     type: "collapse",
    //     name: "票券管理",
    //     key: "coupTickMang",
    //     icon: <FontAwesomeIcon icon={faTicket} />,
    //     collapse: [
    //         {
    //             name: "票券基本資料維護",
    //             key: "tickBasicSet",
    //             route: "/systemMang/ticket/ticketBasicSetting",
    //             component: <TicketBasicSetting/>
    //         },
    //         {
    //             name: "銷售通路基本資料維護",
    //             key: "salesChannBasicSet",
    //             route: "/systemMang/ticket/salesChannelBasicSetting",
    //             component: <SalesChannelBasicSetting/>
    //         },
    //     ],
    // },
    // {
    //     type: "collapse",
    //     name: "使用者管理",
    //     key: "usersProfileControl",
    //     icon: <FontAwesomeIcon icon={faUsers} />,
    //     collapse: [
    //         {
    //             name: "使用者設定",
    //             key: "profile",
    //             route: "/systemMang/accounts/accountSetting",
    //             component: <UserProfileSetting/>
    //         },
    //     ],
    // },
    // {
    //     type: "collapse",
    //     name: "公用參數管理",
    //     key: "GloballSetting",
    //     route: "/systemMang/global/globalVarSetting",
    //     icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
    //     component: <GlobalVarSetting/>,
    //     noCollapse: true,
    // },
    {
        type: "collapse",
        name: "基本資料管理",
        key: "basicSetting",
        icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
        collapse: [
            {
                name: "公用參數管理",
                key: "GloballSetting",
                route: "/systemMang/basicSetting/globalVarSetting",
                component: <GlobalVarSetting/>
            },
            {
                name: "使用者管理",
                key: "usersProfileControl",
                route: "/systemMang/basicSetting/accountSetting",
                component: <UserProfileSetting/>
            },
        ],
    },
    { type: "divider", key: "divider-1" },


];

export default routes;
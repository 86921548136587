import React, {useEffect, useRef, useState} from 'react';
import DashboardLayout from "../../../pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../pages/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDAlert from "components/MDAlert";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {CardHeader} from "@mui/material";
import {
    Tree as DHXTree,
    Layout as DHXLayout,
    Toolbar as DHXToolbar,
    Grid as DHXGrid,
    Window as DHXWindow,
    Form as DHXForm,
    message as DHXMessage
} from 'dhx-suite';
import 'dhx-suite/codebase/suite.min.css'
import './style.css';
import axios from "axios";
import {toolbar1Config} from "./componentsConfig/toolbar1Config";
import {hotelRoomNumberGridColumn} from './componentsConfig/hotelRoomNumberGridColumn';
import {
    globalWindowFootAccept,
    globalWindowFootClear,
    globalWindowFootClose
} from "./componentsConfig/globalWindowConfig";
import {
    hotelRoomNumberSetFormConfig,
    hotelRoomNumberStopFormConfig,
    hotelRoomNumberDeleteFormConfig
} from "./componentsConfig/hotelRoomSetFormConfig";
import Image_error from '../../../assets/images/error.png'
import Image_warning from '../../../assets/images/warning.png'
import api_url from "../../../context/UrlContext.js"
import {useNavigate} from "react-router-dom";


const RoomBasicSetting = () => {

    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState('');
    const [canEdit ,setCanEdit] = useState('');
    const [canDelete ,setCanDelete] = useState('');
    const [canPrint ,setCanPrint] = useState('');

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
    };

    const [isLoading,setIsloading] = useState(true);

    const HotelList = useRef(null);
    // const HotelRoomSetTab = useRef(null);
    const DHXHotelRoomNumberLayout = useRef(null);


    let hotelSelectedId = "0";
    let hotelName = "";

    useEffect(() => {

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))},headers)
            .then((response)=>{
                if(response.data == 0){
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:4},headers)
                        .then((response2)=>{
                            if(response2.status == 200){
                                let p = response2.data.split(',');

                                (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                setIsloading(false);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });

        // 飯店清單
        // const hotelTree = new DHXTree(HotelList.current,{});
        //
        // axios.get(apiServer+"/products/loadHotelList",headers)
        //     .then((response) =>{
        //         if(response.status === 200){
        //             hotelTree.data.removeAll();
        //             hotelTree.data.parse(response.data);
        //         }else{
        //             console.log("Server Error")
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
        //
        // hotelTree.selection.events.on("afterSelect", function(id){
        //     hotelSelectedId = id;
        //     hotelName = hotelTree.selection.getItem().value;
        //     hotelRoomNumberSetForm.getItem("BB002").setValue(id);
        //     hotelRoomNumberSetForm.getItem("hotelName").setValue(hotelTree.selection.getItem().value);
        // });

        //房號基本資料
        let HotelRoomNumberLayout = new DHXLayout(DHXHotelRoomNumberLayout.current,{
            rows:[
                {id:"HotelRoomNumberToolbar", height: "content"},
                {id: "HotelRoomNumberGrid"}
            ]
        });

        const toolbar1 = new DHXToolbar(null, {
        });
        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        hotelRoomNumberSetForm.clear();
                        axios.post(apiServer+"/prices/loadHotelName", {
                            itemId1: hotel,
                        },headers).then((response) =>{
                            if(response.status == 200){
                                hotelName = response.data[0].hotelName;
                                hotelRoomNumberSetForm.getItem("hotelName").setValue(response.data[0].hotelName);
                            }
                        })
                            .catch((error) => {
                                console.log(error);
                            });

                        hotelRoomNumberSetForm.setValue({"BB001":"0","BB002":hotel,"BB022":"1","BB104":999});

                        axios.post(apiServer+"/products/loadRoomTypeList", {
                            itemId1: hotel
                        },headers).then((response) =>{
                            hotelRoomNumberSetForm.getItem("BB003").setOptions(response.data);
                        })
                            .catch((error) => {
                                console.log(error);
                            });

                        dhxHotelRoomNumberSetWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: false });
                        dhxHotelRoomNumberSetWindow.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                case "reload":
                    axios.post(apiServer+"/products/loadHotelRoomNumberGrid",{itemId1:hotel},headers)
                        .then((response) =>{
                            if(response.status === 200){
                                hotelRoomNumberGrid.data.removeAll();
                                hotelRoomNumberGrid.data.parse(response.data);
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                default:
                    break;
            }
        });
        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbar1Config)));

        const hotelRoomNumberGrid = new DHXGrid(null,{
            columns: hotelRoomNumberGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "hotelRoomNumberGrid_edit": function (e, data) {
                        if(canEdit){
                            hotelRoomNumberSetForm.clear();
                            dhxHotelRoomNumberSetWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });

                            axios.post(apiServer+"/products/loadHotelNumberFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                if(response.status == 200){
                                    dhxHotelRoomNumberSetWindow.show();
                                    hotelRoomNumberSetForm.setValue( response.data[0]);

                                    axios.post(apiServer+"/products/loadRoomTypeList", {
                                        itemId1: response.data[0].BB002
                                    },headers).then((response2) =>{
                                        if(response2.status == 200){
                                            hotelRoomNumberSetForm.getItem("BB003").setOptions(response2.data);
                                            hotelRoomNumberSetForm.getItem("BB003").setValue(response.data[0].BB003);
                                        }
                                    })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                }

                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "hotelRoomNumberGrid_stop": function (e, data) {
                        if(canEdit){
                            hotelRoomNumberStopForm.clear();
                            hotelRoomNumberStopForm.getItem("stopFormTitle").setValue("確定要停用嗎?");

                            axios.post(apiServer+"/products/loadHotelNumberFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                dhxHotelRoomNumberSetWindow2.show();
                                hotelRoomNumberStopForm.setValue( response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "hotelRoomNumberGrid_delete": function (e, data) {
                        if(canDelete){
                            hotelRoomNumberDeleteForm.clear();
                            hotelRoomNumberDeleteForm.getItem("deleteFormTitle").setValue("確定要刪除嗎?");

                            axios.post(apiServer+"/products/loadHotelNumberFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                dhxHotelRoomNumberSetWindow3.show();
                                hotelRoomNumberDeleteForm.setValue( response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        axios.post(apiServer+"/products/loadHotelRoomNumberGrid",{itemId1:hotel},headers)
            .then((response) =>{
                if(response.status === 200){
                    hotelRoomNumberGrid.data.removeAll();
                    hotelRoomNumberGrid.data.parse(response.data);
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        HotelRoomNumberLayout.getCell("HotelRoomNumberToolbar").attach(toolbar1);
        HotelRoomNumberLayout.getCell("HotelRoomNumberGrid").attach(hotelRoomNumberGrid);

        const dhxHotelRoomNumberSetWindow = new DHXWindow({
            title: "新增房間視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 450,
            width: 450,
            footer: true,
            css: "dhxHotelRoomNumberSetWindow",
        });

        dhxHotelRoomNumberSetWindow.footer.data.add({type:"spacer"});
        dhxHotelRoomNumberSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        dhxHotelRoomNumberSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClear)));
        dhxHotelRoomNumberSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        dhxHotelRoomNumberSetWindow.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    hotelRoomNumberSetForm.clear();
                    hotelRoomNumberSetForm.setValue({"BB001":"0","BB002":hotel,"BB022":"1","hotelName":hotelName});
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(hotelRoomNumberSetForm.getItem("BB003").getValue() === "0" || hotelRoomNumberSetForm.getItem("BB003").getValue() === ""){
                        DHXMessage({
                            text: "請選擇房型名稱",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(hotelRoomNumberSetForm.getItem("BB101").getValue() === ""){
                        DHXMessage({
                            text: "請填寫房間名稱",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(hotelRoomNumberSetForm.getItem("BB022").getValue() === "0"){
                        if(hotelRoomNumberSetForm.getItem("BB102").getValue() === ""){
                            DHXMessage({
                                text: "請填入原因",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }
                    };

                    axios.post(apiServer+"/products/checkRoomName",
                        hotelRoomNumberSetForm.getValue(),headers
                    ).then((response) =>{
                        console.log(response);
                        if(response.data[0].result){
                            DHXMessage({
                                text: "房間名稱重複",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }else{
                            axios.post(apiServer+"/products/updateHotelRoomNumberFormData",
                                hotelRoomNumberSetForm.getValue(),headers
                            ).then((response) =>{
                                if(response.data[0].result){
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });
                                    hotelRoomNumberSetForm.clear();
                                    hotelRoomNumberSetForm.setValue({"BB001":"0"})

                                    axios.post(apiServer+"/products/loadHotelRoomNumberGrid",{itemId1:hotel},headers)
                                        .then((response) =>{
                                            if(response.status === 200){
                                                dhxHotelRoomNumberSetWindow.hide();
                                                hotelRoomNumberGrid.data.removeAll();
                                                hotelRoomNumberGrid.data.parse(response.data);
                                            }else{
                                                console.log("Server Error")
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxHotelRoomNumberSetWindow.hide();
                    break;
                default:
                    break;
            }
        })

        const hotelRoomNumberSetForm = new DHXForm(null,{
            rows: hotelRoomNumberSetFormConfig
        });

        hotelRoomNumberSetForm.getItem("BB022").events.on("change", function(value) {
            if(value == 0){
                hotelRoomNumberSetForm.getItem("BB102").enable();
            }else{
                hotelRoomNumberSetForm.getItem("BB102").setValue("");
                hotelRoomNumberSetForm.getItem("BB102").disable();
            }
        });

        dhxHotelRoomNumberSetWindow.attach(hotelRoomNumberSetForm);

        const dhxHotelRoomNumberSetWindow2 = new DHXWindow({
            title: "停用房間視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 580,
            footer: true,
            css: "dhxHotelRoomNumberSetWindow2"
        });

        dhxHotelRoomNumberSetWindow2.footer.data.add({type:"spacer"});
        dhxHotelRoomNumberSetWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        dhxHotelRoomNumberSetWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        dhxHotelRoomNumberSetWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    if(hotelRoomNumberStopForm.getItem("BB102").getValue() === ""){
                        DHXMessage({
                            text: "請填寫停用原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/products/stopHotelNumberFormData",
                        hotelRoomNumberStopForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });
                            hotelRoomNumberSetForm.clear();
                            hotelRoomNumberSetForm.setValue({"BB001":"0"})

                            axios.post(apiServer+"/products/loadHotelRoomNumberGrid",{itemId1:hotel},headers)
                                .then((response) =>{
                                    if(response.status === 200){
                                        dhxHotelRoomNumberSetWindow2.hide();
                                        hotelRoomNumberGrid.data.removeAll();
                                        hotelRoomNumberGrid.data.parse(response.data);
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxHotelRoomNumberSetWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let hotelRoomNumberStopFormLayout = new DHXLayout(null,{
            rows:[
                {id:"hotelRoomNumberStopFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_warning+" /></div>",
                    gravity: false
                },
                {id: "hotelRoomNumberStopForm"},
            ]
        });

        const hotelRoomNumberStopForm = new DHXForm(null,{
            rows: hotelRoomNumberStopFormConfig
        });

        dhxHotelRoomNumberSetWindow2.attach(hotelRoomNumberStopFormLayout);
        hotelRoomNumberStopFormLayout.getCell("hotelRoomNumberStopForm").attach(hotelRoomNumberStopForm);

        const dhxHotelRoomNumberSetWindow3 = new DHXWindow({
            title: "刪除房間視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 580,
            footer: true,
            css: "dhxHotelRoomNumberSetWindow3"
        });

        dhxHotelRoomNumberSetWindow3.footer.data.add({type:"spacer"});
        dhxHotelRoomNumberSetWindow3.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        dhxHotelRoomNumberSetWindow3.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        dhxHotelRoomNumberSetWindow3.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    if(hotelRoomNumberDeleteForm.getItem("BB103").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/products/deleteHotelNumberFormData", {
                        itemId1: hotelRoomNumberDeleteForm.getItem("BB001").getValue()
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data[0].result > 0){

                                DHXMessage({
                                    text: "更新成功",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                axios.post(apiServer+"/products/loadHotelRoomNumberGrid",{itemId1:hotel},headers)
                                    .then((response) =>{
                                        if(response.status === 200){
                                            dhxHotelRoomNumberSetWindow3.hide();
                                            hotelRoomNumberGrid.data.removeAll();
                                            hotelRoomNumberGrid.data.parse(response.data);
                                            hotelRoomNumberDeleteForm.clear();
                                            hotelRoomNumberDeleteForm.setValue({"BB001":"0"})
                                        }else{
                                            console.log("Server Error")
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            }
                        }else{

                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        }

                    })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxHotelRoomNumberSetWindow3.hide();
                    break;
                default:
                    break;
            }
        });

        let hotelRoomNumberDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"hotelRoomNumberDeleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "hotelRoomNumberDeleteForm"},
            ]
        });

        const hotelRoomNumberDeleteForm = new DHXForm(null,{
            rows: hotelRoomNumberDeleteFormConfig
        });

        dhxHotelRoomNumberSetWindow3.attach(hotelRoomNumberDeleteFormLayout);
        hotelRoomNumberDeleteFormLayout.getCell("hotelRoomNumberDeleteForm").attach(hotelRoomNumberDeleteForm);

        return () => {
            // hotelTree.destructor();
            HotelRoomNumberLayout.destructor();
        };
    }, [canAccess,canEdit,canDelete,canPrint,hotel]);

    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
                <MDBox py={3}>
                    <Grid container spacing={1}>
                        {/*<Grid item xs={12} lg={2}>*/}
                        {/*    <Card>*/}
                        {/*        <CardHeader title={"飯店清單"}/>*/}
                        {/*        <MDBox py={0} px={0}>*/}
                        {/*            <div ref={HotelList}></div>*/}
                        {/*        </MDBox>*/}
                        {/*    </Card>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} lg={12}>
                            <Card>
                                <CardHeader title={"房間設定"}/>
                                <MDBox py={1} px={1}>
                                    <div
                                        ref={DHXHotelRoomNumberLayout}
                                        style={{height:"600px", width:"100%"}}>
                                    </div>
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>

                </MDBox>
            }

            {!canAccess && !isLoading &&
            <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }
        </DashboardLayout>
    );
};

RoomBasicSetting.propTypes = {

};

export default RoomBasicSetting;

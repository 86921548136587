import React, {useEffect, useRef, useState} from 'react';
import DashboardLayout from "../../../pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../pages/Navbars/DashboardNavbar";
import MDAlert from "components/MDAlert";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
    Grid as DHXGrid,
    Window as DHXWindow,
    Layout as DHXLayout,
    Toolbar as DHXToolbar,
    message as DHXMessage,
    Form as DHXForm,
} from "dhx-suite";
import "dhx-suite/codebase/suite.min.css";
import {CardHeader} from "@mui/material";
import {pricesSetGridColumn} from "./componentsConfig/pricesSetGridColumn";
import {toolbar1Config} from "./componentsConfig/toolbar1Config";
import {toolbar2Config} from "./componentsConfig/toolbar2Config";
import {pricesSetGridForm1LeftConfig} from "./componentsConfig/pricesSetGridForm1LeftConfig";
import {pricesSetGridForm1RightConfig} from "./componentsConfig/pricesSetGridForm1RightConfig";
import {pricesSetGridForm2Config} from "./componentsConfig/pricesSetGridForm2Config";
import {pricesWindowFootAccept,pricesWindowFootClear,pricesWindowFootClose} from "./componentsConfig/pricesWindowConfig";
import {pricesSetGridDeleteFormConfig} from "./componentsConfig/pricesSetGridDeleteFormConfig";
import {openBookingSettingFormConfig} from "./componentsConfig/openBookingSettingFormConfig";
import {pricesTableGridColumn} from "./componentsConfig/pricesTableGridColumn";
import './style.css';
import Image_error from '../../../assets/images/error.png'
import axios from "axios";
import {toolbar3Config} from "./componentsConfig/toolbar3Config";
import api_url from "../../../context/UrlContext.js"
import {useNavigate} from "react-router-dom";

const PricesBasicSetting = props => {

    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState('');
    const [canEdit ,setCanEdit] = useState('');
    const [canDelete ,setCanDelete] = useState('');
    const [canPrint ,setCanPrint] = useState('');

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
    };

    const [isLoading,setIsloading] = useState(true);

    //
    const DHXPricesLayout = useRef(null);

    //
    useEffect(()=>{

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))},headers)
            .then((response)=>{
                if(response.data == 0){
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:5},headers)
                        .then((response2)=>{
                            if(response2.status == 200){
                                let p = response2.data.split(',');

                                (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                setIsloading(false);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });

        const dhxPricesLayout = new DHXLayout(DHXPricesLayout.current,{
            rows:[
                // {id:"statusToolbar", height: "content"},
                {id:"pricesSetToolbar", height: "content"},
                {id:"pricesSetTips", height: "content",html:"<span style='font-weight: 800;color: red;font-size: 1rem;'>不管用哪個方法新增價格，若日期重疊到會以新建立的為主</span>"},
                {id:"pricesSetGrid", height: "500px"},
            ]
        });

        const toolbar1 = new DHXToolbar(null, {
        });
        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "add1":
                    if(canEdit){
                        clearPricesSetGridForm1();
                        dhxPricesSetGridFormWindow1.header.data.update("title", {value: "新增預設價格視窗"});
                        pricesSetGridFormLeft.setValue({"BC001":"0","BC002":"0","BC003":hotel,"BC100":"1"});
                        axios.post(apiServer+"/prices/loadHotelName", {
                            itemId1: hotel,
                        },headers).then((response) =>{
                            if(response.status == 200){
                                pricesSetGridFormLeft.getItem("hotelName").setValue(response.data[0].hotelName);
                            }
                        })
                            .catch((error) => {
                                console.log(error);
                            });

                        axios.post(apiServer+"/prices/loadMainProductsList", {
                            itemId1: hotel,
                        },headers).then((response) =>{
                            pricesSetGridFormLeft.getItem("BC002").setOptions(response.data);
                            pricesSetGridFormLeft.getItem("BC002").enable();
                        })
                            .catch((error) => {
                                console.log(error);
                            });

                        dhxPricesSetGridFormWindow1.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                case "add2":
                    if(canEdit){
                        clearPricesSetGridForm2();
                        dhxPricesSetGridFormWindow2.header.data.update("title", {value: "新增日期範圍價格視窗"});
                        pricesSetGridForm2.setValue({"BC001":"0","BC002":"0","BC003":hotel,"BC100":"2"});

                        axios.post(apiServer+"/prices/loadHotelName", {
                            itemId1: hotel,
                        },headers).then((response) =>{
                            if(response.status == 200){
                                // hotelName = response.data[0].hotelName;
                                pricesSetGridForm2.getItem("hotelName").setValue(response.data[0].hotelName);
                            }
                        })
                            .catch((error) => {
                                console.log(error);
                            });

                        axios.post(apiServer+"/prices/loadMainProductsList", {
                            itemId1: hotel,
                        },headers).then((response) =>{
                            pricesSetGridForm2.getItem("BC002").setOptions(response.data);
                            pricesSetGridForm2.getItem("BC002").enable();
                        })
                            .catch((error) => {
                                console.log(error);
                            });
                        dhxPricesSetGridFormWindow2.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                case "reload":
                    axios.post(apiServer+"/prices/loadPricesSetGrid",{itemId1:hotel},headers)
                        .then((data) =>{
                            dhxPricesSetGrid.data.removeAll();
                            dhxPricesSetGrid.data.parse(data.data);
                        });
                    break;
                case "viewPricesTable":

                    // let str = '';
                    // axios.post(apiServer+"/prices/loadPricesTable", {
                    //     itemId1: "ajm3br2kadk6t3jgbo41ui2onn"
                    // }).then((response) =>{
                    //     console.log(JSON.parse(response.data));
                    //
                    //     // pricesTableGrid.setColumns(response.data);
                    //
                    // }).catch((error) => {
                    //     console.log(error);
                    // });
                    // // console.log(str);
                    // // dhxPricesSetGridFormWindow5.attachHTML(str);
                    // dhxPricesSetGridFormWindow5.show();

                    // let str = '';
                    // axios.post(apiServer+"/prices/loadPricesTable", {
                    //     itemId1: "ajm3br2kadk6t3jgbo41ui2onn"
                    // }).then((response) =>{
                    //     // console.log(response.data[0]['type']);
                    //     // str = response.data[0]['type'];
                    //     // console.log(str);
                    //     dhxPricesSetGridFormWindow5.attachHTML("<div></div>");
                    //     dhxPricesSetGridFormWindow5.attachHTML(response.data[0]['result']);
                    //
                    // }).catch((error) => {
                    //     console.log(error);
                    // });
                    // console.log(str);
                    // dhxPricesSetGridFormWindow5.attachHTML(str);
                    dhxPricesSetGridFormWindow5.show();
                    break;
                default:
                    break;
            }
        });

        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbar1Config)));
        dhxPricesLayout.getCell("pricesSetToolbar").attach(toolbar1);

        axios.post(apiServer+"/prices/loadOpenBookingSetting", {
            itemId1: hotel
        },headers).then((response) =>{
            let openBookingString = '';
            let openBookingStatus = document.getElementById("openBookingStatus");
            openBookingStatus.innerText = response.data[0].openBookingString;
        }).catch((error) => {
            console.log(error);
        });

        const dhxPricesSetGrid = new DHXGrid(null,{
            columns: pricesSetGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "pricesSetGrid_edit": function (e, data) {
                        if(canEdit){
                            pricesSetGridFormLeft.clear();
                            pricesSetGridFormRight.clear();
                            pricesSetGridForm2.clear();

                            if(data.row.priceType === "1"){

                                dhxPricesSetGridFormWindow1.header.data.update("title", {value: "編輯預設價格視窗"});
                                dhxPricesSetGridFormWindow1.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });

                                axios.post(apiServer+"/prices/loadPricesSetGridFormData", {
                                    itemId1: data.row.BC001,itemId2: data.row.BC003
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        pricesSetGridFormLeft.setValue(response.data[0]);
                                        pricesSetGridFormRight.setValue(response.data[0]);
                                        // pricesSetGridFormLeft.getItem("BC002").setOptions(response.data[0].roomType);
                                        if(response.data[0].BC156 === false){
                                            pricesSetGridFormRight.clear();
                                            pricesSetGridFormRight.getItem("BC157").disable();
                                            pricesSetGridFormRight.getItem("BC158").disable();
                                            pricesSetGridFormRight.getItem("BC159").disable();
                                            pricesSetGridFormRight.getItem("BC160").disable();

                                            pricesSetGridFormRight.getItem("BC161").disable();
                                            pricesSetGridFormRight.getItem("BC162").disable();
                                            pricesSetGridFormRight.getItem("BC162").setValue("");
                                            pricesSetGridFormRight.getItem("weekends_ntd").disable();

                                            pricesSetGridFormRight.getItem("BC163").disable();
                                            pricesSetGridFormRight.getItem("BC163").setValue("");
                                            pricesSetGridFormRight.getItem("BC164").disable();
                                            pricesSetGridFormRight.getItem("BC164").setValue("");
                                            pricesSetGridFormRight.getItem("BC165").disable();
                                            pricesSetGridFormRight.getItem("BC165").setValue("");
                                            pricesSetGridFormRight.getItem("BC166").disable();
                                            pricesSetGridFormRight.getItem("BC166").setValue("");
                                            pricesSetGridFormRight.getItem("BC167").disable();
                                            pricesSetGridFormRight.getItem("BC167").setValue("");
                                            pricesSetGridFormRight.getItem("BC168").disable();
                                            pricesSetGridFormRight.getItem("BC168").setValue("");
                                            pricesSetGridFormRight.getItem("BC169").disable();
                                            pricesSetGridFormRight.getItem("BC169").setValue("");
                                            pricesSetGridFormRight.getItem("BC170").disable();
                                            pricesSetGridFormRight.getItem("BC170").setValue("");
                                            pricesSetGridFormRight.getItem("BC171").disable();
                                            pricesSetGridFormRight.getItem("BC171").setValue("");
                                            pricesSetGridFormRight.getItem("BC172").disable();
                                            pricesSetGridFormRight.getItem("BC172").setValue("");
                                            pricesSetGridFormRight.getItem("BC173").disable();
                                            pricesSetGridFormRight.getItem("BC173").setValue("");
                                            pricesSetGridFormRight.getItem("BC174").disable();
                                            pricesSetGridFormRight.getItem("BC174").setValue("");
                                            pricesSetGridFormRight.getItem("BC175").disable();
                                            pricesSetGridFormRight.getItem("BC175").setValue("");
                                            pricesSetGridFormRight.getItem("BC176").disable();
                                            pricesSetGridFormRight.getItem("BC176").setValue("");
                                            pricesSetGridFormRight.getItem("BC177").disable();
                                            pricesSetGridFormRight.getItem("BC177").setValue("");
                                            pricesSetGridFormRight.getItem("BC178").disable();
                                            pricesSetGridFormRight.getItem("BC178").setValue("");
                                            pricesSetGridFormRight.getItem("BC179").disable();
                                            pricesSetGridFormRight.getItem("BC179").setValue("");
                                            pricesSetGridFormRight.getItem("BC180").disable();
                                            pricesSetGridFormRight.getItem("BC180").setValue("");
                                            pricesSetGridFormRight.getItem("BC181").disable();
                                            pricesSetGridFormRight.getItem("BC181").setValue("");
                                            pricesSetGridFormRight.getItem("BC182").disable();
                                            pricesSetGridFormRight.getItem("BC182").setValue("");
                                            pricesSetGridFormRight.getItem("BC183").disable();
                                            pricesSetGridFormRight.getItem("BC183").setValue("");
                                            pricesSetGridFormRight.getItem("BC184").disable();
                                            pricesSetGridFormRight.getItem("BC184").setValue("");
                                            pricesSetGridFormRight.getItem("BC185").disable();
                                            pricesSetGridFormRight.getItem("BC185").setValue("");
                                            pricesSetGridFormRight.getItem("BC186").disable();
                                            pricesSetGridFormRight.getItem("BC186").setValue("");
                                            pricesSetGridFormRight.getItem("BC187").disable();
                                            pricesSetGridFormRight.getItem("BC187").setValue("");
                                            pricesSetGridFormRight.getItem("BC188").disable();
                                            pricesSetGridFormRight.getItem("BC188").setValue("");
                                            pricesSetGridFormRight.getItem("BC189").disable();
                                            pricesSetGridFormRight.getItem("BC189").setValue("");
                                            pricesSetGridFormRight.getItem("BC190").disable();
                                            pricesSetGridFormRight.getItem("BC190").setValue("");
                                            pricesSetGridFormRight.getItem("BC191").disable();
                                            pricesSetGridFormRight.getItem("BC191").setValue("");
                                            pricesSetGridFormRight.getItem("BC192").disable();
                                            pricesSetGridFormRight.getItem("BC192").setValue("");
                                            pricesSetGridFormRight.getItem("BC193").disable();
                                            pricesSetGridFormRight.getItem("BC193").setValue("");
                                            pricesSetGridFormRight.getItem("BC194").disable();
                                            pricesSetGridFormRight.getItem("BC194").setValue("");
                                            pricesSetGridFormRight.getItem("BC195").disable();
                                            pricesSetGridFormRight.getItem("BC195").setValue("");
                                            pricesSetGridFormRight.getItem("BC196").disable();
                                            pricesSetGridFormRight.getItem("BC196").setValue("");
                                            pricesSetGridFormRight.getItem("BC197").disable();
                                            pricesSetGridFormRight.getItem("BC197").setValue("");
                                            pricesSetGridFormRight.getItem("BC198").disable();
                                            pricesSetGridFormRight.getItem("BC198").setValue("");
                                            pricesSetGridFormRight.getItem("BC199").disable();
                                            pricesSetGridFormRight.getItem("BC199").setValue("");
                                            pricesSetGridFormRight.getItem("BC200").disable();
                                            pricesSetGridFormRight.getItem("BC200").setValue("");
                                            pricesSetGridFormRight.getItem("BC201").disable();
                                            pricesSetGridFormRight.getItem("BC201").setValue("");
                                            pricesSetGridFormRight.getItem("BC202").disable();
                                            pricesSetGridFormRight.getItem("BC202").setValue("");
                                            pricesSetGridFormRight.getItem("BC203").disable();
                                            pricesSetGridFormRight.getItem("BC203").setValue("");
                                            pricesSetGridFormRight.getItem("BC204").disable();
                                            pricesSetGridFormRight.getItem("BC204").setValue("");
                                            pricesSetGridFormRight.getItem("BC205").disable();
                                            pricesSetGridFormRight.getItem("BC205").setValue("");
                                            pricesSetGridFormRight.getItem("BC206").disable();
                                            pricesSetGridFormRight.getItem("BC206").setValue("");
                                            pricesSetGridFormRight.getItem("BC207").disable();
                                            pricesSetGridFormRight.getItem("BC207").setValue("");
                                            pricesSetGridFormRight.getItem("BC208").disable();
                                            pricesSetGridFormRight.getItem("BC208").setValue("");
                                            pricesSetGridFormRight.getItem("BC209").disable();
                                            pricesSetGridFormRight.getItem("BC209").setValue("");
                                            pricesSetGridFormRight.getItem("BC210").disable();
                                            pricesSetGridFormRight.getItem("BC210").setValue("");
                                        }else{

                                            let BC157 = response.data[0].BC157.slice(0, -1);
                                            BC157 = BC157.split(',');
                                            let BC157Value = {};
                                            BC157.forEach(function (value) {
                                                let v = value;
                                                BC157Value[v] = true;
                                            });
                                            pricesSetGridFormRight.getItem("BC157").clear();
                                            pricesSetGridFormRight.getItem("BC157").setValue(BC157Value);
                                        }

                                        axios.post(apiServer+"/prices/loadHotelName", {
                                            itemId1: hotel,
                                        },headers).then((response2) =>{
                                            if(response2.status == 200){
                                                // hotelName = response.data[0].hotelName;
                                                pricesSetGridFormLeft.getItem("hotelName").setValue(response2.data[0].hotelName);
                                                pricesSetGridFormLeft.getItem("BC003").setValue(hotel);

                                                axios.post(apiServer+"/prices/loadMainProductsList", {
                                                    itemId1: hotel,
                                                },headers).then((response3) =>{
                                                    pricesSetGridFormLeft.getItem("BC002").setOptions(response3.data);
                                                    pricesSetGridFormLeft.getItem("BC002").setValue(response.data[0].BC002);
                                                    pricesSetGridFormLeft.getItem('BC002').disable();
                                                })
                                                    .catch((error) => {
                                                        console.log(error);
                                                    });

                                                setTimeout(()=>{
                                                    dhxPricesSetGridFormWindow1.show();
                                                },500);
                                            }
                                        })
                                            .catch((error) => {
                                                console.log(error);
                                            });
                                    }
                                }).catch((error) => {
                                    console.log(error);
                                });
                            }else if(data.row.priceType === "2"){
                                dhxPricesSetGridFormWindow2.header.data.update("title", {value: "編輯日期範圍價格視窗"});
                                dhxPricesSetGridFormWindow2.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });

                                axios.post(apiServer+"/prices/loadPricesSetGridFormData", {
                                    itemId1: data.row.BC001,itemId2: data.row.BC003
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        pricesSetGridForm2.setValue(response.data[0]);
                                        // pricesSetGridForm2.getItem("BC002").setOptions(response.data[0].roomType);

                                        let BC157 = response.data[0].BC157.slice(0, -1);
                                        BC157 = BC157.split(',');
                                        let BC157Value = {};
                                        BC157.forEach(function (value) {
                                            let v = value;
                                            BC157Value[v] = true;
                                        });
                                        pricesSetGridForm2.getItem("BC157").clear();
                                        pricesSetGridForm2.getItem("BC157").setValue(BC157Value);

                                        axios.post(apiServer+"/prices/loadHotelName", {
                                            itemId1: hotel,
                                        },headers).then((response2) =>{
                                            if(response2.status == 200){
                                                // hotelName = response.data[0].hotelName;
                                                pricesSetGridForm2.getItem("hotelName").setValue(response.data[0].hotelName);
                                                pricesSetGridForm2.getItem("BC003").setValue(hotel);

                                                axios.post(apiServer+"/prices/loadMainProductsList", {
                                                    itemId1: hotel,
                                                },headers).then((response3) =>{
                                                    pricesSetGridForm2.getItem("BC002").setOptions(response3.data);
                                                    pricesSetGridForm2.getItem("BC002").setValue(response.data[0].BC002);
                                                    pricesSetGridForm2.getItem('BC002').disable();
                                                })
                                                    .catch((error) => {
                                                        console.log(error);
                                                    });

                                                setTimeout(()=>{
                                                    dhxPricesSetGridFormWindow2.show();
                                                },500);
                                            }
                                        })
                                            .catch((error) => {
                                                console.log(error);
                                            });
                                    }
                                }).catch((error) => {
                                    console.log(error);
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "pricesSetGrid_delete": function (e, data) {
                        if(canDelete){
                            pricesSetGridDeleteForm.clear();
                            axios.post(apiServer+"/prices/loadPricesSetGridFormData", {
                                itemId1: data.row.BC001,itemId2: data.row.BC003
                            },headers).then((response) =>{
                                pricesSetGridDeleteForm.setValue(response.data[0]);
                                pricesSetGridDeleteForm.getItem('roomType').setValue(data.row.room_type);
                            }).catch((error) => {
                                console.log(error);
                            });
                            dhxPricesSetGridFormWindow3.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        axios.post(apiServer+"/prices/loadPricesSetGrid",{itemId1:hotel},headers)
            .then((data) =>{
                dhxPricesSetGrid.data.parse(data.data);
            });

        dhxPricesLayout.getCell("pricesSetGrid").attach(dhxPricesSetGrid);

        const dhxPricesSetGridFormWindow1 = new DHXWindow({

            title: "新增預設價格視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 710,
            width: 1000,
            footer: true,
            css: "pricesSetGridFormWindow1",
        });

        dhxPricesSetGridFormWindow1.footer.data.add({type:"spacer"});
        dhxPricesSetGridFormWindow1.footer.data.add( JSON.parse( JSON.stringify(pricesWindowFootClose)));
        dhxPricesSetGridFormWindow1.footer.data.add( JSON.parse( JSON.stringify(pricesWindowFootClear)));
        dhxPricesSetGridFormWindow1.footer.data.add( JSON.parse( JSON.stringify(pricesWindowFootAccept)));

        dhxPricesSetGridFormWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    clearPricesSetGridForm1();
                    pricesSetGridFormLeft.setValue({"BC001":"0","BC002":"0","BC100":"1"});
                    axios.post(apiServer+"/prices/loadHotelName", {
                        itemId1: pricesSetGridFormLeft.getItem("BC003").getValue(),
                    },headers).then((response) =>{
                        pricesSetGridFormLeft.getItem("hotelName").setValue(response.data[0].hotelName);
                    })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    let BC103 = pricesSetGridFormLeft.getItem("BC103").getValue();

                    console.log(Number(pricesSetGridFormLeft.getItem("BC103").getValue()) > 0);
                    // console.log(pricesSetGridFormLeft.getItem("BC103").getValue());

                    // if(pricesSetGridFormLeft.getItem("BC003").getValue() === "0"){
                    //     DHXMessage({
                    //         text: "請選擇飯店名稱",
                    //         icon:"dxi dxi-close",
                    //         css: "dhx_message--error",
                    //         expire:5000
                    //     });
                    //     break;
                    // }

                    if(pricesSetGridFormLeft.getItem("BC002").getValue() === "0"){
                        DHXMessage({
                            text: "請選擇房型",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(pricesSetGridFormLeft.getItem("BC101").getValue() === ""){
                        DHXMessage({
                            text: "請填入開始日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(pricesSetGridFormLeft.getItem("BC102").getValue() === ""){
                        DHXMessage({
                            text: "請填入結束日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(pricesSetGridFormLeft.getItem("BC103").getValue() === ""){
                        DHXMessage({
                            text: "請填入價格",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(!(Number(pricesSetGridFormLeft.getItem("BC103").getValue()) > 0)){
                        DHXMessage({
                            text: "價格只能是正整數數值",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(pricesSetGridFormLeft.getItem("BC104").getValue() === ""){
                        DHXMessage({
                            text: "請填入同業價",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(!(Number(pricesSetGridFormLeft.getItem("BC104").getValue()) > 0)){
                        DHXMessage({
                            text: "同業價只能是正整數數值",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(pricesSetGridFormLeft.getItem("BC105").getValue() === ""){
                        DHXMessage({
                            text: "請填入OTA價格",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(!(Number(pricesSetGridFormLeft.getItem("BC105").getValue()) > 0)){
                        DHXMessage({
                            text: "OTA價格只能是正整數數值",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    let checkHourPrice1 = 1;
                    let BC106Str = 0;
                    if(pricesSetGridFormLeft.getItem("BC106").getValue() === true){
                        BC106Str = 1;
                        if(pricesSetGridFormLeft.getItem("BC107").getValue() === ""){
                            checkHourPrice1 = 0;
                            DHXMessage({
                                text: "請填入每小時相同價格",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(!(Number(pricesSetGridFormLeft.getItem("BC107").getValue()) > 0)){
                            checkHourPrice1 = 0;
                            DHXMessage({
                                text: "每小時相同價格只能是正整數數值",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }
                    }else if(pricesSetGridFormLeft.getItem("BC106").getValue() === false){
                        BC106Str = 0;
                        let hourArray = document.querySelectorAll(".hours input");
                        for(let i=0;i<hourArray.length;i++){
                            if(hourArray[i].value === ""){
                                checkHourPrice1 = 0;
                                DHXMessage({
                                    text: "請填入每小時價格多少",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(!(Number(hourArray[i].value) > 0)){
                                checkHourPrice1 = 0;
                                DHXMessage({
                                    text: "每小時價格只能是正整數數值",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }
                        }
                    }

                    if(checkHourPrice1 === 0){
                        break;
                    }

                    let checkHourPrice2 = 1;

                    let BC156Str = 0;
                    let BC157String = "";
                    let BC161Str = 0;
                    if(pricesSetGridFormRight.getItem("BC156").getValue() === true){
                        BC156Str = 1;
                        let BC157Count = 0;
                        let BC157 = Object.entries(pricesSetGridFormRight.getItem("BC157").getValue());
                        let BC157Length = BC157.length;
                        for(let i=0;i<BC157Length;i++){
                            if(BC157[i][1] === true){
                                BC157String += BC157[i][0] + ",";
                                BC157Count++;
                            }
                        }

                        if(BC157Count === 0){
                            checkHourPrice2 = 0;
                            BC157String = "";
                            DHXMessage({
                                text: "請選擇星期",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(pricesSetGridFormRight.getItem("BC158").getValue() === ""){
                            DHXMessage({
                                text: "請填入周末價格",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(!(Number(pricesSetGridFormRight.getItem("BC158").getValue()) > 0)){
                            DHXMessage({
                                text: "周末價格只能是正整數數值",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(pricesSetGridFormRight.getItem("BC159").getValue() === ""){
                            DHXMessage({
                                text: "請填入周末同業價",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(!(Number(pricesSetGridFormRight.getItem("BC159").getValue()) > 0)){
                            DHXMessage({
                                text: "周末同業價只能是正整數數值",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(pricesSetGridFormRight.getItem("BC160").getValue() === ""){
                            DHXMessage({
                                text: "請填入周末OTA價",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(!(Number(pricesSetGridFormRight.getItem("BC160").getValue()) > 0)){
                            DHXMessage({
                                text: "周末OTA價只能是正整數數值",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(pricesSetGridFormRight.getItem("BC161").getValue() === true){
                            BC161Str = 1;
                            if(pricesSetGridFormRight.getItem("BC162").getValue() === ""){
                                checkHourPrice2 = 0;
                                DHXMessage({
                                    text: "請填入周末每小時相同價格",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }
                        }else if(pricesSetGridFormRight.getItem("BC161").getValue() === false){
                            BC161Str = 0;
                            let weekendHourArray = document.querySelectorAll(".weekendHours input");
                            for(let i=0;i<weekendHourArray.length;i++){
                                if(weekendHourArray[i].value === ""){
                                    checkHourPrice2 = 0;
                                    DHXMessage({
                                        text: "請填入每小時價格多少",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                    break;
                                }

                                if(!(Number(weekendHourArray[i].value) > 0)){
                                    checkHourPrice2 = 0;
                                    DHXMessage({
                                        text: "每小時價格只能是正整數數值",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                    break;
                                }
                            }
                        }
                    }

                    if(checkHourPrice2 === 0){
                        break;
                    }

                    let pricesSetGridFormValue = Object.assign({},pricesSetGridFormLeft.getValue(),pricesSetGridFormRight.getValue());
                    pricesSetGridFormValue.BC157 = BC157String;
                    pricesSetGridFormValue.BC106 = BC106Str;
                    pricesSetGridFormValue.BC156 = BC156Str;
                    pricesSetGridFormValue.BC161 = BC161Str;
                    axios.post(apiServer+"/prices/updatePricesSetGridFormData", {
                        pricesSetGridFormValue
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data[0].result > 0){
                                DHXMessage({
                                    text: response.data[0].message,
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                axios.post(apiServer+"/prices/loadPricesSetGrid",{itemId1:hotel},headers)
                                    .then((data) =>{
                                        dhxPricesSetGridFormWindow1.hide();
                                        dhxPricesSetGrid.data.removeAll();
                                        dhxPricesSetGrid.data.parse(data.data);
                                    });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        }
                    });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxPricesSetGridFormWindow1.hide();
                    break;
                default:
                    break;
            }
        })

        const dhxPricesSetGridFormLayout = new DHXLayout(null,{
            cols: [
                {
                    id: "PricesSetGridFormLeft",
                    width: "50%",
                },
                {
                    id: "PricesSetGridFormRight",
                    width: "50%",
                },
            ]
        });

        const pricesSetGridFormLeft = new DHXForm(null,{
            rows: pricesSetGridForm1LeftConfig
        });


        // pricesSetGridFormLeft.getItem("BC003").events.on("change", function(value) {
        //     if(value === "0"){
        //         pricesSetGridFormLeft.getItem("BC003").setValue("0");
        //         pricesSetGridFormLeft.getItem("BC002").setValue("0");
        //         pricesSetGridFormLeft.getItem("BC002").disable();
        //     }else{
        //         axios.post(apiServer+"/prices/loadMainProductsList", {
        //             itemId1: pricesSetGridFormLeft.getItem("BC003").getValue(),
        //         },headers).then((response) =>{
        //             pricesSetGridFormLeft.getItem("BC002").setOptions(response.data);
        //             pricesSetGridFormLeft.getItem("BC002").enable();
        //         })
        //             .catch((error) => {
        //                 console.log(error);
        //             });
        //     }
        // });

        let pricesSetGridFormLeftStartDate = pricesSetGridFormLeft.getItem("BC101").getWidget();
        let pricesSetGridFormLeftEndDate = pricesSetGridFormLeft.getItem("BC102").getWidget();
        pricesSetGridFormLeftStartDate.link(pricesSetGridFormLeftEndDate)

        const pricesSetGridFormRight = new DHXForm(null,{
            rows: pricesSetGridForm1RightConfig
        });

        dhxPricesSetGridFormWindow1.events.on("click", function (name, e){
            switch (name){
                case "send":
                    dhxPricesSetGridFormWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        pricesSetGridFormLeft.getItem("BC106").events.on("change", function(value) {
            if(value == true){
                pricesSetGridFormLeft.getItem("BC107").enable();
                pricesSetGridFormLeft.getItem("BC107").setValue("");

                pricesSetGridFormLeft.getItem("BC108").disable();
                pricesSetGridFormLeft.getItem("BC108").setValue("");
                pricesSetGridFormLeft.getItem("BC109").disable();
                pricesSetGridFormLeft.getItem("BC109").setValue("");
                pricesSetGridFormLeft.getItem("BC110").disable();
                pricesSetGridFormLeft.getItem("BC110").setValue("");
                pricesSetGridFormLeft.getItem("BC111").disable();
                pricesSetGridFormLeft.getItem("BC111").setValue("");
                pricesSetGridFormLeft.getItem("BC112").disable();
                pricesSetGridFormLeft.getItem("BC112").setValue("");
                pricesSetGridFormLeft.getItem("BC113").disable();
                pricesSetGridFormLeft.getItem("BC113").setValue("");
                pricesSetGridFormLeft.getItem("BC114").disable();
                pricesSetGridFormLeft.getItem("BC114").setValue("");
                pricesSetGridFormLeft.getItem("BC115").disable();
                pricesSetGridFormLeft.getItem("BC115").setValue("");
                pricesSetGridFormLeft.getItem("BC116").disable();
                pricesSetGridFormLeft.getItem("BC116").setValue("");
                pricesSetGridFormLeft.getItem("BC117").disable();
                pricesSetGridFormLeft.getItem("BC117").setValue("");
                pricesSetGridFormLeft.getItem("BC118").disable();
                pricesSetGridFormLeft.getItem("BC118").setValue("");
                pricesSetGridFormLeft.getItem("BC119").disable();
                pricesSetGridFormLeft.getItem("BC119").setValue("");
                pricesSetGridFormLeft.getItem("BC120").disable();
                pricesSetGridFormLeft.getItem("BC120").setValue("");
                pricesSetGridFormLeft.getItem("BC121").disable();
                pricesSetGridFormLeft.getItem("BC121").setValue("");
                pricesSetGridFormLeft.getItem("BC122").disable();
                pricesSetGridFormLeft.getItem("BC122").setValue("");
                pricesSetGridFormLeft.getItem("BC123").disable();
                pricesSetGridFormLeft.getItem("BC123").setValue("");
                pricesSetGridFormLeft.getItem("BC124").disable();
                pricesSetGridFormLeft.getItem("BC124").setValue("");
                pricesSetGridFormLeft.getItem("BC125").disable();
                pricesSetGridFormLeft.getItem("BC125").setValue("");
                pricesSetGridFormLeft.getItem("BC126").disable();
                pricesSetGridFormLeft.getItem("BC126").setValue("");
                pricesSetGridFormLeft.getItem("BC127").disable();
                pricesSetGridFormLeft.getItem("BC127").setValue("");
                pricesSetGridFormLeft.getItem("BC128").disable();
                pricesSetGridFormLeft.getItem("BC128").setValue("");
                pricesSetGridFormLeft.getItem("BC129").disable();
                pricesSetGridFormLeft.getItem("BC129").setValue("");
                pricesSetGridFormLeft.getItem("BC130").disable();
                pricesSetGridFormLeft.getItem("BC130").setValue("");
                pricesSetGridFormLeft.getItem("BC131").disable();
                pricesSetGridFormLeft.getItem("BC131").setValue("");
                pricesSetGridFormLeft.getItem("BC132").disable();
                pricesSetGridFormLeft.getItem("BC132").setValue("");
                pricesSetGridFormLeft.getItem("BC133").disable();
                pricesSetGridFormLeft.getItem("BC133").setValue("");
                pricesSetGridFormLeft.getItem("BC134").disable();
                pricesSetGridFormLeft.getItem("BC134").setValue("");
                pricesSetGridFormLeft.getItem("BC135").disable();
                pricesSetGridFormLeft.getItem("BC135").setValue("");
                pricesSetGridFormLeft.getItem("BC136").disable();
                pricesSetGridFormLeft.getItem("BC136").setValue("");
                pricesSetGridFormLeft.getItem("BC137").disable();
                pricesSetGridFormLeft.getItem("BC137").setValue("");
                pricesSetGridFormLeft.getItem("BC138").disable();
                pricesSetGridFormLeft.getItem("BC138").setValue("");
                pricesSetGridFormLeft.getItem("BC139").disable();
                pricesSetGridFormLeft.getItem("BC139").setValue("");
                pricesSetGridFormLeft.getItem("BC140").disable();
                pricesSetGridFormLeft.getItem("BC140").setValue("");
                pricesSetGridFormLeft.getItem("BC141").disable();
                pricesSetGridFormLeft.getItem("BC141").setValue("");
                pricesSetGridFormLeft.getItem("BC142").disable();
                pricesSetGridFormLeft.getItem("BC142").setValue("");
                pricesSetGridFormLeft.getItem("BC143").disable();
                pricesSetGridFormLeft.getItem("BC143").setValue("");
                pricesSetGridFormLeft.getItem("BC144").disable();
                pricesSetGridFormLeft.getItem("BC144").setValue("");
                pricesSetGridFormLeft.getItem("BC145").disable();
                pricesSetGridFormLeft.getItem("BC145").setValue("");
                pricesSetGridFormLeft.getItem("BC146").disable();
                pricesSetGridFormLeft.getItem("BC146").setValue("");
                pricesSetGridFormLeft.getItem("BC147").disable();
                pricesSetGridFormLeft.getItem("BC147").setValue("");
                pricesSetGridFormLeft.getItem("BC148").disable();
                pricesSetGridFormLeft.getItem("BC148").setValue("");
                pricesSetGridFormLeft.getItem("BC149").disable();
                pricesSetGridFormLeft.getItem("BC149").setValue("");
                pricesSetGridFormLeft.getItem("BC150").disable();
                pricesSetGridFormLeft.getItem("BC150").setValue("");
                pricesSetGridFormLeft.getItem("BC151").disable();
                pricesSetGridFormLeft.getItem("BC151").setValue("");
                pricesSetGridFormLeft.getItem("BC152").disable();
                pricesSetGridFormLeft.getItem("BC152").setValue("");
                pricesSetGridFormLeft.getItem("BC153").disable();
                pricesSetGridFormLeft.getItem("BC153").setValue("");
                pricesSetGridFormLeft.getItem("BC154").disable();
                pricesSetGridFormLeft.getItem("BC154").setValue("");
                pricesSetGridFormLeft.getItem("BC155").disable();
                pricesSetGridFormLeft.getItem("BC155").setValue("");
            }else if(value == false){
                pricesSetGridFormLeft.getItem("BC107").disable();
                pricesSetGridFormLeft.getItem("BC107").setValue("");

                pricesSetGridFormLeft.getItem("BC108").enable();
                pricesSetGridFormLeft.getItem("BC108").setValue("");
                pricesSetGridFormLeft.getItem("BC109").enable();
                pricesSetGridFormLeft.getItem("BC109").setValue("");
                pricesSetGridFormLeft.getItem("BC110").enable();
                pricesSetGridFormLeft.getItem("BC110").setValue("");
                pricesSetGridFormLeft.getItem("BC111").enable();
                pricesSetGridFormLeft.getItem("BC111").setValue("");
                pricesSetGridFormLeft.getItem("BC112").enable();
                pricesSetGridFormLeft.getItem("BC112").setValue("");
                pricesSetGridFormLeft.getItem("BC113").enable();
                pricesSetGridFormLeft.getItem("BC113").setValue("");
                pricesSetGridFormLeft.getItem("BC114").enable();
                pricesSetGridFormLeft.getItem("BC114").setValue("");
                pricesSetGridFormLeft.getItem("BC115").enable();
                pricesSetGridFormLeft.getItem("BC115").setValue("");
                pricesSetGridFormLeft.getItem("BC116").enable();
                pricesSetGridFormLeft.getItem("BC116").setValue("");
                pricesSetGridFormLeft.getItem("BC117").enable();
                pricesSetGridFormLeft.getItem("BC117").setValue("");
                pricesSetGridFormLeft.getItem("BC118").enable();
                pricesSetGridFormLeft.getItem("BC118").setValue("");
                pricesSetGridFormLeft.getItem("BC119").enable();
                pricesSetGridFormLeft.getItem("BC119").setValue("");
                pricesSetGridFormLeft.getItem("BC120").enable();
                pricesSetGridFormLeft.getItem("BC120").setValue("");
                pricesSetGridFormLeft.getItem("BC121").enable();
                pricesSetGridFormLeft.getItem("BC121").setValue("");
                pricesSetGridFormLeft.getItem("BC122").enable();
                pricesSetGridFormLeft.getItem("BC122").setValue("");
                pricesSetGridFormLeft.getItem("BC123").enable();
                pricesSetGridFormLeft.getItem("BC123").setValue("");
                pricesSetGridFormLeft.getItem("BC124").enable();
                pricesSetGridFormLeft.getItem("BC124").setValue("");
                pricesSetGridFormLeft.getItem("BC125").enable();
                pricesSetGridFormLeft.getItem("BC125").setValue("");
                pricesSetGridFormLeft.getItem("BC126").enable();
                pricesSetGridFormLeft.getItem("BC126").setValue("");
                pricesSetGridFormLeft.getItem("BC127").enable();
                pricesSetGridFormLeft.getItem("BC127").setValue("");
                pricesSetGridFormLeft.getItem("BC128").enable();
                pricesSetGridFormLeft.getItem("BC128").setValue("");
                pricesSetGridFormLeft.getItem("BC129").enable();
                pricesSetGridFormLeft.getItem("BC129").setValue("");
                pricesSetGridFormLeft.getItem("BC130").enable();
                pricesSetGridFormLeft.getItem("BC130").setValue("");
                pricesSetGridFormLeft.getItem("BC131").enable();
                pricesSetGridFormLeft.getItem("BC131").setValue("");
                pricesSetGridFormLeft.getItem("BC132").enable();
                pricesSetGridFormLeft.getItem("BC132").setValue("");
                pricesSetGridFormLeft.getItem("BC133").enable();
                pricesSetGridFormLeft.getItem("BC133").setValue("");
                pricesSetGridFormLeft.getItem("BC134").enable();
                pricesSetGridFormLeft.getItem("BC134").setValue("");
                pricesSetGridFormLeft.getItem("BC135").enable();
                pricesSetGridFormLeft.getItem("BC135").setValue("");
                pricesSetGridFormLeft.getItem("BC136").enable();
                pricesSetGridFormLeft.getItem("BC136").setValue("");
                pricesSetGridFormLeft.getItem("BC137").enable();
                pricesSetGridFormLeft.getItem("BC137").setValue("");
                pricesSetGridFormLeft.getItem("BC138").enable();
                pricesSetGridFormLeft.getItem("BC138").setValue("");
                pricesSetGridFormLeft.getItem("BC139").enable();
                pricesSetGridFormLeft.getItem("BC139").setValue("");
                pricesSetGridFormLeft.getItem("BC140").enable();
                pricesSetGridFormLeft.getItem("BC140").setValue("");
                pricesSetGridFormLeft.getItem("BC141").enable();
                pricesSetGridFormLeft.getItem("BC141").setValue("");
                pricesSetGridFormLeft.getItem("BC142").enable();
                pricesSetGridFormLeft.getItem("BC142").setValue("");
                pricesSetGridFormLeft.getItem("BC143").enable();
                pricesSetGridFormLeft.getItem("BC143").setValue("");
                pricesSetGridFormLeft.getItem("BC144").enable();
                pricesSetGridFormLeft.getItem("BC144").setValue("");
                pricesSetGridFormLeft.getItem("BC145").enable();
                pricesSetGridFormLeft.getItem("BC145").setValue("");
                pricesSetGridFormLeft.getItem("BC146").enable();
                pricesSetGridFormLeft.getItem("BC146").setValue("");
                pricesSetGridFormLeft.getItem("BC147").enable();
                pricesSetGridFormLeft.getItem("BC147").setValue("");
                pricesSetGridFormLeft.getItem("BC148").enable();
                pricesSetGridFormLeft.getItem("BC148").setValue("");
                pricesSetGridFormLeft.getItem("BC149").enable();
                pricesSetGridFormLeft.getItem("BC149").setValue("");
                pricesSetGridFormLeft.getItem("BC150").enable();
                pricesSetGridFormLeft.getItem("BC150").setValue("");
                pricesSetGridFormLeft.getItem("BC151").enable();
                pricesSetGridFormLeft.getItem("BC151").setValue("");
                pricesSetGridFormLeft.getItem("BC152").enable();
                pricesSetGridFormLeft.getItem("BC152").setValue("");
                pricesSetGridFormLeft.getItem("BC153").enable();
                pricesSetGridFormLeft.getItem("BC153").setValue("");
                pricesSetGridFormLeft.getItem("BC154").enable();
                pricesSetGridFormLeft.getItem("BC154").setValue("");
                pricesSetGridFormLeft.getItem("BC155").enable();
                pricesSetGridFormLeft.getItem("BC155").setValue("");

            }
        });

        pricesSetGridFormRight.getItem("BC156").events.on("change", function(value) {
            if(value == true){
                pricesSetGridFormRight.getItem("BC157").enable();
                pricesSetGridFormRight.getItem("BC158").enable();
                pricesSetGridFormRight.getItem("BC159").enable();
                pricesSetGridFormRight.getItem("BC160").enable();

                pricesSetGridFormRight.getItem("BC161").enable();
                pricesSetGridFormRight.getItem("BC161").setValue(true);
                pricesSetGridFormRight.getItem("BC162").enable();
                pricesSetGridFormRight.getItem("BC162").setValue("100");
                pricesSetGridFormRight.getItem("weekends_ntd").enable();

                pricesSetGridFormRight.getItem("BC163").disable();
                pricesSetGridFormRight.getItem("BC163").setValue("");
                pricesSetGridFormRight.getItem("BC164").disable();
                pricesSetGridFormRight.getItem("BC164").setValue("");
                pricesSetGridFormRight.getItem("BC165").disable();
                pricesSetGridFormRight.getItem("BC165").setValue("");
                pricesSetGridFormRight.getItem("BC166").disable();
                pricesSetGridFormRight.getItem("BC166").setValue("");
                pricesSetGridFormRight.getItem("BC167").disable();
                pricesSetGridFormRight.getItem("BC167").setValue("");
                pricesSetGridFormRight.getItem("BC168").disable();
                pricesSetGridFormRight.getItem("BC168").setValue("");
                pricesSetGridFormRight.getItem("BC169").disable();
                pricesSetGridFormRight.getItem("BC169").setValue("");
                pricesSetGridFormRight.getItem("BC170").disable();
                pricesSetGridFormRight.getItem("BC170").setValue("");
                pricesSetGridFormRight.getItem("BC171").disable();
                pricesSetGridFormRight.getItem("BC171").setValue("");
                pricesSetGridFormRight.getItem("BC172").disable();
                pricesSetGridFormRight.getItem("BC172").setValue("");
                pricesSetGridFormRight.getItem("BC173").disable();
                pricesSetGridFormRight.getItem("BC173").setValue("");
                pricesSetGridFormRight.getItem("BC174").disable();
                pricesSetGridFormRight.getItem("BC174").setValue("");
                pricesSetGridFormRight.getItem("BC175").disable();
                pricesSetGridFormRight.getItem("BC175").setValue("");
                pricesSetGridFormRight.getItem("BC176").disable();
                pricesSetGridFormRight.getItem("BC176").setValue("");
                pricesSetGridFormRight.getItem("BC177").disable();
                pricesSetGridFormRight.getItem("BC177").setValue("");
                pricesSetGridFormRight.getItem("BC178").disable();
                pricesSetGridFormRight.getItem("BC178").setValue("");
                pricesSetGridFormRight.getItem("BC179").disable();
                pricesSetGridFormRight.getItem("BC179").setValue("");
                pricesSetGridFormRight.getItem("BC180").disable();
                pricesSetGridFormRight.getItem("BC180").setValue("");
                pricesSetGridFormRight.getItem("BC181").disable();
                pricesSetGridFormRight.getItem("BC181").setValue("");
                pricesSetGridFormRight.getItem("BC182").disable();
                pricesSetGridFormRight.getItem("BC182").setValue("");
                pricesSetGridFormRight.getItem("BC183").disable();
                pricesSetGridFormRight.getItem("BC183").setValue("");
                pricesSetGridFormRight.getItem("BC184").disable();
                pricesSetGridFormRight.getItem("BC184").setValue("");
                pricesSetGridFormRight.getItem("BC185").disable();
                pricesSetGridFormRight.getItem("BC185").setValue("");
                pricesSetGridFormRight.getItem("BC186").disable();
                pricesSetGridFormRight.getItem("BC186").setValue("");
                pricesSetGridFormRight.getItem("BC187").disable();
                pricesSetGridFormRight.getItem("BC187").setValue("");
                pricesSetGridFormRight.getItem("BC188").disable();
                pricesSetGridFormRight.getItem("BC188").setValue("");
                pricesSetGridFormRight.getItem("BC189").disable();
                pricesSetGridFormRight.getItem("BC189").setValue("");
                pricesSetGridFormRight.getItem("BC190").disable();
                pricesSetGridFormRight.getItem("BC190").setValue("");
                pricesSetGridFormRight.getItem("BC191").disable();
                pricesSetGridFormRight.getItem("BC191").setValue("");
                pricesSetGridFormRight.getItem("BC192").disable();
                pricesSetGridFormRight.getItem("BC192").setValue("");
                pricesSetGridFormRight.getItem("BC193").disable();
                pricesSetGridFormRight.getItem("BC193").setValue("");
                pricesSetGridFormRight.getItem("BC194").disable();
                pricesSetGridFormRight.getItem("BC194").setValue("");
                pricesSetGridFormRight.getItem("BC195").disable();
                pricesSetGridFormRight.getItem("BC195").setValue("");
                pricesSetGridFormRight.getItem("BC196").disable();
                pricesSetGridFormRight.getItem("BC196").setValue("");
                pricesSetGridFormRight.getItem("BC197").disable();
                pricesSetGridFormRight.getItem("BC197").setValue("");
                pricesSetGridFormRight.getItem("BC198").disable();
                pricesSetGridFormRight.getItem("BC198").setValue("");
                pricesSetGridFormRight.getItem("BC199").disable();
                pricesSetGridFormRight.getItem("BC199").setValue("");
                pricesSetGridFormRight.getItem("BC200").disable();
                pricesSetGridFormRight.getItem("BC200").setValue("");
                pricesSetGridFormRight.getItem("BC201").disable();
                pricesSetGridFormRight.getItem("BC201").setValue("");
                pricesSetGridFormRight.getItem("BC202").disable();
                pricesSetGridFormRight.getItem("BC202").setValue("");
                pricesSetGridFormRight.getItem("BC203").disable();
                pricesSetGridFormRight.getItem("BC203").setValue("");
                pricesSetGridFormRight.getItem("BC204").disable();
                pricesSetGridFormRight.getItem("BC204").setValue("");
                pricesSetGridFormRight.getItem("BC205").disable();
                pricesSetGridFormRight.getItem("BC205").setValue("");
                pricesSetGridFormRight.getItem("BC206").disable();
                pricesSetGridFormRight.getItem("BC206").setValue("");
                pricesSetGridFormRight.getItem("BC207").disable();
                pricesSetGridFormRight.getItem("BC207").setValue("");
                pricesSetGridFormRight.getItem("BC208").disable();
                pricesSetGridFormRight.getItem("BC208").setValue("");
                pricesSetGridFormRight.getItem("BC209").disable();
                pricesSetGridFormRight.getItem("BC209").setValue("");
                pricesSetGridFormRight.getItem("BC210").disable();
                pricesSetGridFormRight.getItem("BC210").setValue("");

            }else if(value == false){
                pricesSetGridFormRight.clear();
                pricesSetGridFormRight.getItem("BC157").disable();
                pricesSetGridFormRight.getItem("BC158").disable();
                pricesSetGridFormRight.getItem("BC159").disable();
                pricesSetGridFormRight.getItem("BC160").disable();

                pricesSetGridFormRight.getItem("BC161").disable();
                pricesSetGridFormRight.getItem("BC162").disable();
                pricesSetGridFormRight.getItem("BC162").setValue("");
                pricesSetGridFormRight.getItem("weekends_ntd").disable();

                pricesSetGridFormRight.getItem("BC163").disable();
                pricesSetGridFormRight.getItem("BC163").setValue("");
                pricesSetGridFormRight.getItem("BC164").disable();
                pricesSetGridFormRight.getItem("BC164").setValue("");
                pricesSetGridFormRight.getItem("BC165").disable();
                pricesSetGridFormRight.getItem("BC165").setValue("");
                pricesSetGridFormRight.getItem("BC166").disable();
                pricesSetGridFormRight.getItem("BC166").setValue("");
                pricesSetGridFormRight.getItem("BC167").disable();
                pricesSetGridFormRight.getItem("BC167").setValue("");
                pricesSetGridFormRight.getItem("BC168").disable();
                pricesSetGridFormRight.getItem("BC168").setValue("");
                pricesSetGridFormRight.getItem("BC169").disable();
                pricesSetGridFormRight.getItem("BC169").setValue("");
                pricesSetGridFormRight.getItem("BC170").disable();
                pricesSetGridFormRight.getItem("BC170").setValue("");
                pricesSetGridFormRight.getItem("BC171").disable();
                pricesSetGridFormRight.getItem("BC171").setValue("");
                pricesSetGridFormRight.getItem("BC172").disable();
                pricesSetGridFormRight.getItem("BC172").setValue("");
                pricesSetGridFormRight.getItem("BC173").disable();
                pricesSetGridFormRight.getItem("BC173").setValue("");
                pricesSetGridFormRight.getItem("BC174").disable();
                pricesSetGridFormRight.getItem("BC174").setValue("");
                pricesSetGridFormRight.getItem("BC175").disable();
                pricesSetGridFormRight.getItem("BC175").setValue("");
                pricesSetGridFormRight.getItem("BC176").disable();
                pricesSetGridFormRight.getItem("BC176").setValue("");
                pricesSetGridFormRight.getItem("BC177").disable();
                pricesSetGridFormRight.getItem("BC177").setValue("");
                pricesSetGridFormRight.getItem("BC178").disable();
                pricesSetGridFormRight.getItem("BC178").setValue("");
                pricesSetGridFormRight.getItem("BC179").disable();
                pricesSetGridFormRight.getItem("BC179").setValue("");
                pricesSetGridFormRight.getItem("BC180").disable();
                pricesSetGridFormRight.getItem("BC180").setValue("");
                pricesSetGridFormRight.getItem("BC181").disable();
                pricesSetGridFormRight.getItem("BC181").setValue("");
                pricesSetGridFormRight.getItem("BC182").disable();
                pricesSetGridFormRight.getItem("BC182").setValue("");
                pricesSetGridFormRight.getItem("BC183").disable();
                pricesSetGridFormRight.getItem("BC183").setValue("");
                pricesSetGridFormRight.getItem("BC184").disable();
                pricesSetGridFormRight.getItem("BC184").setValue("");
                pricesSetGridFormRight.getItem("BC185").disable();
                pricesSetGridFormRight.getItem("BC185").setValue("");
                pricesSetGridFormRight.getItem("BC186").disable();
                pricesSetGridFormRight.getItem("BC186").setValue("");
                pricesSetGridFormRight.getItem("BC187").disable();
                pricesSetGridFormRight.getItem("BC187").setValue("");
                pricesSetGridFormRight.getItem("BC188").disable();
                pricesSetGridFormRight.getItem("BC188").setValue("");
                pricesSetGridFormRight.getItem("BC189").disable();
                pricesSetGridFormRight.getItem("BC189").setValue("");
                pricesSetGridFormRight.getItem("BC190").disable();
                pricesSetGridFormRight.getItem("BC190").setValue("");
                pricesSetGridFormRight.getItem("BC191").disable();
                pricesSetGridFormRight.getItem("BC191").setValue("");
                pricesSetGridFormRight.getItem("BC192").disable();
                pricesSetGridFormRight.getItem("BC192").setValue("");
                pricesSetGridFormRight.getItem("BC193").disable();
                pricesSetGridFormRight.getItem("BC193").setValue("");
                pricesSetGridFormRight.getItem("BC194").disable();
                pricesSetGridFormRight.getItem("BC194").setValue("");
                pricesSetGridFormRight.getItem("BC195").disable();
                pricesSetGridFormRight.getItem("BC195").setValue("");
                pricesSetGridFormRight.getItem("BC196").disable();
                pricesSetGridFormRight.getItem("BC196").setValue("");
                pricesSetGridFormRight.getItem("BC197").disable();
                pricesSetGridFormRight.getItem("BC197").setValue("");
                pricesSetGridFormRight.getItem("BC198").disable();
                pricesSetGridFormRight.getItem("BC198").setValue("");
                pricesSetGridFormRight.getItem("BC199").disable();
                pricesSetGridFormRight.getItem("BC199").setValue("");
                pricesSetGridFormRight.getItem("BC200").disable();
                pricesSetGridFormRight.getItem("BC200").setValue("");
                pricesSetGridFormRight.getItem("BC201").disable();
                pricesSetGridFormRight.getItem("BC201").setValue("");
                pricesSetGridFormRight.getItem("BC202").disable();
                pricesSetGridFormRight.getItem("BC202").setValue("");
                pricesSetGridFormRight.getItem("BC203").disable();
                pricesSetGridFormRight.getItem("BC203").setValue("");
                pricesSetGridFormRight.getItem("BC204").disable();
                pricesSetGridFormRight.getItem("BC204").setValue("");
                pricesSetGridFormRight.getItem("BC205").disable();
                pricesSetGridFormRight.getItem("BC205").setValue("");
                pricesSetGridFormRight.getItem("BC206").disable();
                pricesSetGridFormRight.getItem("BC206").setValue("");
                pricesSetGridFormRight.getItem("BC207").disable();
                pricesSetGridFormRight.getItem("BC207").setValue("");
                pricesSetGridFormRight.getItem("BC208").disable();
                pricesSetGridFormRight.getItem("BC208").setValue("");
                pricesSetGridFormRight.getItem("BC209").disable();
                pricesSetGridFormRight.getItem("BC209").setValue("");
                pricesSetGridFormRight.getItem("BC210").disable();
                pricesSetGridFormRight.getItem("BC210").setValue("");

            }
        });

        pricesSetGridFormRight.getItem("BC161").events.on("change", function(value) {
            if(value == true){
                pricesSetGridFormRight.getItem("BC162").enable();
                pricesSetGridFormRight.getItem("BC162").setValue("100");
                pricesSetGridFormRight.getItem("weekends_ntd").enable();

                pricesSetGridFormRight.getItem("BC163").disable();
                pricesSetGridFormRight.getItem("BC163").setValue("");
                pricesSetGridFormRight.getItem("BC164").disable();
                pricesSetGridFormRight.getItem("BC164").setValue("");
                pricesSetGridFormRight.getItem("BC165").disable();
                pricesSetGridFormRight.getItem("BC165").setValue("");
                pricesSetGridFormRight.getItem("BC166").disable();
                pricesSetGridFormRight.getItem("BC166").setValue("");
                pricesSetGridFormRight.getItem("BC167").disable();
                pricesSetGridFormRight.getItem("BC167").setValue("");
                pricesSetGridFormRight.getItem("BC168").disable();
                pricesSetGridFormRight.getItem("BC168").setValue("");
                pricesSetGridFormRight.getItem("BC169").disable();
                pricesSetGridFormRight.getItem("BC169").setValue("");
                pricesSetGridFormRight.getItem("BC170").disable();
                pricesSetGridFormRight.getItem("BC170").setValue("");
                pricesSetGridFormRight.getItem("BC171").disable();
                pricesSetGridFormRight.getItem("BC171").setValue("");
                pricesSetGridFormRight.getItem("BC172").disable();
                pricesSetGridFormRight.getItem("BC172").setValue("");
                pricesSetGridFormRight.getItem("BC173").disable();
                pricesSetGridFormRight.getItem("BC173").setValue("");
                pricesSetGridFormRight.getItem("BC174").disable();
                pricesSetGridFormRight.getItem("BC174").setValue("");
                pricesSetGridFormRight.getItem("BC175").disable();
                pricesSetGridFormRight.getItem("BC175").setValue("");
                pricesSetGridFormRight.getItem("BC176").disable();
                pricesSetGridFormRight.getItem("BC176").setValue("");
                pricesSetGridFormRight.getItem("BC177").disable();
                pricesSetGridFormRight.getItem("BC177").setValue("");
                pricesSetGridFormRight.getItem("BC178").disable();
                pricesSetGridFormRight.getItem("BC178").setValue("");
                pricesSetGridFormRight.getItem("BC179").disable();
                pricesSetGridFormRight.getItem("BC179").setValue("");
                pricesSetGridFormRight.getItem("BC180").disable();
                pricesSetGridFormRight.getItem("BC180").setValue("");
                pricesSetGridFormRight.getItem("BC181").disable();
                pricesSetGridFormRight.getItem("BC181").setValue("");
                pricesSetGridFormRight.getItem("BC182").disable();
                pricesSetGridFormRight.getItem("BC182").setValue("");
                pricesSetGridFormRight.getItem("BC183").disable();
                pricesSetGridFormRight.getItem("BC183").setValue("");
                pricesSetGridFormRight.getItem("BC184").disable();
                pricesSetGridFormRight.getItem("BC184").setValue("");
                pricesSetGridFormRight.getItem("BC185").disable();
                pricesSetGridFormRight.getItem("BC185").setValue("");
                pricesSetGridFormRight.getItem("BC186").disable();
                pricesSetGridFormRight.getItem("BC186").setValue("");
                pricesSetGridFormRight.getItem("BC187").disable();
                pricesSetGridFormRight.getItem("BC187").setValue("");
                pricesSetGridFormRight.getItem("BC188").disable();
                pricesSetGridFormRight.getItem("BC188").setValue("");
                pricesSetGridFormRight.getItem("BC189").disable();
                pricesSetGridFormRight.getItem("BC189").setValue("");
                pricesSetGridFormRight.getItem("BC190").disable();
                pricesSetGridFormRight.getItem("BC190").setValue("");
                pricesSetGridFormRight.getItem("BC191").disable();
                pricesSetGridFormRight.getItem("BC191").setValue("");
                pricesSetGridFormRight.getItem("BC192").disable();
                pricesSetGridFormRight.getItem("BC192").setValue("");
                pricesSetGridFormRight.getItem("BC193").disable();
                pricesSetGridFormRight.getItem("BC193").setValue("");
                pricesSetGridFormRight.getItem("BC194").disable();
                pricesSetGridFormRight.getItem("BC194").setValue("");
                pricesSetGridFormRight.getItem("BC195").disable();
                pricesSetGridFormRight.getItem("BC195").setValue("");
                pricesSetGridFormRight.getItem("BC196").disable();
                pricesSetGridFormRight.getItem("BC196").setValue("");
                pricesSetGridFormRight.getItem("BC197").disable();
                pricesSetGridFormRight.getItem("BC197").setValue("");
                pricesSetGridFormRight.getItem("BC198").disable();
                pricesSetGridFormRight.getItem("BC198").setValue("");
                pricesSetGridFormRight.getItem("BC199").disable();
                pricesSetGridFormRight.getItem("BC199").setValue("");
                pricesSetGridFormRight.getItem("BC200").disable();
                pricesSetGridFormRight.getItem("BC200").setValue("");
                pricesSetGridFormRight.getItem("BC201").disable();
                pricesSetGridFormRight.getItem("BC201").setValue("");
                pricesSetGridFormRight.getItem("BC202").disable();
                pricesSetGridFormRight.getItem("BC202").setValue("");
                pricesSetGridFormRight.getItem("BC203").disable();
                pricesSetGridFormRight.getItem("BC203").setValue("");
                pricesSetGridFormRight.getItem("BC204").disable();
                pricesSetGridFormRight.getItem("BC204").setValue("");
                pricesSetGridFormRight.getItem("BC205").disable();
                pricesSetGridFormRight.getItem("BC205").setValue("");
                pricesSetGridFormRight.getItem("BC206").disable();
                pricesSetGridFormRight.getItem("BC206").setValue("");
                pricesSetGridFormRight.getItem("BC207").disable();
                pricesSetGridFormRight.getItem("BC207").setValue("");
                pricesSetGridFormRight.getItem("BC208").disable();
                pricesSetGridFormRight.getItem("BC208").setValue("");
                pricesSetGridFormRight.getItem("BC209").disable();
                pricesSetGridFormRight.getItem("BC209").setValue("");
                pricesSetGridFormRight.getItem("BC210").disable();
                pricesSetGridFormRight.getItem("BC210").setValue("");

            }else if(value == false){
                pricesSetGridFormRight.getItem("BC162").disable();
                pricesSetGridFormRight.getItem("BC162").setValue("");
                pricesSetGridFormRight.getItem("weekends_ntd").disable();

                pricesSetGridFormRight.getItem("BC163").enable();
                pricesSetGridFormRight.getItem("BC163").setValue("");
                pricesSetGridFormRight.getItem("BC164").enable();
                pricesSetGridFormRight.getItem("BC164").setValue("");
                pricesSetGridFormRight.getItem("BC165").enable();
                pricesSetGridFormRight.getItem("BC165").setValue("");
                pricesSetGridFormRight.getItem("BC166").enable();
                pricesSetGridFormRight.getItem("BC166").setValue("");
                pricesSetGridFormRight.getItem("BC167").enable();
                pricesSetGridFormRight.getItem("BC167").setValue("");
                pricesSetGridFormRight.getItem("BC168").enable();
                pricesSetGridFormRight.getItem("BC168").setValue("");
                pricesSetGridFormRight.getItem("BC169").enable();
                pricesSetGridFormRight.getItem("BC169").setValue("");
                pricesSetGridFormRight.getItem("BC170").enable();
                pricesSetGridFormRight.getItem("BC170").setValue("");
                pricesSetGridFormRight.getItem("BC171").enable();
                pricesSetGridFormRight.getItem("BC171").setValue("");
                pricesSetGridFormRight.getItem("BC172").enable();
                pricesSetGridFormRight.getItem("BC172").setValue("");
                pricesSetGridFormRight.getItem("BC173").enable();
                pricesSetGridFormRight.getItem("BC173").setValue("");
                pricesSetGridFormRight.getItem("BC174").enable();
                pricesSetGridFormRight.getItem("BC174").setValue("");
                pricesSetGridFormRight.getItem("BC175").enable();
                pricesSetGridFormRight.getItem("BC175").setValue("");
                pricesSetGridFormRight.getItem("BC176").enable();
                pricesSetGridFormRight.getItem("BC176").setValue("");
                pricesSetGridFormRight.getItem("BC177").enable();
                pricesSetGridFormRight.getItem("BC177").setValue("");
                pricesSetGridFormRight.getItem("BC178").enable();
                pricesSetGridFormRight.getItem("BC178").setValue("");
                pricesSetGridFormRight.getItem("BC179").enable();
                pricesSetGridFormRight.getItem("BC179").setValue("");
                pricesSetGridFormRight.getItem("BC180").enable();
                pricesSetGridFormRight.getItem("BC180").setValue("");
                pricesSetGridFormRight.getItem("BC181").enable();
                pricesSetGridFormRight.getItem("BC181").setValue("");
                pricesSetGridFormRight.getItem("BC182").enable();
                pricesSetGridFormRight.getItem("BC182").setValue("");
                pricesSetGridFormRight.getItem("BC183").enable();
                pricesSetGridFormRight.getItem("BC183").setValue("");
                pricesSetGridFormRight.getItem("BC184").enable();
                pricesSetGridFormRight.getItem("BC184").setValue("");
                pricesSetGridFormRight.getItem("BC185").enable();
                pricesSetGridFormRight.getItem("BC185").setValue("");
                pricesSetGridFormRight.getItem("BC186").enable();
                pricesSetGridFormRight.getItem("BC186").setValue("");
                pricesSetGridFormRight.getItem("BC187").enable();
                pricesSetGridFormRight.getItem("BC187").setValue("");
                pricesSetGridFormRight.getItem("BC188").enable();
                pricesSetGridFormRight.getItem("BC188").setValue("");
                pricesSetGridFormRight.getItem("BC189").enable();
                pricesSetGridFormRight.getItem("BC189").setValue("");
                pricesSetGridFormRight.getItem("BC190").enable();
                pricesSetGridFormRight.getItem("BC190").setValue("");
                pricesSetGridFormRight.getItem("BC191").enable();
                pricesSetGridFormRight.getItem("BC191").setValue("");
                pricesSetGridFormRight.getItem("BC192").enable();
                pricesSetGridFormRight.getItem("BC192").setValue("");
                pricesSetGridFormRight.getItem("BC193").enable();
                pricesSetGridFormRight.getItem("BC193").setValue("");
                pricesSetGridFormRight.getItem("BC194").enable();
                pricesSetGridFormRight.getItem("BC194").setValue("");
                pricesSetGridFormRight.getItem("BC195").enable();
                pricesSetGridFormRight.getItem("BC195").setValue("");
                pricesSetGridFormRight.getItem("BC196").enable();
                pricesSetGridFormRight.getItem("BC196").setValue("");
                pricesSetGridFormRight.getItem("BC197").enable();
                pricesSetGridFormRight.getItem("BC197").setValue("");
                pricesSetGridFormRight.getItem("BC198").enable();
                pricesSetGridFormRight.getItem("BC198").setValue("");
                pricesSetGridFormRight.getItem("BC199").enable();
                pricesSetGridFormRight.getItem("BC199").setValue("");
                pricesSetGridFormRight.getItem("BC200").enable();
                pricesSetGridFormRight.getItem("BC200").setValue("");
                pricesSetGridFormRight.getItem("BC201").enable();
                pricesSetGridFormRight.getItem("BC201").setValue("");
                pricesSetGridFormRight.getItem("BC202").enable();
                pricesSetGridFormRight.getItem("BC202").setValue("");
                pricesSetGridFormRight.getItem("BC203").enable();
                pricesSetGridFormRight.getItem("BC203").setValue("");
                pricesSetGridFormRight.getItem("BC204").enable();
                pricesSetGridFormRight.getItem("BC204").setValue("");
                pricesSetGridFormRight.getItem("BC205").enable();
                pricesSetGridFormRight.getItem("BC205").setValue("");
                pricesSetGridFormRight.getItem("BC206").enable();
                pricesSetGridFormRight.getItem("BC206").setValue("");
                pricesSetGridFormRight.getItem("BC207").enable();
                pricesSetGridFormRight.getItem("BC207").setValue("");
                pricesSetGridFormRight.getItem("BC208").enable();
                pricesSetGridFormRight.getItem("BC208").setValue("");
                pricesSetGridFormRight.getItem("BC209").enable();
                pricesSetGridFormRight.getItem("BC209").setValue("");
                pricesSetGridFormRight.getItem("BC210").enable();
                pricesSetGridFormRight.getItem("BC210").setValue("");
            }
        });

        dhxPricesSetGridFormWindow1.attach(dhxPricesSetGridFormLayout);
        dhxPricesSetGridFormLayout.getCell("PricesSetGridFormLeft").attach(pricesSetGridFormLeft);
        dhxPricesSetGridFormLayout.getCell("PricesSetGridFormRight").attach(pricesSetGridFormRight);

        const dhxPricesSetGridFormWindow2 = new DHXWindow({

            title: "新增日期範圍價格視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 680,
            width: 760,
            footer: true,
            css: "pricesSetGridFormWindow1",
        });

        dhxPricesSetGridFormWindow2.footer.data.add({type:"spacer"});
        dhxPricesSetGridFormWindow2.footer.data.add( JSON.parse( JSON.stringify(pricesWindowFootClose)));
        dhxPricesSetGridFormWindow2.footer.data.add( JSON.parse( JSON.stringify(pricesWindowFootClear)));
        dhxPricesSetGridFormWindow2.footer.data.add( JSON.parse( JSON.stringify(pricesWindowFootAccept)));

        dhxPricesSetGridFormWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    clearPricesSetGridForm2();
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(pricesSetGridForm2.getItem("BC002").getValue() === "0"){
                        DHXMessage({
                            text: "請選擇房型",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(pricesSetGridForm2.getItem("BC101").getValue() === ""){
                        DHXMessage({
                            text: "請填入開始日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(pricesSetGridForm2.getItem("BC102").getValue() === ""){
                        DHXMessage({
                            text: "請填入結束日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    let BC157String = "";
                    let BC157Count = 0;
                    let BC157 = Object.entries(pricesSetGridForm2.getItem("BC157").getValue());
                    let BC157Length = BC157.length;
                    for(let i=0;i<BC157Length;i++){
                        if(BC157[i][1] === true){
                            BC157String += BC157[i][0] + ",";
                            BC157Count++;
                        }
                    }

                    if(BC157Count === 0){
                        BC157String = "";
                        DHXMessage({
                            text: "請選擇星期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(pricesSetGridForm2.getItem("BC103").getValue() === ""){
                        DHXMessage({
                            text: "請填入價格",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(!(Number(pricesSetGridForm2.getItem("BC103").getValue()) > 0)){
                        DHXMessage({
                            text: "價格只能是正整數數值",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(pricesSetGridForm2.getItem("BC104").getValue() === ""){
                        DHXMessage({
                            text: "請填入同業價",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(!(Number(pricesSetGridForm2.getItem("BC104").getValue()) > 0)){
                        DHXMessage({
                            text: "同業價只能是正整數數值",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(pricesSetGridForm2.getItem("BC105").getValue() === ""){
                        DHXMessage({
                            text: "請填入OTA價格",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(!(Number(pricesSetGridForm2.getItem("BC105").getValue()) > 0)){
                        DHXMessage({
                            text: "OTA價格只能是正整數數值",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    let checkHourPrice1 = 1;
                    let BC106Str = 0;
                    if(pricesSetGridForm2.getItem("BC106").getValue() === true){
                        BC106Str = 1;
                        if(pricesSetGridForm2.getItem("BC107").getValue() === ""){
                            checkHourPrice1 = 0;
                            DHXMessage({
                                text: "請填入每小時相同價格",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(!(Number(pricesSetGridForm2.getItem("BC107").getValue()) > 0)){
                            checkHourPrice1 = 0;
                            DHXMessage({
                                text: "每小時相同價格只能是正整數數值",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }
                    }else if(pricesSetGridForm2.getItem("BC106").getValue() === false){
                        BC106Str = 0;
                        let hourArray = document.querySelectorAll(".rangeHours input");
                        for(let i=0;i<hourArray.length;i++){
                            if(hourArray[i].value === ""){
                                checkHourPrice1 = 0;
                                DHXMessage({
                                    text: "請填入每小時價格多少",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(!(Number(hourArray[i].value) > 0)){
                                checkHourPrice1 = 0;
                                DHXMessage({
                                    text: "每小時價格只能是正整數數值",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }
                        }
                    }

                    if(checkHourPrice1 === 0){
                        break;
                    }

                    let pricesSetGridFormValue = Object.assign({},pricesSetGridForm2.getValue());
                    pricesSetGridFormValue.BC157 = BC157String;
                    pricesSetGridFormValue.BC106 = BC106Str;
                    axios.post(apiServer+"/prices/updatePricesSetGridFormData", {
                        pricesSetGridFormValue
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data[0].result > 0){
                                DHXMessage({
                                    text: response.data[0].message,
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });
                                axios.post(apiServer+"/prices/loadPricesSetGrid",{itemId1:hotel},headers)
                                    .then((data) =>{
                                        dhxPricesSetGridFormWindow2.hide();
                                        dhxPricesSetGrid.data.removeAll();
                                        dhxPricesSetGrid.data.parse(data.data);
                                    });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        }
                    });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxPricesSetGridFormWindow2.hide();
                    break;
                default:
                    break;
            }
        })

        const pricesSetGridForm2 = new DHXForm(null,{
            rows: pricesSetGridForm2Config
        });

        // pricesSetGridForm2.getItem("BC003").events.on("change", function(value) {
        //     console.log(value);
        //     if(value === "0"){
        //         pricesSetGridForm2.getItem("BC002").setValue("0");
        //         pricesSetGridForm2.getItem("BC002").disable();
        //     }else{
        //         axios.post(apiServer+"/prices/loadMainProductsList", {
        //             itemId1: pricesSetGridForm2.getItem("BC003").getValue(),
        //         },headers).then((response) =>{
        //             pricesSetGridForm2.getItem("BC002").setOptions(response.data);
        //             pricesSetGridForm2.getItem("BC002").enable();
        //         })
        //             .catch((error) => {
        //                 console.log(error);
        //             });
        //     }
        // });

        let pricesSetGridForm2StartDate = pricesSetGridForm2.getItem("BC101").getWidget();
        let pricesSetGridForm2EndDate = pricesSetGridForm2.getItem("BC102").getWidget();
        pricesSetGridForm2StartDate.link(pricesSetGridForm2EndDate)

        dhxPricesSetGridFormWindow2.events.on("click", function (name, e){
            switch (name){
                case "send":
                    dhxPricesSetGridFormWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        pricesSetGridForm2.getItem("BC106").events.on("change", function(value) {
            if(value == true){

                pricesSetGridForm2.getItem("BC107").enable();
                pricesSetGridForm2.getItem("BC107").setValue("");
                pricesSetGridForm2.getItem("range_ntd").enable();

                pricesSetGridForm2.getItem("BC108").disable();
                pricesSetGridForm2.getItem("BC108").setValue("");
                pricesSetGridForm2.getItem("BC109").disable();
                pricesSetGridForm2.getItem("BC109").setValue("");
                pricesSetGridForm2.getItem("BC110").disable();
                pricesSetGridForm2.getItem("BC110").setValue("");
                pricesSetGridForm2.getItem("BC111").disable();
                pricesSetGridForm2.getItem("BC111").setValue("");
                pricesSetGridForm2.getItem("BC112").disable();
                pricesSetGridForm2.getItem("BC112").setValue("");
                pricesSetGridForm2.getItem("BC113").disable();
                pricesSetGridForm2.getItem("BC113").setValue("");
                pricesSetGridForm2.getItem("BC114").disable();
                pricesSetGridForm2.getItem("BC114").setValue("");
                pricesSetGridForm2.getItem("BC115").disable();
                pricesSetGridForm2.getItem("BC115").setValue("");
                pricesSetGridForm2.getItem("BC116").disable();
                pricesSetGridForm2.getItem("BC116").setValue("");
                pricesSetGridForm2.getItem("BC117").disable();
                pricesSetGridForm2.getItem("BC117").setValue("");
                pricesSetGridForm2.getItem("BC118").disable();
                pricesSetGridForm2.getItem("BC118").setValue("");
                pricesSetGridForm2.getItem("BC119").disable();
                pricesSetGridForm2.getItem("BC119").setValue("");
                pricesSetGridForm2.getItem("BC120").disable();
                pricesSetGridForm2.getItem("BC120").setValue("");
                pricesSetGridForm2.getItem("BC121").disable();
                pricesSetGridForm2.getItem("BC121").setValue("");
                pricesSetGridForm2.getItem("BC122").disable();
                pricesSetGridForm2.getItem("BC122").setValue("");
                pricesSetGridForm2.getItem("BC123").disable();
                pricesSetGridForm2.getItem("BC123").setValue("");
                pricesSetGridForm2.getItem("BC124").disable();
                pricesSetGridForm2.getItem("BC124").setValue("");
                pricesSetGridForm2.getItem("BC125").disable();
                pricesSetGridForm2.getItem("BC125").setValue("");
                pricesSetGridForm2.getItem("BC126").disable();
                pricesSetGridForm2.getItem("BC126").setValue("");
                pricesSetGridForm2.getItem("BC127").disable();
                pricesSetGridForm2.getItem("BC127").setValue("");
                pricesSetGridForm2.getItem("BC128").disable();
                pricesSetGridForm2.getItem("BC128").setValue("");
                pricesSetGridForm2.getItem("BC129").disable();
                pricesSetGridForm2.getItem("BC129").setValue("");
                pricesSetGridForm2.getItem("BC130").disable();
                pricesSetGridForm2.getItem("BC130").setValue("");
                pricesSetGridForm2.getItem("BC131").disable();
                pricesSetGridForm2.getItem("BC131").setValue("");
                pricesSetGridForm2.getItem("BC132").disable();
                pricesSetGridForm2.getItem("BC132").setValue("");
                pricesSetGridForm2.getItem("BC133").disable();
                pricesSetGridForm2.getItem("BC133").setValue("");
                pricesSetGridForm2.getItem("BC134").disable();
                pricesSetGridForm2.getItem("BC134").setValue("");
                pricesSetGridForm2.getItem("BC135").disable();
                pricesSetGridForm2.getItem("BC135").setValue("");
                pricesSetGridForm2.getItem("BC136").disable();
                pricesSetGridForm2.getItem("BC136").setValue("");
                pricesSetGridForm2.getItem("BC137").disable();
                pricesSetGridForm2.getItem("BC137").setValue("");
                pricesSetGridForm2.getItem("BC138").disable();
                pricesSetGridForm2.getItem("BC138").setValue("");
                pricesSetGridForm2.getItem("BC139").disable();
                pricesSetGridForm2.getItem("BC139").setValue("");
                pricesSetGridForm2.getItem("BC140").disable();
                pricesSetGridForm2.getItem("BC140").setValue("");
                pricesSetGridForm2.getItem("BC141").disable();
                pricesSetGridForm2.getItem("BC141").setValue("");
                pricesSetGridForm2.getItem("BC142").disable();
                pricesSetGridForm2.getItem("BC142").setValue("");
                pricesSetGridForm2.getItem("BC143").disable();
                pricesSetGridForm2.getItem("BC143").setValue("");
                pricesSetGridForm2.getItem("BC144").disable();
                pricesSetGridForm2.getItem("BC144").setValue("");
                pricesSetGridForm2.getItem("BC145").disable();
                pricesSetGridForm2.getItem("BC145").setValue("");
                pricesSetGridForm2.getItem("BC146").disable();
                pricesSetGridForm2.getItem("BC146").setValue("");
                pricesSetGridForm2.getItem("BC147").disable();
                pricesSetGridForm2.getItem("BC147").setValue("");
                pricesSetGridForm2.getItem("BC148").disable();
                pricesSetGridForm2.getItem("BC148").setValue("");
                pricesSetGridForm2.getItem("BC149").disable();
                pricesSetGridForm2.getItem("BC149").setValue("");
                pricesSetGridForm2.getItem("BC150").disable();
                pricesSetGridForm2.getItem("BC150").setValue("");
                pricesSetGridForm2.getItem("BC151").disable();
                pricesSetGridForm2.getItem("BC151").setValue("");
                pricesSetGridForm2.getItem("BC152").disable();
                pricesSetGridForm2.getItem("BC152").setValue("");
                pricesSetGridForm2.getItem("BC153").disable();
                pricesSetGridForm2.getItem("BC153").setValue("");
                pricesSetGridForm2.getItem("BC154").disable();
                pricesSetGridForm2.getItem("BC154").setValue("");
                pricesSetGridForm2.getItem("BC155").disable();
                pricesSetGridForm2.getItem("BC155").setValue("");

            }else if(value == false){

                pricesSetGridForm2.getItem("BC107").disable();
                pricesSetGridForm2.getItem("BC107").setValue("");
                pricesSetGridForm2.getItem("range_ntd").disable();

                pricesSetGridForm2.getItem("BC108").enable();
                pricesSetGridForm2.getItem("BC108").setValue("");
                pricesSetGridForm2.getItem("BC109").enable();
                pricesSetGridForm2.getItem("BC109").setValue("");
                pricesSetGridForm2.getItem("BC110").enable();
                pricesSetGridForm2.getItem("BC110").setValue("");
                pricesSetGridForm2.getItem("BC111").enable();
                pricesSetGridForm2.getItem("BC111").setValue("");
                pricesSetGridForm2.getItem("BC112").enable();
                pricesSetGridForm2.getItem("BC112").setValue("");
                pricesSetGridForm2.getItem("BC113").enable();
                pricesSetGridForm2.getItem("BC113").setValue("");
                pricesSetGridForm2.getItem("BC114").enable();
                pricesSetGridForm2.getItem("BC114").setValue("");
                pricesSetGridForm2.getItem("BC115").enable();
                pricesSetGridForm2.getItem("BC115").setValue("");
                pricesSetGridForm2.getItem("BC116").enable();
                pricesSetGridForm2.getItem("BC116").setValue("");
                pricesSetGridForm2.getItem("BC117").enable();
                pricesSetGridForm2.getItem("BC117").setValue("");
                pricesSetGridForm2.getItem("BC118").enable();
                pricesSetGridForm2.getItem("BC118").setValue("");
                pricesSetGridForm2.getItem("BC119").enable();
                pricesSetGridForm2.getItem("BC119").setValue("");
                pricesSetGridForm2.getItem("BC120").enable();
                pricesSetGridForm2.getItem("BC120").setValue("");
                pricesSetGridForm2.getItem("BC121").enable();
                pricesSetGridForm2.getItem("BC121").setValue("");
                pricesSetGridForm2.getItem("BC122").enable();
                pricesSetGridForm2.getItem("BC122").setValue("");
                pricesSetGridForm2.getItem("BC123").enable();
                pricesSetGridForm2.getItem("BC123").setValue("");
                pricesSetGridForm2.getItem("BC124").enable();
                pricesSetGridForm2.getItem("BC124").setValue("");
                pricesSetGridForm2.getItem("BC125").enable();
                pricesSetGridForm2.getItem("BC125").setValue("");
                pricesSetGridForm2.getItem("BC126").enable();
                pricesSetGridForm2.getItem("BC126").setValue("");
                pricesSetGridForm2.getItem("BC127").enable();
                pricesSetGridForm2.getItem("BC127").setValue("");
                pricesSetGridForm2.getItem("BC128").enable();
                pricesSetGridForm2.getItem("BC128").setValue("");
                pricesSetGridForm2.getItem("BC129").enable();
                pricesSetGridForm2.getItem("BC129").setValue("");
                pricesSetGridForm2.getItem("BC130").enable();
                pricesSetGridForm2.getItem("BC130").setValue("");
                pricesSetGridForm2.getItem("BC131").enable();
                pricesSetGridForm2.getItem("BC131").setValue("");
                pricesSetGridForm2.getItem("BC132").enable();
                pricesSetGridForm2.getItem("BC132").setValue("");
                pricesSetGridForm2.getItem("BC133").enable();
                pricesSetGridForm2.getItem("BC133").setValue("");
                pricesSetGridForm2.getItem("BC134").enable();
                pricesSetGridForm2.getItem("BC134").setValue("");
                pricesSetGridForm2.getItem("BC135").enable();
                pricesSetGridForm2.getItem("BC135").setValue("");
                pricesSetGridForm2.getItem("BC136").enable();
                pricesSetGridForm2.getItem("BC136").setValue("");
                pricesSetGridForm2.getItem("BC137").enable();
                pricesSetGridForm2.getItem("BC137").setValue("");
                pricesSetGridForm2.getItem("BC138").enable();
                pricesSetGridForm2.getItem("BC138").setValue("");
                pricesSetGridForm2.getItem("BC139").enable();
                pricesSetGridForm2.getItem("BC139").setValue("");
                pricesSetGridForm2.getItem("BC140").enable();
                pricesSetGridForm2.getItem("BC140").setValue("");
                pricesSetGridForm2.getItem("BC141").enable();
                pricesSetGridForm2.getItem("BC141").setValue("");
                pricesSetGridForm2.getItem("BC142").enable();
                pricesSetGridForm2.getItem("BC142").setValue("");
                pricesSetGridForm2.getItem("BC143").enable();
                pricesSetGridForm2.getItem("BC143").setValue("");
                pricesSetGridForm2.getItem("BC144").enable();
                pricesSetGridForm2.getItem("BC144").setValue("");
                pricesSetGridForm2.getItem("BC145").enable();
                pricesSetGridForm2.getItem("BC145").setValue("");
                pricesSetGridForm2.getItem("BC146").enable();
                pricesSetGridForm2.getItem("BC146").setValue("");
                pricesSetGridForm2.getItem("BC147").enable();
                pricesSetGridForm2.getItem("BC147").setValue("");
                pricesSetGridForm2.getItem("BC148").enable();
                pricesSetGridForm2.getItem("BC148").setValue("");
                pricesSetGridForm2.getItem("BC149").enable();
                pricesSetGridForm2.getItem("BC149").setValue("");
                pricesSetGridForm2.getItem("BC150").enable();
                pricesSetGridForm2.getItem("BC150").setValue("");
                pricesSetGridForm2.getItem("BC151").enable();
                pricesSetGridForm2.getItem("BC151").setValue("");
                pricesSetGridForm2.getItem("BC152").enable();
                pricesSetGridForm2.getItem("BC152").setValue("");
                pricesSetGridForm2.getItem("BC153").enable();
                pricesSetGridForm2.getItem("BC153").setValue("");
                pricesSetGridForm2.getItem("BC154").enable();
                pricesSetGridForm2.getItem("BC154").setValue("");
                pricesSetGridForm2.getItem("BC155").enable();
                pricesSetGridForm2.getItem("BC155").setValue("");
            }
        });

        dhxPricesSetGridFormWindow2.attach(pricesSetGridForm2);

        const dhxPricesSetGridFormWindow3 = new DHXWindow({
            title: "刪除價格設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 660,
            footer: true,
            css: "pricesSetGridFormWindow3"
        });

        dhxPricesSetGridFormWindow3.footer.data.add({type:"spacer"});
        dhxPricesSetGridFormWindow3.footer.data.add( JSON.parse( JSON.stringify(pricesWindowFootClose)));
        dhxPricesSetGridFormWindow3.footer.data.add( JSON.parse( JSON.stringify(pricesWindowFootAccept)));

        dhxPricesSetGridFormWindow3.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    if(pricesSetGridDeleteForm.getItem("BC212").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/prices/deletePrices", {
                        itemId1: pricesSetGridDeleteForm.getItem("BC001").getValue(),
                        itemId2: pricesSetGridDeleteForm.getItem("BC212").getValue(),
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data[0].result > 0){
                                DHXMessage({
                                    text: "刪除成功",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                axios.post(apiServer+"/prices/loadPricesSetGrid",{itemId1:hotel},headers)
                                    .then((data) =>{
                                        dhxPricesSetGridFormWindow3.hide();
                                        dhxPricesSetGrid.data.removeAll();
                                        dhxPricesSetGrid.data.parse(data.data);
                                    });
                                pricesSetGridDeleteForm.clear();
                                pricesSetGridDeleteForm.setValue({"BC001": "0"});
                                pricesSetGridDeleteForm.clear("validation");
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        }

                    }).catch((error) => {
                        console.log(error);
                    });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxPricesSetGridFormWindow3.hide();
                    dhxPricesSetGridFormWindow3.clear();
                    dhxPricesSetGridFormWindow3.setValue({"BC001": "0"});
                    dhxPricesSetGridFormWindow3.clear("validation");
                    break;
                default:
                    break;
            }
        });

        let pricesSetGridDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"pricesSetGridDeleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "pricesSetGridDeleteForm"},
            ]
        });

        const pricesSetGridDeleteForm = new DHXForm(null,{
            rows: pricesSetGridDeleteFormConfig
        });

        dhxPricesSetGridFormWindow3.attach(pricesSetGridDeleteFormLayout);
        pricesSetGridDeleteFormLayout.getCell("pricesSetGridDeleteForm").attach(pricesSetGridDeleteForm);

        const toolbar2 = new DHXToolbar(null, {
        });
        toolbar2.events.on("click", function(id,e){
            switch (id) {
                case "add1":
                    if(canEdit){
                        dhxPricesSetGridFormWindow1.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                case "add2":
                    if(canEdit){
                        clearPricesSetGridForm2();
                        dhxPricesSetGridFormWindow2.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                case "openBookingSetting":
                    if(canDelete){
                        openBookingSettingForm.clear();

                        axios.post(apiServer+"/prices/loadOpenBookingSettingFormData", {
                            itemId1: hotel
                        },headers).then((response) =>{
                            console.log(response.data)

                            if(JSON.parse(response.data.length > 0)){
                                console.log(response.data[0]);
                                openBookingSettingForm.setValue(response.data[0]);
                                if(response.data[0].BG100 === "1"){
                                    openBookingSettingForm.getItem("BG101").enable();
                                    openBookingSettingForm.getItem("BG102").disable();
                                    openBookingSettingForm.getItem("BG102").clear();
                                    openBookingSettingForm.getItem("BG103").disable();
                                    openBookingSettingForm.getItem("BG103").clear();
                                }else if(response.data[0].BG100 === "2"){
                                    openBookingSettingForm.getItem("BG101").disable();
                                    openBookingSettingForm.getItem("BG102").enable();
                                    openBookingSettingForm.getItem("BG102").clear();
                                    openBookingSettingForm.getItem("BG103").enable();
                                    openBookingSettingForm.getItem("BG103").clear();
                                }
                            }else{
                                console.log("false");
                                openBookingSettingForm.getItem("BG001").setValue("0");
                                openBookingSettingForm.getItem("BG002").setValue(hotel);

                                openBookingSettingForm.getItem("BG100").setValue("1");
                                openBookingSettingForm.getItem("BG101").setValue("");
                                openBookingSettingForm.getItem("BG101").enable();
                                openBookingSettingForm.getItem("BG102").disable();
                                openBookingSettingForm.getItem("BG102").clear();
                                openBookingSettingForm.getItem("BG103").disable();
                                openBookingSettingForm.getItem("BG103").clear();
                            }
                        }).catch((error) => {
                            console.log(error);
                        });

                        openBookingSettingForm.setValue({"BG100":"1","BG002":hotel});
                        openBookingSettingForm.getItem("BG101").enable();
                        dhxPricesSetGridFormWindow4.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有刪除的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                default:
                    break;
            }
        });
        toolbar2.data.parse(JSON.parse(JSON.stringify(toolbar2Config)));
        // dhxPricesLayout.getCell("statusToolbar").attach(toolbar2);

        //開放訂房設定
        const dhxPricesSetGridFormWindow4 = new DHXWindow({
            title: "開放訂房設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:600,
            height: 300,
            footer: true,
            css: "pricesSetGridFormWindow4"
        });

        dhxPricesSetGridFormWindow4.footer.data.add({type:"spacer"});
        dhxPricesSetGridFormWindow4.footer.data.add( JSON.parse( JSON.stringify(pricesWindowFootClose)));
        dhxPricesSetGridFormWindow4.footer.data.add( JSON.parse( JSON.stringify(pricesWindowFootAccept)));

        dhxPricesSetGridFormWindow4.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":

                    console.log(openBookingSettingForm.getItem("BG100").getValue());

                    let openBookingValue = 1,messageText = "";
                    if(openBookingSettingForm.getItem("BG100").getValue() === "1"){
                        openBookingValue = 1;
                        messageText = "";
                        if(openBookingSettingForm.getItem("BG101").getValue() === ""){
                            openBookingValue = 0;
                            messageText = "請填入天數";
                        }

                    }else if(openBookingSettingForm.getItem("BG100").getValue() === "2"){
                        openBookingValue = 1;
                        messageText = "";
                        if(openBookingSettingForm.getItem("BG102").getValue() === "" || openBookingSettingForm.getItem("BG103").getValue() === ""){
                            openBookingValue = 0;
                            messageText = "選擇日期範圍";
                        }
                    }else{
                        DHXMessage({
                            text: "請選擇開放訂房類型",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }

                    if(openBookingValue !== 1){
                        DHXMessage({
                            text: messageText,
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    axios.post(apiServer+"/prices/updateOpenBookingSettingFormData",
                        openBookingSettingForm.getValue(),headers
                    ).then((response) =>{
                        // console.log(response.data[0]);
                        // let r =  JSON.parse(response);
                        if(response.data[0].result){
                            DHXMessage({
                                text: response.data[0].message,
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            dhxPricesSetGridFormWindow4.hide();
                            openBookingSettingForm.clear();
                            openBookingSettingForm.clear("validation");

                            let openBookingString = '';
                            let openBookingStatus = document.getElementById("openBookingStatus");
                            openBookingStatus.innerText = response.data[0].openBookingString;
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });


                    // openBookingSettingForm.send(apiServer+"/prices/updateOpenBookingSettingFormData","POST")
                    //     .then((response)=>{
                    //         let r = JSON.parse(response);
                    //         console.log(r[0].message);
                    //         if(r[0].result){
                    //
                    //             DHXMessage({
                    //                 text: r[0].message,
                    //                 icon:"dxi dxi-close",
                    //                 css: "dhx_message--success",
                    //                 expire:5000
                    //             });
                    //
                    //             dhxPricesSetGridFormWindow4.hide();
                    //             openBookingSettingForm.clear();
                    //             openBookingSettingForm.clear("validation");
                    //
                    //             let openBookingString = '';
                    //             let openBookingStatus = document.getElementById("openBookingStatus");
                    //             openBookingStatus.innerText = r[0].openBookingString;
                    //
                    //         }else{
                    //             DHXMessage({
                    //                 text: "連線異常，請重新整理",
                    //                 icon:"dxi dxi-close",
                    //                 css: "dhx_message--danger",
                    //                 expire:5000
                    //             });
                    //         }
                    //
                    //     }, err => {
                    //         console.log("then->err->"+err);
                    //     })
                    //     .catch(error => {
                    //         console.log("error =>"+error);
                    //     });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxPricesSetGridFormWindow4.hide();
                    openBookingSettingForm.clear();
                    openBookingSettingForm.getItem("BG100").setValue("1");
                    break;
                default:
                    break;
            }
        });

        const openBookingSettingForm = new DHXForm(null,{
            rows: openBookingSettingFormConfig
        });

        let openBookingSettingFormLockStartDate = openBookingSettingForm.getItem("BG102").getWidget();
        let openBookingSettingFormLockEndDate = openBookingSettingForm.getItem("BG103").getWidget();
        openBookingSettingFormLockStartDate.link(openBookingSettingFormLockEndDate)

        openBookingSettingForm.getItem("BG100").events.on("change", function(value) {
            if(value == "1"){
                openBookingSettingForm.getItem("BG101").enable();
                openBookingSettingForm.getItem("BG101").setValue("");
                openBookingSettingForm.getItem("BG102").disable();
                openBookingSettingForm.getItem("BG102").clear();
                openBookingSettingForm.getItem("BG103").disable();
                openBookingSettingForm.getItem("BG103").clear();
            }else if(value == "2"){
                openBookingSettingForm.getItem("BG101").disable();
                openBookingSettingForm.getItem("BG101").setValue("");
                openBookingSettingForm.getItem("BG102").enable();
                openBookingSettingForm.getItem("BG102").clear();
                openBookingSettingForm.getItem("BG103").enable();
                openBookingSettingForm.getItem("BG103").clear();
            }
        });

        dhxPricesSetGridFormWindow4.attach(openBookingSettingForm);

        //瀏覽價格表示窗
        const dhxPricesSetGridFormWindow5 = new DHXWindow({
            title: "瀏覽價格表",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:700,
            height: 600,
            footer: true,
            css: "pricesSetGridFormWindow5"
        });

        dhxPricesSetGridFormWindow5.footer.data.add({type:"spacer"});
        dhxPricesSetGridFormWindow5.footer.data.add( JSON.parse( JSON.stringify(pricesWindowFootClose)));
        // dhxPricesSetGridFormWindow4.footer.data.add( JSON.parse( JSON.stringify(pricesWindowFootAccept)));

        // dhxPricesSetGridFormWindow5.footer.events.on("click",(itemId1) =>{
        //     switch (itemId1){
        //         case "WINDOW_FOOT_CLOSE":
        //             dhxPricesSetGridFormWindow5.hide();
        //             break;
        //         default:
        //             break;
        //     }
        // });
        //
        // let dhxPricesSetGridFormWindow5Layout = new DHXLayout(null,{
        //     rows:[
        //         {id:"pricesTableToolbar", height: "content", gravity: false},
        //         {id: "pricesTableGrid"},
        //     ]
        // });
        //
        // let pricesTableGrid = new DHXGrid(null,{
        //     columns: pricesTableGridColumn,
        //     autoWidth: true,
        //     resizable: true,
        // });
        //
        // const pricesTableToolbar = new DHXToolbar(null, {
        // });
        //
        // pricesTableToolbar.events.on("click", function(id,e){
        //     switch (id) {
        //         case "add":
        //             break;
        //         default:
        //             break;
        //     }
        // });
        // pricesTableToolbar.data.parse(JSON.parse(JSON.stringify(toolbar3Config)));
        //
        // dhxPricesSetGridFormWindow5.attach(dhxPricesSetGridFormWindow5Layout);
        // dhxPricesSetGridFormWindow5Layout.getCell("pricesTableToolbar").attach(pricesTableToolbar);
        // dhxPricesSetGridFormWindow5Layout.getCell("pricesTableGrid").attach(pricesTableGrid);

        //清除房型價格設定
        function clearPricesSetGridForm1(){
            pricesSetGridFormLeft.getItem("BC101").clear();
            pricesSetGridFormLeft.getItem("BC102").clear();
            pricesSetGridFormLeft.getItem("BC103").setValue("");
            pricesSetGridFormLeft.getItem("BC104").setValue("");
            pricesSetGridFormLeft.getItem("BC105").setValue("");
            pricesSetGridFormLeft.getItem("BC106").setValue(true);
            pricesSetGridFormLeft.getItem("BC107").enable();
            pricesSetGridFormLeft.getItem("BC107").setValue("");

            pricesSetGridFormLeft.getItem("BC107").enable();
            pricesSetGridFormLeft.getItem("BC107").setValue("");

            pricesSetGridFormLeft.getItem("BC108").disable();
            pricesSetGridFormLeft.getItem("BC108").setValue("");
            pricesSetGridFormLeft.getItem("BC109").disable();
            pricesSetGridFormLeft.getItem("BC109").setValue("");
            pricesSetGridFormLeft.getItem("BC110").disable();
            pricesSetGridFormLeft.getItem("BC110").setValue("");
            pricesSetGridFormLeft.getItem("BC111").disable();
            pricesSetGridFormLeft.getItem("BC111").setValue("");
            pricesSetGridFormLeft.getItem("BC112").disable();
            pricesSetGridFormLeft.getItem("BC112").setValue("");
            pricesSetGridFormLeft.getItem("BC113").disable();
            pricesSetGridFormLeft.getItem("BC113").setValue("");
            pricesSetGridFormLeft.getItem("BC114").disable();
            pricesSetGridFormLeft.getItem("BC114").setValue("");
            pricesSetGridFormLeft.getItem("BC115").disable();
            pricesSetGridFormLeft.getItem("BC115").setValue("");
            pricesSetGridFormLeft.getItem("BC116").disable();
            pricesSetGridFormLeft.getItem("BC116").setValue("");
            pricesSetGridFormLeft.getItem("BC117").disable();
            pricesSetGridFormLeft.getItem("BC117").setValue("");
            pricesSetGridFormLeft.getItem("BC118").disable();
            pricesSetGridFormLeft.getItem("BC118").setValue("");
            pricesSetGridFormLeft.getItem("BC119").disable();
            pricesSetGridFormLeft.getItem("BC119").setValue("");
            pricesSetGridFormLeft.getItem("BC120").disable();
            pricesSetGridFormLeft.getItem("BC120").setValue("");
            pricesSetGridFormLeft.getItem("BC121").disable();
            pricesSetGridFormLeft.getItem("BC121").setValue("");
            pricesSetGridFormLeft.getItem("BC122").disable();
            pricesSetGridFormLeft.getItem("BC122").setValue("");
            pricesSetGridFormLeft.getItem("BC123").disable();
            pricesSetGridFormLeft.getItem("BC123").setValue("");
            pricesSetGridFormLeft.getItem("BC124").disable();
            pricesSetGridFormLeft.getItem("BC124").setValue("");
            pricesSetGridFormLeft.getItem("BC125").disable();
            pricesSetGridFormLeft.getItem("BC125").setValue("");
            pricesSetGridFormLeft.getItem("BC126").disable();
            pricesSetGridFormLeft.getItem("BC126").setValue("");
            pricesSetGridFormLeft.getItem("BC127").disable();
            pricesSetGridFormLeft.getItem("BC127").setValue("");
            pricesSetGridFormLeft.getItem("BC128").disable();
            pricesSetGridFormLeft.getItem("BC128").setValue("");
            pricesSetGridFormLeft.getItem("BC129").disable();
            pricesSetGridFormLeft.getItem("BC129").setValue("");
            pricesSetGridFormLeft.getItem("BC130").disable();
            pricesSetGridFormLeft.getItem("BC130").setValue("");
            pricesSetGridFormLeft.getItem("BC131").disable();
            pricesSetGridFormLeft.getItem("BC131").setValue("");
            pricesSetGridFormLeft.getItem("BC132").disable();
            pricesSetGridFormLeft.getItem("BC132").setValue("");
            pricesSetGridFormLeft.getItem("BC133").disable();
            pricesSetGridFormLeft.getItem("BC133").setValue("");
            pricesSetGridFormLeft.getItem("BC134").disable();
            pricesSetGridFormLeft.getItem("BC134").setValue("");
            pricesSetGridFormLeft.getItem("BC135").disable();
            pricesSetGridFormLeft.getItem("BC135").setValue("");
            pricesSetGridFormLeft.getItem("BC136").disable();
            pricesSetGridFormLeft.getItem("BC136").setValue("");
            pricesSetGridFormLeft.getItem("BC137").disable();
            pricesSetGridFormLeft.getItem("BC137").setValue("");
            pricesSetGridFormLeft.getItem("BC138").disable();
            pricesSetGridFormLeft.getItem("BC138").setValue("");
            pricesSetGridFormLeft.getItem("BC139").disable();
            pricesSetGridFormLeft.getItem("BC139").setValue("");
            pricesSetGridFormLeft.getItem("BC140").disable();
            pricesSetGridFormLeft.getItem("BC140").setValue("");
            pricesSetGridFormLeft.getItem("BC141").disable();
            pricesSetGridFormLeft.getItem("BC141").setValue("");
            pricesSetGridFormLeft.getItem("BC142").disable();
            pricesSetGridFormLeft.getItem("BC142").setValue("");
            pricesSetGridFormLeft.getItem("BC143").disable();
            pricesSetGridFormLeft.getItem("BC143").setValue("");
            pricesSetGridFormLeft.getItem("BC144").disable();
            pricesSetGridFormLeft.getItem("BC144").setValue("");
            pricesSetGridFormLeft.getItem("BC145").disable();
            pricesSetGridFormLeft.getItem("BC145").setValue("");
            pricesSetGridFormLeft.getItem("BC146").disable();
            pricesSetGridFormLeft.getItem("BC146").setValue("");
            pricesSetGridFormLeft.getItem("BC147").disable();
            pricesSetGridFormLeft.getItem("BC147").setValue("");
            pricesSetGridFormLeft.getItem("BC148").disable();
            pricesSetGridFormLeft.getItem("BC148").setValue("");
            pricesSetGridFormLeft.getItem("BC149").disable();
            pricesSetGridFormLeft.getItem("BC149").setValue("");
            pricesSetGridFormLeft.getItem("BC150").disable();
            pricesSetGridFormLeft.getItem("BC150").setValue("");
            pricesSetGridFormLeft.getItem("BC151").disable();
            pricesSetGridFormLeft.getItem("BC151").setValue("");
            pricesSetGridFormLeft.getItem("BC152").disable();
            pricesSetGridFormLeft.getItem("BC152").setValue("");
            pricesSetGridFormLeft.getItem("BC153").disable();
            pricesSetGridFormLeft.getItem("BC153").setValue("");
            pricesSetGridFormLeft.getItem("BC154").disable();
            pricesSetGridFormLeft.getItem("BC154").setValue("");
            pricesSetGridFormLeft.getItem("BC155").disable();
            pricesSetGridFormLeft.getItem("BC155").setValue("");
            pricesSetGridFormLeft.getItem("BC211").setValue("");

            pricesSetGridFormRight.getItem("BC156").setValue(false);
            pricesSetGridFormRight.getItem("BC157").clear();
            pricesSetGridFormRight.getItem("BC157").disable();
            pricesSetGridFormRight.getItem("BC158").disable();
            pricesSetGridFormRight.getItem("BC158").setValue("");
            pricesSetGridFormRight.getItem("BC159").disable();
            pricesSetGridFormRight.getItem("BC159").setValue("");
            pricesSetGridFormRight.getItem("BC160").disable();
            pricesSetGridFormRight.getItem("BC160").setValue("");

            pricesSetGridFormRight.getItem("BC161").disable();
            pricesSetGridFormRight.getItem("BC161").setValue(false);
            pricesSetGridFormRight.getItem("BC162").disable();
            pricesSetGridFormRight.getItem("BC162").setValue("");
            pricesSetGridFormRight.getItem("weekends_ntd").disable();

            pricesSetGridFormRight.getItem("BC163").disable();
            pricesSetGridFormRight.getItem("BC163").setValue("");
            pricesSetGridFormRight.getItem("BC164").disable();
            pricesSetGridFormRight.getItem("BC164").setValue("");
            pricesSetGridFormRight.getItem("BC165").disable();
            pricesSetGridFormRight.getItem("BC165").setValue("");
            pricesSetGridFormRight.getItem("BC166").disable();
            pricesSetGridFormRight.getItem("BC166").setValue("");
            pricesSetGridFormRight.getItem("BC167").disable();
            pricesSetGridFormRight.getItem("BC167").setValue("");
            pricesSetGridFormRight.getItem("BC168").disable();
            pricesSetGridFormRight.getItem("BC168").setValue("");
            pricesSetGridFormRight.getItem("BC169").disable();
            pricesSetGridFormRight.getItem("BC169").setValue("");
            pricesSetGridFormRight.getItem("BC170").disable();
            pricesSetGridFormRight.getItem("BC170").setValue("");
            pricesSetGridFormRight.getItem("BC171").disable();
            pricesSetGridFormRight.getItem("BC171").setValue("");
            pricesSetGridFormRight.getItem("BC172").disable();
            pricesSetGridFormRight.getItem("BC172").setValue("");
            pricesSetGridFormRight.getItem("BC173").disable();
            pricesSetGridFormRight.getItem("BC173").setValue("");
            pricesSetGridFormRight.getItem("BC174").disable();
            pricesSetGridFormRight.getItem("BC174").setValue("");
            pricesSetGridFormRight.getItem("BC175").disable();
            pricesSetGridFormRight.getItem("BC175").setValue("");
            pricesSetGridFormRight.getItem("BC176").disable();
            pricesSetGridFormRight.getItem("BC176").setValue("");
            pricesSetGridFormRight.getItem("BC177").disable();
            pricesSetGridFormRight.getItem("BC177").setValue("");
            pricesSetGridFormRight.getItem("BC178").disable();
            pricesSetGridFormRight.getItem("BC178").setValue("");
            pricesSetGridFormRight.getItem("BC179").disable();
            pricesSetGridFormRight.getItem("BC179").setValue("");
            pricesSetGridFormRight.getItem("BC180").disable();
            pricesSetGridFormRight.getItem("BC180").setValue("");
            pricesSetGridFormRight.getItem("BC181").disable();
            pricesSetGridFormRight.getItem("BC181").setValue("");
            pricesSetGridFormRight.getItem("BC182").disable();
            pricesSetGridFormRight.getItem("BC182").setValue("");
            pricesSetGridFormRight.getItem("BC183").disable();
            pricesSetGridFormRight.getItem("BC183").setValue("");
            pricesSetGridFormRight.getItem("BC184").disable();
            pricesSetGridFormRight.getItem("BC184").setValue("");
            pricesSetGridFormRight.getItem("BC185").disable();
            pricesSetGridFormRight.getItem("BC185").setValue("");
            pricesSetGridFormRight.getItem("BC186").disable();
            pricesSetGridFormRight.getItem("BC186").setValue("");
            pricesSetGridFormRight.getItem("BC187").disable();
            pricesSetGridFormRight.getItem("BC187").setValue("");
            pricesSetGridFormRight.getItem("BC188").disable();
            pricesSetGridFormRight.getItem("BC188").setValue("");
            pricesSetGridFormRight.getItem("BC189").disable();
            pricesSetGridFormRight.getItem("BC189").setValue("");
            pricesSetGridFormRight.getItem("BC190").disable();
            pricesSetGridFormRight.getItem("BC190").setValue("");
            pricesSetGridFormRight.getItem("BC191").disable();
            pricesSetGridFormRight.getItem("BC191").setValue("");
            pricesSetGridFormRight.getItem("BC192").disable();
            pricesSetGridFormRight.getItem("BC192").setValue("");
            pricesSetGridFormRight.getItem("BC193").disable();
            pricesSetGridFormRight.getItem("BC193").setValue("");
            pricesSetGridFormRight.getItem("BC194").disable();
            pricesSetGridFormRight.getItem("BC194").setValue("");
            pricesSetGridFormRight.getItem("BC195").disable();
            pricesSetGridFormRight.getItem("BC195").setValue("");
            pricesSetGridFormRight.getItem("BC196").disable();
            pricesSetGridFormRight.getItem("BC196").setValue("");
            pricesSetGridFormRight.getItem("BC197").disable();
            pricesSetGridFormRight.getItem("BC197").setValue("");
            pricesSetGridFormRight.getItem("BC198").disable();
            pricesSetGridFormRight.getItem("BC198").setValue("");
            pricesSetGridFormRight.getItem("BC199").disable();
            pricesSetGridFormRight.getItem("BC199").setValue("");
            pricesSetGridFormRight.getItem("BC200").disable();
            pricesSetGridFormRight.getItem("BC200").setValue("");
            pricesSetGridFormRight.getItem("BC201").disable();
            pricesSetGridFormRight.getItem("BC201").setValue("");
            pricesSetGridFormRight.getItem("BC202").disable();
            pricesSetGridFormRight.getItem("BC202").setValue("");
            pricesSetGridFormRight.getItem("BC203").disable();
            pricesSetGridFormRight.getItem("BC203").setValue("");
            pricesSetGridFormRight.getItem("BC204").disable();
            pricesSetGridFormRight.getItem("BC204").setValue("");
            pricesSetGridFormRight.getItem("BC205").disable();
            pricesSetGridFormRight.getItem("BC205").setValue("");
            pricesSetGridFormRight.getItem("BC206").disable();
            pricesSetGridFormRight.getItem("BC206").setValue("");
            pricesSetGridFormRight.getItem("BC207").disable();
            pricesSetGridFormRight.getItem("BC207").setValue("");
            pricesSetGridFormRight.getItem("BC208").disable();
            pricesSetGridFormRight.getItem("BC208").setValue("");
            pricesSetGridFormRight.getItem("BC209").disable();
            pricesSetGridFormRight.getItem("BC209").setValue("");
            pricesSetGridFormRight.getItem("BC210").disable();
            pricesSetGridFormRight.getItem("BC210").setValue("");
        };

        //清除日期範圍更改價格Form
        function clearPricesSetGridForm2(){
            pricesSetGridForm2.clear();
            pricesSetGridForm2.getItem("BC106").setValue(true);
            pricesSetGridForm2.getItem("BC107").enable();
            pricesSetGridForm2.getItem("BC107").setValue("");
            pricesSetGridForm2.getItem("range_ntd").enable();

            pricesSetGridForm2.getItem("BC108").disable();
            pricesSetGridForm2.getItem("BC108").setValue("");
            pricesSetGridForm2.getItem("BC109").disable();
            pricesSetGridForm2.getItem("BC109").setValue("");
            pricesSetGridForm2.getItem("BC110").disable();
            pricesSetGridForm2.getItem("BC110").setValue("");
            pricesSetGridForm2.getItem("BC111").disable();
            pricesSetGridForm2.getItem("BC111").setValue("");
            pricesSetGridForm2.getItem("BC112").disable();
            pricesSetGridForm2.getItem("BC112").setValue("");
            pricesSetGridForm2.getItem("BC113").disable();
            pricesSetGridForm2.getItem("BC113").setValue("");
            pricesSetGridForm2.getItem("BC114").disable();
            pricesSetGridForm2.getItem("BC114").setValue("");
            pricesSetGridForm2.getItem("BC115").disable();
            pricesSetGridForm2.getItem("BC115").setValue("");
            pricesSetGridForm2.getItem("BC116").disable();
            pricesSetGridForm2.getItem("BC116").setValue("");
            pricesSetGridForm2.getItem("BC117").disable();
            pricesSetGridForm2.getItem("BC117").setValue("");
            pricesSetGridForm2.getItem("BC118").disable();
            pricesSetGridForm2.getItem("BC118").setValue("");
            pricesSetGridForm2.getItem("BC119").disable();
            pricesSetGridForm2.getItem("BC119").setValue("");
            pricesSetGridForm2.getItem("BC120").disable();
            pricesSetGridForm2.getItem("BC120").setValue("");
            pricesSetGridForm2.getItem("BC121").disable();
            pricesSetGridForm2.getItem("BC121").setValue("");
            pricesSetGridForm2.getItem("BC122").disable();
            pricesSetGridForm2.getItem("BC122").setValue("");
            pricesSetGridForm2.getItem("BC123").disable();
            pricesSetGridForm2.getItem("BC123").setValue("");
            pricesSetGridForm2.getItem("BC124").disable();
            pricesSetGridForm2.getItem("BC124").setValue("");
            pricesSetGridForm2.getItem("BC125").disable();
            pricesSetGridForm2.getItem("BC125").setValue("");
            pricesSetGridForm2.getItem("BC126").disable();
            pricesSetGridForm2.getItem("BC126").setValue("");
            pricesSetGridForm2.getItem("BC127").disable();
            pricesSetGridForm2.getItem("BC127").setValue("");
            pricesSetGridForm2.getItem("BC128").disable();
            pricesSetGridForm2.getItem("BC128").setValue("");
            pricesSetGridForm2.getItem("BC129").disable();
            pricesSetGridForm2.getItem("BC129").setValue("");
            pricesSetGridForm2.getItem("BC130").disable();
            pricesSetGridForm2.getItem("BC130").setValue("");
            pricesSetGridForm2.getItem("BC131").disable();
            pricesSetGridForm2.getItem("BC131").setValue("");
            pricesSetGridForm2.getItem("BC132").disable();
            pricesSetGridForm2.getItem("BC132").setValue("");
            pricesSetGridForm2.getItem("BC133").disable();
            pricesSetGridForm2.getItem("BC133").setValue("");
            pricesSetGridForm2.getItem("BC134").disable();
            pricesSetGridForm2.getItem("BC134").setValue("");
            pricesSetGridForm2.getItem("BC135").disable();
            pricesSetGridForm2.getItem("BC135").setValue("");
            pricesSetGridForm2.getItem("BC136").disable();
            pricesSetGridForm2.getItem("BC136").setValue("");
            pricesSetGridForm2.getItem("BC137").disable();
            pricesSetGridForm2.getItem("BC137").setValue("");
            pricesSetGridForm2.getItem("BC138").disable();
            pricesSetGridForm2.getItem("BC138").setValue("");
            pricesSetGridForm2.getItem("BC139").disable();
            pricesSetGridForm2.getItem("BC139").setValue("");
            pricesSetGridForm2.getItem("BC140").disable();
            pricesSetGridForm2.getItem("BC140").setValue("");
            pricesSetGridForm2.getItem("BC141").disable();
            pricesSetGridForm2.getItem("BC141").setValue("");
            pricesSetGridForm2.getItem("BC142").disable();
            pricesSetGridForm2.getItem("BC142").setValue("");
            pricesSetGridForm2.getItem("BC143").disable();
            pricesSetGridForm2.getItem("BC143").setValue("");
            pricesSetGridForm2.getItem("BC144").disable();
            pricesSetGridForm2.getItem("BC144").setValue("");
            pricesSetGridForm2.getItem("BC145").disable();
            pricesSetGridForm2.getItem("BC145").setValue("");
            pricesSetGridForm2.getItem("BC146").disable();
            pricesSetGridForm2.getItem("BC146").setValue("");
            pricesSetGridForm2.getItem("BC147").disable();
            pricesSetGridForm2.getItem("BC147").setValue("");
            pricesSetGridForm2.getItem("BC148").disable();
            pricesSetGridForm2.getItem("BC148").setValue("");
            pricesSetGridForm2.getItem("BC149").disable();
            pricesSetGridForm2.getItem("BC149").setValue("");
            pricesSetGridForm2.getItem("BC150").disable();
            pricesSetGridForm2.getItem("BC150").setValue("");
            pricesSetGridForm2.getItem("BC151").disable();
            pricesSetGridForm2.getItem("BC151").setValue("");
            pricesSetGridForm2.getItem("BC152").disable();
            pricesSetGridForm2.getItem("BC152").setValue("");
            pricesSetGridForm2.getItem("BC153").disable();
            pricesSetGridForm2.getItem("BC153").setValue("");
            pricesSetGridForm2.getItem("BC154").disable();
            pricesSetGridForm2.getItem("BC154").setValue("");
            pricesSetGridForm2.getItem("BC155").disable();
            pricesSetGridForm2.getItem("BC155").setValue("");
        };

        return () =>{
            dhxPricesLayout && dhxPricesLayout.destructor();
        }

    },[canAccess,canEdit,canDelete,canPrint,hotel]);


    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
                <MDBox py={2}>
                    <MDBox mb={2}>
                        <Grid container>
                            <Grid item xs={12} lg={12}>
                                <Card>
                                    <CardHeader style={{"paddingBottom":"0px"}} title={"價格設定"}/>
                                    <MDBox py={1} px={1}>
                                        <div ref={DHXPricesLayout}></div>
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            }

            {!canAccess && !isLoading &&
            <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }

        </DashboardLayout>
    );
};

export default PricesBasicSetting;

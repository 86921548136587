export const hotelVerbFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {
        cols:[
            {type: "input", label: "*飯店名稱：", placeholder: "", name: "AA101",
                width: "49.7%",
                css:"rightMargin",},
            {type: "input", label: "飯店編號：", placeholder: "未填寫由系統自動產生", name: "AA100",
                width: "49.7%",
                css:"rightMargin",},
        ],
    },
    {
        cols:[
            {type: "input", label: "*HotelNabe飯店編號：", placeholder: "", name: "AA116",
                width: "49.7%",
                css:"rightMargin",},
            {type: "select", name: "AA104", label: "*飯店類型：",
                options: [
                    {value: "0", content: "--請選擇--",select: true},
                    {value: "1", content: "一般飯店",},
                    {value: "2", content: "連鎖飯店"},
                    {value: "3", content: "觀光飯店"},
                    {value: "4", content: "汽車旅館"},
                    {value: "5", content: "民宿"},
                    {value: "6", content: "青年旅館"},
                    {value: "7", content: "套房"},
                    {value: "8", content: "防疫旅館"},
                ],
                width: "49.7%",
                css:"rightMargin",
            },
        ],
    },
    {
        cols:[
            {
                cols:[
                    {type: "select", name: "AA112", label: "*國家：",
                        options: [
                            {value: "0", content: "--請選擇--",select: true},
                            {value: "86", content: "台灣"},
                        ],
                        width: "49.7%",
                        css:"rightMargin",
                    },
                    {type: "select", name: "AA113", label: "*縣市：",
                        options: [
                            {value: "0", content: "--請選擇--",select: true},
                            {value: "100", content: "臺北",},
                            {value: "200", content: "基隆",},
                            {value: "260", content: "宜蘭",},
                            {value: "300", content: "新竹",},
                            {value: "330", content: "桃園",},
                            {value: "360", content: "苗栗",},
                            {value: "400", content: "臺中",},
                            {value: "500", content: "彰化",},
                            {value: "540", content: "南投",},
                            {value: "600", content: "嘉義",},
                            {value: "630", content: "雲林",},
                            {value: "700", content: "台南",},
                            {value: "800", content: "高雄",},
                            {value: "880", content: "澎湖",},
                            {value: "900", content: "屏東",},
                            {value: "950", content: "臺東",},
                            {value: "970", content: "花蓮",},
                            {value: "890", content: "金門",},
                        ],
                        width: "49.7%",
                        css:"rightMargin",
                    },
                ],
                width: "49.7%",
                css:"rightMargin",
            },
            {type: "input", label: "*飯店地址：", placeholder: "", name: "AA102",
                width: "49.7%",
                css:"rightMargin",},
        ],
    },
    {
        cols:[
            {type: "input", label: "客服Line ID：", placeholder: "", name: "AA121",
                width: "32.9%",
                css:"rightMargin"},
            {type: "input", label: "*飯店電話：", placeholder: "", name: "AA103",
                width: "32.9%",
                css:"rightMargin"},
            {type: "input", label: "*營業時間：", placeholder: "", name: "AA105",
                width: "32.9%",
                css:"rightMargin"},
        ],
    },
    // {
    //     type: "text",
    //     value: "(若是官方Line請不要輸入開頭的)",
    //     css: "hotelVerbFormNote"
    // },
    // {
    //     type: "text",
    //     label: "(若是官方Line請不要輸入開頭的@)",
    //     css: "hotelVerbFormNote",
    //     value: "123",
    //     name:"hotelVerbFormNote"
    // },
    {
        type: "text",
        value: "(若是官方Line請不要輸入開頭的@)",
        // width:"280px",
        css: "hotelVerbForm_tip",
        name: "tip",
    },
    {
        type: "textarea",
        name:"AA117",
        label: "飯店介紹：",
        value: "",
        placeholder: "",
        height: "120px"
    },
    {
        type: "textarea",
        name:"AA118",
        label: "交通位置：",
        value: "",
        placeholder: "",
        height: "120px"
    },
    {
        type: "textarea",
        name:"AA119",
        label: "旅遊資訊：",
        value: "",
        placeholder: "",
        height: "120px"
    },
    {
        type: "textarea",
        name:"AA106",
        label: "退款規則：",
        value: "",
        placeholder: "",
        height: "120px"
    },
    {
        type: "textarea",
        name:"AA107",
        label: "禁止事項：",
        value: "",
        placeholder: "",
        height: "120px"
    },
    {
        type: "textarea",
        name:"AA108",
        label: "其他：",
        value: "",
        placeholder: "",
        height: "120px"
    },
    {
        type: "textarea",
        name:"AA120",
        label: "消費者條約：",
        value: "",
        placeholder: "",
        height: "150px"
    },
    {
        type: "radioGroup",
        label: "*飯店停啟用狀態：",
        value: 1,
        options: {
            cols: [
                {
                    type: "radioButton",
                    text: "啟用",
                    value: 1,
                    checked: true,
                },
                {
                    type: "radioButton",
                    text: "停用",
                    value: 3
                },
                // {
                //     type: "radioButton",
                //     text: "日期範圍停用",
                //     value: 5
                // },
            ]
        },
        name: "AA041",
    },
    // {
    //     cols:[
    //
    //         {
    //             type: "datepicker",
    //             label: "Lock Start Date",
    //             dateFormat: "%Y-%m-%d",
    //             css:"rightMargin",
    //             width: "49.7%",
    //             disabled: true,
    //             name: "AA110",
    //         },
    //         {
    //             type: "datepicker",
    //             label: "Lock End Date",
    //             dateFormat: "%Y-%m-%d",
    //             width: "49.7%",
    //             disabled: true,
    //             name: "AA111",
    //         },
    //     ],
    // },
    {
        type: "textarea",
        value: "",
        label: "*停用原因",
        placeholder: "請填入停用原因",
        height: 150,
        name: "AA114",
    },
]

export const hotelMaintainTeamFormConfig = [
    {type: "input", name: "AC001", hidden: true, value: "0"},
    {type: "select", name: "AC100", label: "*維修片語類型：",
        options: [
            {value: "0", content: "--請選擇--",select: true},
            {value: "1", content: "內部",},
            {value: "2", content: "外部",},
        ]
    },
    {type: "input", label: "*維修片語名稱：", placeholder: "", name: "AC101"},
]

export const hotelFaultSetFormConfig = [
    {type: "input", name: "AB001", hidden: true, value: "0"},
    {type: "input", label: "*故障片語名稱：", placeholder: "", name: "AB100"},

]

export const hotelBookingCancelFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {type: "input", label: "*取消訂單片語名稱：", placeholder: "", name: "AA100"},

]

export const mainProductsSetFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {type: "input", label: "*主要產品代號：", placeholder: "", name: "AA100"},
    {type: "input", label: "*主要產品名稱：", placeholder: "", name: "AA101"},
]

export const unitsSetFormConfig = [
    {type: "input", name: "AD001", hidden: true, value: "0"},
    {type: "input", label: "*單位名稱", placeholder: "", name: "AD100"},
    {
        type: "radioGroup",
        // required: true,
        label: "*停啟用狀態：",
        value: "1",
        options: {
            cols: [
                {
                    type: "radioButton",
                    text: "啟用",
                    value: "1",
                    checked: true,
                },
                {
                    type: "radioButton",
                    text: "停用",
                    value: "0"
                },
            ]
        },
        name: "AD022",
    },
]

export const orderSourceSetFormConfig = [
    {type: "input", name: "AF001", hidden: true, value: "0"},
    {type: "input", label: "*手敲訂單來源名稱", placeholder: "", name: "AF100"},
    {
        type: "radioGroup",
        // required: true,
        label: "*停啟用狀態：",
        value: "1",
        options: {
            cols: [
                {
                    type: "radioButton",
                    text: "啟用",
                    value: "1",
                    checked: true,
                },
                {
                    type: "radioButton",
                    text: "停用",
                    value: "0"
                },
            ]
        },
        name: "AF022",
    },
]
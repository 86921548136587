import React, {useEffect, useRef, useState} from 'react';
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
    Grid as DHXGrid,
    Window as DHXWindow,
    Layout as DHXLayout,
    Toolbar as DHXToolbar,
    Form as DHXForm,
    message as DHXMessage,
} from "dhx-suite";
import "dhx-suite/codebase/suite.min.css";
import {CardHeader} from "@mui/material";
import {toolbar1Config} from "./componentsConfig/toolbar1Config";
import {otherPrdBasSetGridColumn} from "./componentsConfig/otherPrdBasSetGridColumn";
import {otherPrdBasSetGridFormConfig} from "./componentsConfig/otherPrdBasSetGridFormConfig";
import {WindowFootAccept, WindowFootClear, WindowFootClose} from "./componentsConfig/windowConfig";
import {stopFormConfig} from "./componentsConfig/stopFormConfig";
import {deleteFormConfig} from "./componentsConfig/deleteFormConfig";
import './style.css';
import Image_error from '../../../assets/images/error.png'
import Image_warning from '../../../assets/images/warning.png'
import axios from "axios";
import api_url from "../../../context/UrlContext.js"
import {useNavigate} from "react-router-dom";

const OtherProductsBasicSetting = props => {

    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState('');
    const [canEdit ,setCanEdit] = useState('');
    const [canDelete ,setCanDelete] = useState('');
    const [canPrint ,setCanPrint] = useState('');

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
    };

    const [isLoading,setIsloading] = useState(true);

    const DHXOtherPrdBasSetGridLayout = useRef(null);

    useEffect(()=>{

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))},headers)
            .then((response)=>{
                if(response.data == 0){
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:6},headers)
                        .then((response2)=>{
                            if(response2.status == 200){
                                let p = response2.data.split(',');

                                (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                setIsloading(false);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });

        const dhxOtherPrdBasSetGridLayout = new DHXLayout(DHXOtherPrdBasSetGridLayout.current,{
            rows:[
                {id:"otherPrdBasSetGridToolbar", height: "content"},
                {id:"otherPrdBasSetGridLayout", height: "500px"},
            ]
        });

        const toolbar1 = new DHXToolbar(null, {
        });
        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        otherPrdBasSetGridForm.clear();
                        dhxOtherPrdBasSetGridWindow1.header.data.update("title", {value: "新增加價產品視窗"});
                        otherPrdBasSetGridForm.setValue({"CA001": "0","CA002":hotel,"CA003":"0"});
                        otherPrdBasSetGridForm.getItem("CA022").setValue("1");

                        axios.get(apiServer+"/otherProducts/loadUnitsList",headers).then((response) =>{
                            otherPrdBasSetGridForm.getItem("CA003").setOptions(response.data);
                            otherPrdBasSetGridForm.clear("validation");
                        })
                            .catch((error) => {
                                console.log(error);
                            });

                        axios.post(apiServer+"/prices/loadHotelName", {
                            itemId1: hotel,
                        },headers).then((response) =>{
                            if(response.status == 200){
                                otherPrdBasSetGridForm.getItem("hotelName").setValue(response.data[0].hotelName);
                            }
                        })
                            .catch((error) => {
                                console.log(error);
                            });

                        otherPrdBasSetGridForm.clear("validation");
                        dhxOtherPrdBasSetGridWindow1.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: false });
                        dhxOtherPrdBasSetGridWindow1.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                case "reload":
                    axios.post(apiServer+"/otherProducts/loadOtherProductsGrid",{itemId1:hotel},headers)
                        .then((data) =>{
                            dhxOtherPrdBasSetGrid.data.removeAll();
                            dhxOtherPrdBasSetGrid.data.parse(data.data);
                        });
                    break;

                default:
                    break;
            }
        });
        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbar1Config)));

        const dhxOtherPrdBasSetGrid = new DHXGrid(null,{
            columns: otherPrdBasSetGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "otherPrdBasSetGrid_edit": function (e, data) {
                        if(canEdit){
                            otherPrdBasSetGridForm.clear();
                            dhxOtherPrdBasSetGridWindow1.header.data.update("title", {value: "編輯加價產品視窗"});
                            dhxOtherPrdBasSetGridWindow1.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });

                            axios.get(apiServer+"/otherProducts/loadUnitsList",headers)
                                .then((response) =>{
                                    otherPrdBasSetGridForm.getItem("CA003").setOptions(response.data);
                                    otherPrdBasSetGridForm.clear("validation");
                                })
                                .catch((error) => {
                                    console.log(error);
                                });

                            axios.post(apiServer+"/prices/loadHotelName", {
                                itemId1: hotel,
                            },headers).then((response) =>{
                                if(response.status == 200){
                                    otherPrdBasSetGridForm.getItem("hotelName").setValue(response.data[0].hotelName);
                                }
                            })
                                .catch((error) => {
                                    console.log(error);
                                });

                            axios.post(apiServer+"/otherProducts/loadOtherPrdBasSetGridFormData", {
                                itemId1: data.row.CA001
                            },headers).then((response) =>{
                                otherPrdBasSetGridForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            dhxOtherPrdBasSetGridWindow1.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "otherPrdBasSetGrid_stop": function (e, data) {
                        if(canEdit){
                            otherPrdBasSetGridStopForm.clear();
                            otherPrdBasSetGridStopForm.getItem("stopFormTitle").setValue("確定要停用嗎?");

                            axios.post(apiServer+"/otherProducts/loadOtherPrdBasSetGridFormData", {
                                itemId1: data.row.CA001
                            },headers).then((response) =>{
                                otherPrdBasSetGridStopForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            dhxOtherPrdBasSetGridWindow2.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "otherPrdBasSetGrid_delete": function (e, data) {
                        if(canDelete){
                            otherPrdBasSetGridDeleteForm.clear();
                            otherPrdBasSetGridDeleteForm.getItem("deleteFormTitle").setValue("確定刪除用嗎?");

                            axios.post(apiServer+"/otherProducts/loadOtherPrdBasSetGridFormData", {
                                itemId1: data.row.CA001
                            },headers).then((response) =>{
                                otherPrdBasSetGridDeleteForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            dhxOtherPrdBasSetGridWindow3.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });
        axios.post(apiServer+"/otherProducts/loadOtherProductsGrid",{itemId1:hotel},headers)
            .then((data) =>{
                dhxOtherPrdBasSetGrid.data.removeAll();
                dhxOtherPrdBasSetGrid.data.parse(data.data);
            });

        dhxOtherPrdBasSetGridLayout.getCell("otherPrdBasSetGridToolbar").attach(toolbar1);
        dhxOtherPrdBasSetGridLayout.getCell("otherPrdBasSetGridLayout").attach(dhxOtherPrdBasSetGrid);

        const dhxOtherPrdBasSetGridWindow1 = new DHXWindow({

            title: "Other Product Info Window",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 450,
            width: 570,
            footer: true,
            css: "dhxOtherPrdBasSetGridWindow1",
        });

        dhxOtherPrdBasSetGridWindow1.footer.data.add({type:"spacer"});
        dhxOtherPrdBasSetGridWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxOtherPrdBasSetGridWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClear)));
        dhxOtherPrdBasSetGridWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxOtherPrdBasSetGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    otherPrdBasSetGridForm.clear();
                    otherPrdBasSetGridForm.setValue({"CA001": "0","CA002":hotel,"CA003":"0"});
                    otherPrdBasSetGridForm.getItem("CA022").setValue("1");
                    otherPrdBasSetGridForm.clear("validation");
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    otherPrdBasSetGridForm.validate(false);

                    if(otherPrdBasSetGridForm.getItem("CA101").getValue() === ""){
                        DHXMessage({
                            text: "請填寫加購產品名稱",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(otherPrdBasSetGridForm.getItem("CA102").getValue() === ""){
                        DHXMessage({
                            text: "請填寫價格",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(otherPrdBasSetGridForm.getItem("CA003").getValue() === "0"){
                        DHXMessage({
                            text: "請選擇單位",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(otherPrdBasSetGridForm.getItem("CA103").getValue() === "" || otherPrdBasSetGridForm.getItem("CA103").getValue() === 0){
                        DHXMessage({
                            text: "請填寫庫存",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(otherPrdBasSetGridForm.getItem("CA022").getValue() === "0" && otherPrdBasSetGridForm.getItem("CA108").getValue() === ""){
                        DHXMessage({
                            text: "請填寫停用原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/otherProducts/checkOtherProductsName",
                        otherPrdBasSetGridForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "加價產品名稱重複",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }else{
                            axios.post(apiServer+"/otherProducts/updateOtherProductsFormData",
                                otherPrdBasSetGridForm.getValue(),headers
                            ).then((response) =>{
                                if(response.data[0].result){
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });

                                    otherPrdBasSetGridForm.clear();
                                    otherPrdBasSetGridForm.setValue({"CA001": "0","CA002":hotel,"CA003":"0"});
                                    otherPrdBasSetGridForm.getItem("CA022").setValue("1");
                                    otherPrdBasSetGridForm.clear("validation");

                                    axios.post(apiServer+"/otherProducts/loadOtherProductsGrid",{itemId1:hotel},headers)
                                        .then((data) =>{
                                            dhxOtherPrdBasSetGridWindow1.hide();
                                            dhxOtherPrdBasSetGrid.data.removeAll();
                                            dhxOtherPrdBasSetGrid.data.parse(data.data);
                                        });
                                }else{

                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    otherPrdBasSetGridForm.clear();
                    otherPrdBasSetGridForm.setValue({"CA001": "0","CA002":hotel,"CA003":"0"});
                    otherPrdBasSetGridForm.getItem("CA022").setValue("1");
                    otherPrdBasSetGridForm.clear("validation");
                    dhxOtherPrdBasSetGridWindow1.hide();
                    break;
                default:
                    break;
            }
        })

        const otherPrdBasSetGridForm = new DHXForm(null,{
            rows: otherPrdBasSetGridFormConfig
        });

        // let therPrdBasSetGridFormLockStartDate = otherPrdBasSetGridForm.getItem("CA106").getWidget();
        // let therPrdBasSetGridFormLockEndDate = otherPrdBasSetGridForm.getItem("CA107").getWidget();
        // therPrdBasSetGridFormLockStartDate.link(therPrdBasSetGridFormLockEndDate)

        otherPrdBasSetGridForm.getItem("CA022").events.on("change", function(value) {
            if(value == "0"){
                otherPrdBasSetGridForm.getItem("CA108").enable();
                // otherPrdBasSetGridForm.getItem("CA105").enable();
            }else{
                otherPrdBasSetGridForm.getItem("CA108").setValue("");
                // otherPrdBasSetGridForm.getItem("CA106").clear();
                // otherPrdBasSetGridForm.getItem("CA107").clear();
                otherPrdBasSetGridForm.getItem("CA108").disable();
                // otherPrdBasSetGridForm.getItem("CA105").disable();
                // otherPrdBasSetGridForm.getItem("CA106").disable();
                // otherPrdBasSetGridForm.getItem("CA107").disable();
                // otherPrdBasSetGridForm.getItem("CA105").clear();
            }
        });
        //
        // otherPrdBasSetGridForm.getItem("CA105").events.on("change", function(value) {
        //     if(value == "2"){
        //         otherPrdBasSetGridForm.getItem("CA106").enable();
        //         otherPrdBasSetGridForm.getItem("CA107").enable();
        //     }else{
        //         otherPrdBasSetGridForm.getItem("CA106").clear();
        //         otherPrdBasSetGridForm.getItem("CA107").clear();
        //         otherPrdBasSetGridForm.getItem("CA106").disable();
        //         otherPrdBasSetGridForm.getItem("CA107").disable();
        //     }
        // });

        dhxOtherPrdBasSetGridWindow1.attach(otherPrdBasSetGridForm);

        const dhxOtherPrdBasSetGridWindow2 = new DHXWindow({
            title: "停用加價產品視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 540,
            footer: true,
            css: "dhxOtherPrdBasSetGridWindow2",
        });

        dhxOtherPrdBasSetGridWindow2.footer.data.add({type:"spacer"});
        dhxOtherPrdBasSetGridWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxOtherPrdBasSetGridWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxOtherPrdBasSetGridWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    otherPrdBasSetGridStopForm.validate(false);

                    // if(otherPrdBasSetGridStopForm.getItem("CA105").getValue() === ""){
                    //     DHXMessage({
                    //         text: "請選擇停用類型",
                    //         icon:"dxi dxi-close",
                    //         css: "dhx_message--error",
                    //         expire:5000
                    //     });
                    //     break;
                    // }else if(otherPrdBasSetGridStopForm.getItem("CA105").getValue() === "2"){
                    //     if(otherPrdBasSetGridStopForm.getItem("CA106").getValue() === ""){
                    //         DHXMessage({
                    //             text: "請填寫鎖定開始日期",
                    //             icon:"dxi dxi-close",
                    //             css: "dhx_message--error",
                    //             expire:5000
                    //         });
                    //         break;
                    //     }else if(otherPrdBasSetGridStopForm.getItem("CA107").getValue() === ""){
                    //         DHXMessage({
                    //             text: "請填寫鎖定結束日期",
                    //             icon:"dxi dxi-close",
                    //             css: "dhx_message--error",
                    //             expire:5000
                    //         });
                    //         break;
                    //     }
                    // };

                    if(otherPrdBasSetGridStopForm.getItem("CA108").getValue() === ""){
                        DHXMessage({
                            text: "請填寫停用原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/otherProducts/stopOtherProducts",
                        otherPrdBasSetGridStopForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });
                            axios.post(apiServer+"/otherProducts/loadOtherProductsGrid",{itemId1:hotel},headers)
                                .then((data) =>{
                                    dhxOtherPrdBasSetGrid.data.removeAll();
                                    dhxOtherPrdBasSetGrid.data.parse(data.data);
                                    dhxOtherPrdBasSetGridWindow2.hide();
                                    otherPrdBasSetGridStopForm.clear();
                                    otherPrdBasSetGridStopForm.setValue({"CA001": "0"});
                                    otherPrdBasSetGridStopForm.clear("validation");
                                });
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxOtherPrdBasSetGridWindow2.hide();
                    otherPrdBasSetGridStopForm.clear();
                    otherPrdBasSetGridStopForm.setValue({"CA001": "0"});
                    otherPrdBasSetGridStopForm.clear("validation");
                    break;
                default:
                    break;
            }
        });

        let otherPrdBasSetGridStopFormLayout = new DHXLayout(null,{
            rows:[
                {id:"otherPrdBasSetGridStopFormImg",
                    height: 170,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_warning+" /></div>",
                    gravity: false
                },
                {id: "otherPrdBasSetGridStopForm"},
            ]
        });

        const otherPrdBasSetGridStopForm = new DHXForm(null,{
            rows: stopFormConfig
        });

        // let otherPrdBasSetGridStopFormLockStartDate = otherPrdBasSetGridStopForm.getItem("CA106").getWidget();
        // let otherPrdBasSetGridStopFormLockEndDate = otherPrdBasSetGridStopForm.getItem("CA107").getWidget();
        // otherPrdBasSetGridStopFormLockStartDate.link(otherPrdBasSetGridStopFormLockEndDate);

        // otherPrdBasSetGridStopForm.getItem("CA105").events.on("change", function(value) {
        //     if(value == "2"){
        //         otherPrdBasSetGridStopForm.getItem("CA106").enable();
        //         otherPrdBasSetGridStopForm.getItem("CA107").enable();
        //     }else{
        //         otherPrdBasSetGridStopForm.getItem("CA106").clear();
        //         otherPrdBasSetGridStopForm.getItem("CA107").clear();
        //         otherPrdBasSetGridStopForm.getItem("CA106").disable();
        //         otherPrdBasSetGridStopForm.getItem("CA107").disable();
        //     }
        // });

        dhxOtherPrdBasSetGridWindow2.attach(otherPrdBasSetGridStopFormLayout);
        otherPrdBasSetGridStopFormLayout.getCell("otherPrdBasSetGridStopForm").attach(otherPrdBasSetGridStopForm);

        const dhxOtherPrdBasSetGridWindow3 = new DHXWindow({
            title: "刪除加價產品視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 570,
            footer: true,
            css: "dhxOtherPrdBasSetGridWindow3",
        });

        dhxOtherPrdBasSetGridWindow3.footer.data.add({type:"spacer"});
        dhxOtherPrdBasSetGridWindow3.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxOtherPrdBasSetGridWindow3.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxOtherPrdBasSetGridWindow3.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    if(otherPrdBasSetGridDeleteForm.getItem("CA109").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/otherProducts/deleteOtherProducts",
                        otherPrdBasSetGridDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            axios.post(apiServer+"/otherProducts/loadOtherProductsGrid",{itemId1:hotel},headers)
                                .then((data) =>{
                                    dhxOtherPrdBasSetGrid.data.removeAll();
                                    dhxOtherPrdBasSetGrid.data.parse(data.data);
                                    dhxOtherPrdBasSetGridWindow3.hide();
                                    otherPrdBasSetGridDeleteForm.clear();
                                    otherPrdBasSetGridDeleteForm.setValue({"CA001": "0"});
                                    otherPrdBasSetGridDeleteForm.clear("validation");
                                });
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxOtherPrdBasSetGridWindow3.hide();
                    otherPrdBasSetGridDeleteForm.clear();
                    otherPrdBasSetGridDeleteForm.setValue({"CA001": "0"});
                    // otherPrdBasSetGridDeleteForm.getItem("CA022").setValue("1");
                    otherPrdBasSetGridDeleteForm.clear("validation");
                    break;
                default:
                    break;
            }
        });

        let otherPrdBasSetGridDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"otherPrdBasSetGridDeleteFormImg",
                    height: 170,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "otherPrdBasSetGridDeleteForm"},
            ]
        });

        const otherPrdBasSetGridDeleteForm = new DHXForm(null,{
            rows: deleteFormConfig
        });

        dhxOtherPrdBasSetGridWindow3.attach(otherPrdBasSetGridDeleteFormLayout);
        otherPrdBasSetGridDeleteFormLayout.getCell("otherPrdBasSetGridDeleteForm").attach(otherPrdBasSetGridDeleteForm);

        return () =>{
            dhxOtherPrdBasSetGridLayout && dhxOtherPrdBasSetGridLayout.destructor();
        }

    },[canAccess,canEdit,canDelete,canPrint,hotel]);


    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
                <MDBox py={2}>
                    <MDBox mb={2}>
                        <Grid container>
                            <Grid item xs={12} lg={12}>
                                <Card>
                                    <CardHeader title={"加價產品設定"}/>
                                    <MDBox py={1} px={1}>
                                        <div ref={DHXOtherPrdBasSetGridLayout}></div>
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            }

            {!canAccess && !isLoading &&
            <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }
        </DashboardLayout>
    );
};

export default OtherProductsBasicSetting;
export const alertSetGridColumn = [
    {
        id: "action", header: [{ text: "動作", align: "center" }],
        htmlEnable: true, align: "center", width:100,
        // template: function (e) {
        //     return "<a class='edit-button'><i title='edit' class='dxi dxi-pencil alertSetGrid_edit' style='color: #466fc4'>"+e+"</i></a>&nbsp;<i title='delete' class='dxi dxi-delete-forever alertSetGrid_delete' style='color: #c94747'>"+e+"</i>"
        // }
    },
    { minWidth: 80, id: "item_no", width:100, header: [{ text: "項次" }] },
    { minWidth: 80, id: "alert_time", width:150, header: [{ text: "提醒時間" },{content: "inputFilter"}] },
    { minWidth: 80, id: "alert_content", width:250, header: [{ text: "提醒內容" },{content: "selectFilter"}] },
    { width:200, id: "created_by", header: [{ text: "建立人員" },{content: "inputFilter"}] },
    { width:200, id: "created_at", header: [{ text: "建立時間" },{content: "inputFilter"}] },
    { width:200, id: "updated_by", header: [{ text: "更新人員" },{content: "inputFilter"}] },
    { width:200, id: "updated_at", header: [{ text: "更新時間" },{content: "inputFilter"}] },
];
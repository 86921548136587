export const orderFormConfig = [
    { type: "input", name: "AA001", hidden: true, value: "0" },
    { type: "input", name: "AA002", hidden: true, value: "0" },
    { type: "input", name: "AA137", hidden: true, value: "0" },
    { type: "input", name: "AA138", hidden: true, value: "0" },
    {
        css: "row1_title",
        rows: [
            {
                type: "text",
                value:"訂單資訊",
                width:80,
                name: "row1_title",
            },
        ]
    },
    {
        css: "row1",
        rows: [
            {
                cols: [
                    {
                        type: "text",
                        label: "訂單編號",
                        labelPosition: "top",
                        width: 173,
                        name: "AA128"
                    },
                    {
                        type: "text",
                        label: "訂單來源",
                        labelPosition: "top",
                        hidden:false,
                        width: 173,
                        name: "AA101_1"
                    },
                    {
                        type: "select",
                        label: "訂單來源：",
                        labelPosition: "top",
                        width: 173,
                        hidden:true,
                        options: [
                            {
                                value: "0",
                                content: "---請選擇---",
                            },
                        ],
                        name: "AA101_2"
                    },
                    {
                        type: "text",
                        label: "訂單來源編號",
                        labelPosition: "top",
                        width: 173,
                        name: "AA102"
                    },
                    {
                        type: "text",
                        label: "訂單來源備註",
                        labelPosition: "top",
                        width: 173,
                        name: "AA134"
                    },
                ],
            },
            {
                cols: [
                    {
                        type: "text",
                        label: "訂購日期",
                        labelPosition: "top",
                        width: 173,
                        name: "AA100"
                    },
                    {
                        type: "text",
                        label: "訂單總金額",
                        labelPosition: "top",
                        width: 173,
                        name: "AA136"
                    },
                    {
                        type: "text",
                        label: "發票號碼",
                        labelPosition: "top",
                        width: 173,
                        name: "AA121"
                    },
                ],
            },
        ],
    },
    {
        type: "textarea",
        label: "訂單備註",
        height: 150,
        name: "AA124",
        css: "orderFormNote",
    },
];
export const productInfo_payLogGridFormConfig = [
    { type: "input", name: "AH001", hidden: true, value: "0" },
    { type: "input", name: "AH002", hidden: true, value: "0" },
    { type: "input", name: "AH003", hidden: true, value: "0" },
    { type: "input", name: "TMPId", hidden: true, value: "0" },
    {
        cols: [
            {
                type: "select",
                label: "*付款方式：",
                labelPosition: "top",
                options: [
                    {
                        value: "0",
                        content: "---請選擇---",
                        disabled: true
                    },
                ],
                width: "32.8%",
                css:"rightMargin",
                name: "AH004"
            },
            {
                type: "input",
                label: "*付款金額(元)：",
                labelPosition: "top",
                value:0,
                inputType: "number",
                width: "32.8%",
                css:"rightMargin",
                name: "AH100"
            },
            {
                type: "input",
                label: "折價(元)：",
                labelPosition: "top",
                value:0,
                inputType: "number",
                width: "32.8%",
                css:"rightMargin",
                name: "AH101"
            },
        ],
    },
    {
        type: "textarea",
        label: "備註：",
        labelPosition: "top",
        height:150,
        name: "AH102"
    },
];
export const checkIn_roomGridColumn = [
    { minWidth: 80, id: "item_no", width:80, header: [{ text: "項次" }],footer: [{ text: "總金額" }] },
    { minWidth: 80, id: "check_in_out_date", width:230, header: [{ text: "入住/退房日期" }] },
    { minWidth: 80, id: "room_type", width:150, editable: true, header: [{ text: "房型" },{content: "inputFilter"}] },
    { minWidth: 80, id: "room_name", width:200, editable: true, header: [{ text: "房間名稱" },{content: "inputFilter"}] },
    { minWidth: 80, id: "quantity", width:80, header: [{ text: "數量" },{content: "inputFilter"}] },
    { minWidth: 80, id: "price", width:150, header: [{ text: "價格" },{content: "inputFilter"}] },
    { minWidth: 80, id: "additionalGuestPrice", width:150, header: [{ text: "加床費用" },{content: "inputFilter"}] },
    { minWidth: 80, id: "totalPrice", width:150, header: [{ text: "小計" },{content: "inputFilter"}], footer: [{ content: "sum" }], format: "#,#.##" },
    { width:200, id: "created_by", header: [{ text: "建立人員" },{content: "inputFilter"}] },
    { width:200, id: "created_at", header: [{ text: "建立時間" },{content: "inputFilter"}] },
    { width:200, id: "updated_by", header: [{ text: "更新人員" },{content: "inputFilter"}] },
    { width:200, id: "updated_at", header: [{ text: "更新時間" },{content: "inputFilter"}] },
];
export const toolbar1Config = [
    // {
    //     type: "input",
    //     label: "Room Number Keyword：",
    //     width: "350px",
    //     id: "keyword",
    // },
    {id: "reload", type: "button", circle: true, value: "重新整理", size: "small", icon: "fa-solid fa-rotate-right"},
    {id: "sync", type: "button", circle: true, value: "同步狀態", size: "small", icon: "fa-solid fa-rotate"},
    {id: "setHotelLock", type: "button", circle: true, value: "設定大樓門鎖密碼", size: "small", icon: "fa-solid fa-gear"},
];
export const productInfo_otherPrdGridColumn = [
    {
        id: "action", header: [{ text: "動作", align: "center" }],
        htmlEnable: true, align: "center", width:100,
        footer: [{ text: "總金額" }]
    },
    { minWidth: 80, id: "item_no", width:80, header: [{ text: "項次" }] },
    { minWidth: 80, id: "roomName", width:200, header: [{ text: "房間名稱" },{content: "selectFilter"}] },
    { minWidth: 80, id: "otherPrdName", width:200, header: [{ text: "加價產品名稱" },{content: "selectFilter"}] },
    { minWidth: 80, id: "quantity", width:100, header: [{ text: "數量" },{content: "inputFilter"}] },
    { minWidth: 80, id: "price", width:100, header: [{ text: "價格" },{content: "inputFilter"}] },
    { minWidth: 80, id: "note", width:250, header: [{ text: "備註" },{content: "inputFilter"}] },
    { minWidth: 80, id: "subtotal", width:100, header: [{ text: "小計" },{content: "inputFilter"}], footer: [{ content: "sum" }], format: "#,#" },
    { width:200, id: "created_by", header: [{ text: "建立人員" },{content: "inputFilter"}] },
    { width:200, id: "created_at", header: [{ text: "建立時間" },{content: "inputFilter"}] },
    { width:200, id: "updated_by", header: [{ text: "更新人員" },{content: "inputFilter"}] },
    { width:200, id: "updated_at", header: [{ text: "更新時間" },{content: "inputFilter"}] },
];
let getDate = (date)=> {
    let startDateValue = new Date(date);
    let year1 = startDateValue.getFullYear();
    let month1 = startDateValue.getMonth()+1;
    let day1 = startDateValue.getDate();

    return `${year1}-${month1.toString().padStart(2,'0')}-${day1.toString().padStart(2,'0')}`;
}

let dateTime = new Date();
dateTime = dateTime.setDate(dateTime.getDate()+1);
dateTime = new Date(dateTime);

export const newOrder_roomGridFormConfig = [
    { type: "input", name: "AG001", hidden: true, value: "0" },
    { type: "input", name: "AG002", hidden: true, value: "0" },
    { type: "input", name: "AG003", hidden: true, value: "0" },
    { type: "input", name: "AG006", hidden: true, value: "0" },
    { type: "input", name: "AG132", hidden: true, value: "0" },
    { type: "input", name: "AG133", hidden: true, value: "0" },
    { type: "input", name: "AG134", hidden: true, value: "0" },
    {
        cols: [
            {
                type: "datepicker",
                label: "*入住日期：",
                timePicker: true,
                timeFormat: 24,
                dateFormat: "%Y-%m-%d %H:%i",
                valueFormat:"string",
                value:`${getDate(new Date())} 15:00`,
                width: "49.7%",
                mark: function (date) {
                    if (getDate(date) === getDate(new Date)) {
                        return "highlight-date";
                    }
                },
                css:"rightMargin dateRange",
                name: "AG119",
            },
            {
                type: "datepicker",
                label: "*退房日期：",
                timePicker: true,
                timeFormat: 24,
                dateFormat: "%Y-%m-%d %H:%i",
                valueFormat:"string",
                value:`${getDate(dateTime)} 11:00`,
                width: "49.7%",
                mark: function (date) {
                    if (getDate(date) === getDate(new Date)) {
                        return "highlight-date";
                    }
                },
                css:"rightMargin dateRange",
                name: "AG120",
            }
        ],
    },
    {
        type: "combo",
        multiselection: false,
        label: "*房型：",
        labelPosition: "top",
        css:"rightMargin",
        data: [
            { value: "---請選擇---", id: "0" },
        ],
        readOnly:true,
        disabled:true,
        name: "AG004",
    },
    {
        type: "combo",
        multiselection: false,
        label: "*房間名稱：",
        labelPosition: "top",
        css:"rightMargin",
        data: [
            { value: "---請選擇---", id: "0" },
        ],
        readOnly:true,
        disabled:true,
        name: "AG005",
    },
    {
        cols: [
            { type: "input", label: "*價格：", value:"0", placeholder:"請填入金額", inputType:"number", width: "49.7%", disabled:true, css:"rightMargin",
                // name: "AB116"
                name: "AG127"
            },
            { type: "input", label: "加床費用：", value:"0", placeholder:"請填入金額", inputType:"number", width: "49.7%", disabled:true, css:"rightMargin",
                // name: "AB118"
                name: "AG116"
            },
        ],
    },
    { type: "text", label: "總金額：",labelPosition:"left", value:"", width: "100%", css:"total", name: "total" },
];
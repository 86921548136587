export const viewGridFormConfig = [
    {
        type: "button",
        value: "新增訂單",
        width: "300px",
        css: "changeBtn",
        name: "addBtn",
        id: "addBtn"
    },
    {
        type: "button",
        value: "修改訂單",
        width: "300px",
        css: "changeBtn",
        name: "modifyBtn",
        id: "modifyBtn"
    },
    {
        type: "button",
        value: "刪除訂單",
        width: "300px",
        css: "changeBtn",
        name: "deleteBtn",
        id: "deleteBtn"
    },
    {
        type: "button",
        value: "入　　住",
        width: "300px",
        css: "changeBtn",
        name: "checkInBtn",
        id: "checkInBtn"
    },
    {
        type: "button",
        value: "換　　房",
        width: "300px",
        css: "changeBtn",
        name: "exchangeBtn",
        hidden:true
    },
    {
        type: "button",
        value: "退　　房",
        width: "300px",
        css: "changeBtn",
        name: "checkOutBtn"
    },
    // {
    //     type: "button",
    //     value: "打　　掃",
    //     css: "changeBtn",
    //     name: "cleanBtn",
    //     width: "300px",
    // },
    {
        type: "button",
        value: "打掃完畢",
        css: "changeBtn",
        name: "finishBtn",
        width: "300px",
    },
    {
        type: "button",
        value: "故障通報",
        width: "300px",
        css: "changeBtn",
        name: "faultReportBtn",
    },
    {
        type: "button",
        value: "故障處理回報",
        width: "300px",
        css: "changeBtn",
        name: "reportDoneBtn",
    },
];

import React, {useEffect, useRef, useState} from 'react';
import DashboardLayout from "../../../pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../pages/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDAlert from "components/MDAlert";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
    Grid as DHXGrid,
    Window as DHXWindow,
    Layout as DHXLayout,
    Toolbar as DHXToolbar,
    Form as DHXForm,
    Tree as DHXTree,
    message as DHXMessage,
    i18n as i18n
} from "dhx-suite";
import "dhx-suite/codebase/suite.min.css";
import {CardHeader} from "@mui/material";
import {reserveGridColumn} from "./componentsConfig/reserveGridColumn";
import {toolbar1Config} from "./componentsConfig/toolbar1Config";
import {reserveFormConfig} from "./componentsConfig/reserveFormConfig";
import {WindowFootAccept, WindowFootClear, WindowFootClose} from "./componentsConfig/windowConfig";
import './style.css';
import {deleteFormConfig} from "./componentsConfig/deleteFormConfig";
import Image_error from '../../../assets/images/error.png'
import axios from "axios";
import api_url from "../../../context/UrlContext.js"
import {useNavigate} from "react-router-dom";

const ReserveBasicSetting = props => {

    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState('');
    const [canEdit ,setCanEdit] = useState('');
    const [canDelete ,setCanDelete] = useState('');
    const [canPrint ,setCanPrint] = useState('');

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
    };

    const [isLoading,setIsloading] = useState(true);

    //
    const DHXReserveGridLayout = useRef(null);

    let mainPrdSelectedId = "0";
    let reserveFormTree = '';

    const localeCal = {
        // English locale
        en: {
            monthsShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            cancel: "Cancel"
        },
        // German locale
        de: {
            monthsShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
            months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
            daysShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
            days: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
            cancel: "Zurück",
        }
    }
    const localeTimePicker = {
        en: {
            hours: "Hours",
            minutes: "Minutes",
            save: "Save"
        },
        de: {
            hours: "Uhr?",
            minutes: "Minute?",
            save: "Halten?"
        }
    }

    useEffect(()=>{

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))})
            .then((response)=>{
                if(response.data == 0){
                    //沒有登入就跳轉到登入頁面
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:10},headers)
                        .then((response2)=>{
                            if(response2.status == 200){
                                let p = response2.data.split(',');

                                (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                setIsloading(false);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });

        const dhxReserveGridLayout = new DHXLayout(DHXReserveGridLayout.current,{
            rows:[
                {id:"reserveGridToolbar", height: "content"},
                {id:"reserveGridLayout", height: "500px"},
            ]
        });

        const toolbar1 = new DHXToolbar(null, {
        });
        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    dhxReserveWindow1.header.data.update("title", {value: "新增保留視窗"});
                    reserveForm.clear();
                    mainPrdSelectedId = "0";
                    reserveForm.setValue({"AA001":"0","AA002":hotel,"AA004":"0","AA108":"0","AA103":Date.now()});
                    reserveForm.getItem("tip").setValue("(點擊輸入框進入選擇畫面)");
                    reserveForm.clear("validation");

                    axios.post(apiServer+"/reserve/loadOtherProductsList", {
                        itemId1: hotel,itemId2: reserveForm.getItem("AA001").getValue()
                    },headers).then((response) =>{
                        reserveForm.getItem("AA005").setOptions(response.data);
                    })
                        .catch((error) => {
                            console.log(error);
                        });

                    dhxReserveWindow1.show();
                    break;

                case "reload":
                    axios.post(apiServer+"/handOver/loadReservesGrid", {
                        itemId1: hotel
                    },headers).then((response) =>{
                        if(response.status === 200){
                            dhxReserveGrid.data.removeAll();
                            dhxReserveGrid.data.parse(response.data);
                        }else{
                            console.log("Server Error")
                        }
                    })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                default:
                    break;
            }
        });
        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbar1Config)));

        const dhxReserveGrid = new DHXGrid(null,{
            columns: reserveGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "reserveGrid_edit": function (e, data) {

                        reserveForm.clear();
                        dhxReserveWindow1.header.data.update("title", {value: "編輯保留視窗"});

                        axios.post(apiServer+"/reserve/loadReserveFormData", {
                            itemId1: data.row.AA001
                        },headers).then((response) =>{
                            if(response.status === 200){
                                reserveForm.setValue(response.data[0]);
                                mainPrdSelectedId = response.data[0].AA004;
                                reserveForm.getItem("AA004").setValue(response.data[0].AA004);

                                axios.post(apiServer+"/reserve/loadOtherProductsList", {
                                    itemId1: hotel,itemId2: reserveForm.getItem("AA001").getValue()
                                },headers).then((response2) =>{
                                    reserveForm.getItem("AA005").setOptions(response2.data);
                                    reserveForm.getItem("AA005").setValue(response.data[0].AA005)
                                })
                                    .catch((error) => {
                                        console.log(error);
                                    });

                            }else{
                                console.log("Server Error")
                            }
                        })
                            .catch((error) => {
                                console.log(error);
                            });
                        dhxReserveWindow1.show();
                    },
                    "reserveGrid_delete": function (e, data) {

                        reserveDeleteForm.getItem("deleteFormTitle").setValue("確定要刪除嗎?");
                        axios.post(apiServer+"/reserve/loadReserveFormData", {
                            itemId1: data.row.AA001
                        },headers).then((response) =>{
                            reserveDeleteForm.setValue(response.data[0]);
                        })
                            .catch((error) => {
                                console.log(error);
                            });
                        dhxReserveWindow2.show();
                    }
                }
            }
        });

        axios.post(apiServer+"/reserve/loadReservesGrid", {
            itemId1: hotel
        },headers).then((response) =>{
            if(response.status === 200){
                dhxReserveGrid.data.removeAll();
                dhxReserveGrid.data.parse(response.data);
            }else{
                console.log("Server Error")
            }
        })
            .catch((error) => {
                console.log(error);
            });

        dhxReserveGridLayout.getCell("reserveGridToolbar").attach(toolbar1);
        dhxReserveGridLayout.getCell("reserveGridLayout").attach(dhxReserveGrid);

        const dhxReserveWindow1 = new DHXWindow({

            title: "新增保留視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 520,
            width: 635,
            footer: true,
            css: "reserveWindow1",
        });

        dhxReserveWindow1.footer.data.add({type:"spacer"});
        dhxReserveWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxReserveWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClear)));
        dhxReserveWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxReserveWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    reserveForm.clear();
                    mainPrdSelectedId = "0";
                    reserveForm.setValue({"AA001":"0","AA004":"0"});
                    reserveForm.getItem("tip").setValue("(點擊輸入框進入選擇畫面)");
                    reserveForm.clear("validation");
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(reserveForm.getItem("AA100").getValue() == 0 || reserveForm.getItem("AA100").getValue() == ""){
                        DHXMessage({
                            text: "請選取保留對象",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }else if(reserveForm.getItem("AA100").getValue() == 1 && mainPrdSelectedId == "0"){
                        DHXMessage({
                            text: "請選取主要產品",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }else if(reserveForm.getItem("AA100").getValue() == 2 && (reserveForm.getItem("AA005").getValue() == 0 || reserveForm.getItem("AA005").getValue() == "")){
                        DHXMessage({
                            text: "請選取加價產品",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }else if(reserveForm.getItem("AA100").getValue() == 2 && reserveForm.getItem("AA005").getValue() !== 0 && reserveForm.getItem("AA005").getValue() !== "" && (reserveForm.getItem("AA108").getValue() == "" || reserveForm.getItem("AA108").getValue() == 0)){
                        DHXMessage({
                            text: "請填寫加價產品數量",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(reserveForm.getItem("AA102").getValue().replace(/\s*/g,"") == ""){
                        DHXMessage({
                            text: "請填寫保留原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(reserveForm.getItem("AA103").getValue() == ""){
                        DHXMessage({
                            text: "請填寫通報日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(reserveForm.getItem("AA104").getValue() == ""){
                        DHXMessage({
                            text: "請填寫保留開始日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(reserveForm.getItem("AA105").getValue() == ""){
                        DHXMessage({
                            text: "請填寫保留結束日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(reserveForm.getItem("AA100").getValue() == 1){
                        updateReserve();
                    }else if(reserveForm.getItem("AA100").getValue() == 2){
                        axios.post(apiServer+"/reserve/checkRemained", {
                            itemId1: reserveForm.getItem("AA005").getValue(),itemId2: reserveForm.getItem("AA108").getValue(),itemId3: reserveForm.getItem("AA001").getValue()
                        },headers).then((response1) =>{

                            if(response1.status === 200){
                                if(response1.data[0].result === 0){
                                    DHXMessage({
                                        text: response1.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }else{
                                    updateReserve();
                                }
                            }else{
                                console.log("Server Error")
                            }
                        });
                    }
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxReserveWindow1.hide();
                    break;
                default:
                    break;
            }
        })

        // i18n.setLocale("reserveForm", localeCal["de"]);

        const reserveForm = new DHXForm(null,{
            rows: reserveFormConfig
        });

        function updateReserve(){
            axios.post(apiServer+"/reserve/updateReserve",
                reserveForm.getValue(),headers
            ).then((response) =>{
                if(response.data[0].result){
                    DHXMessage({
                        text: response.data[0].message,
                        icon:"dxi dxi-close",
                        css: "dhx_message--success",
                        expire:5000
                    });

                    reserveForm.clear();
                    reserveForm.setValue({"AA001": "0", "AA004": "0"});

                    dhxReserveWindow1.hide();

                    axios.post(apiServer+"/reserve/loadReservesGrid", {
                        itemId1: hotel
                    },headers).then((response2) =>{
                        if(response2.status === 200){

                            console.log(response2.data[0].result)

                            dhxReserveGrid.data.removeAll();
                            dhxReserveGrid.data.parse(response2.data);
                        }else{
                            console.log("Server Error")
                        }
                    })
                        .catch((error) => {
                            console.log(error);
                        });
                }else{

                }
            })
                .catch((error) => {
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                });
        }

        reserveForm.getItem("AA005").events.on("change", function(value) {
            if(reserveForm.getItem("AA005").getValue() !== '0' && reserveForm.getItem("AA005").getValue() !== ''){
                axios.post(apiServer+"/reserve/loadOtherPrdRemained", {
                    itemId1: reserveForm.getItem("AA005").getValue()
                },headers).then((response) =>{
                    reserveForm.getItem("remained").setValue(response.data[0].remained);
                })
                    .catch((error) => {
                        console.log(error);
                    });
            }else{
                reserveForm.getItem("remained").setValue("");
            }
        });

        reserveForm.getItem("AA104").events.on("change", function(value) {
            if(reserveForm.getItem("AA104").getValue() != '' && reserveForm.getItem("AA105").getValue() != ''){
                mainPrdSelectedId = "0";
                reserveForm.getItem("mainPrd").setValue("");
                reserveForm.getItem("AA005").setValue("");
                reserveForm.getItem("AA100").enable();
            }else{
                reserveForm.getItem("AA100").disable();
                mainPrdSelectedId = "0";
                reserveForm.getItem("mainPrd").setValue("");
                reserveForm.getItem("AA005").setValue("");
            }
        });

        reserveForm.getItem("AA105").events.on("change", function(value) {
            if(reserveForm.getItem("AA104").getValue() != '' && reserveForm.getItem("AA105").getValue() != ''){
                mainPrdSelectedId = "0";
                reserveForm.getItem("mainPrd").setValue("");
                reserveForm.getItem("AA004").setValue("0");
                reserveForm.getItem("AA005").setValue("0");
                reserveForm.getItem("AA100").enable();
            }else{
                reserveForm.getItem("AA100").disable();
                mainPrdSelectedId = "0";
                reserveForm.getItem("mainPrd").setValue("");
                reserveForm.getItem("AA004").setValue("0");
                reserveForm.getItem("AA005").setValue("0");
            }
        });

        reserveForm.events.on("click", function (name, e){
            switch (name){
                case "send":
                    dhxReserveWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        reserveForm.getItem("mainPrd").events.on("focus", (value) => {
            const dhxReserveFormTreeWindow1 = new DHXWindow({

                title: "主要產品視窗",
                modal: true,
                resizable: true,
                movable: true,
                closable:true,
                viewportOverflow:true,
                height: 400,
                width: 400,
                footer: true,
                css: "reserveFormTreeWindow1",
            });

            dhxReserveFormTreeWindow1.footer.data.add({type:"spacer"});
            dhxReserveFormTreeWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
            dhxReserveFormTreeWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

            dhxReserveFormTreeWindow1.footer.events.on("click",(itemId1) =>{
                switch (itemId1){
                    case "WINDOW_FOOT_ACCEPT":
                        if(mainPrdSelectedId === "0"){
                            reserveForm.getItem("mainPrd").setValue("");
                            DHXMessage({
                                text: "請選取主要產品",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }
                        reserveForm.getItem("mainPrd").setValue(reserveFormTree.selection.getItem().value);
                        dhxReserveFormTreeWindow1.hide();
                        reserveFormTree.destructor();
                        dhxReserveFormTreeWindow1.destructor();
                        break;
                    case "WINDOW_FOOT_CLOSE":
                        dhxReserveFormTreeWindow1.hide();
                        reserveFormTree.destructor();
                        dhxReserveFormTreeWindow1.destructor();
                        break;
                    default:
                        break;
                }
            })

            reserveFormTree = new DHXTree(null, {});

            if(mainPrdSelectedId !== "0"){
                reserveFormTree.selection.add(mainPrdSelectedId);
            }

            reserveFormTree.selection.events.on("afterSelect", function(id){
                if(reserveFormTree.selection.getItem().items == undefined){
                    mainPrdSelectedId = id;
                    reserveForm.getItem("AA004").setValue(mainPrdSelectedId);
                }else{
                    mainPrdSelectedId = "0";
                    reserveForm.getItem("AA004").setValue("0");
                    reserveForm.getItem("mainPrd").setValue("");
                }
            });

            axios.post(apiServer+"/reserve/loadMainProductsTree", {
                itemId1: hotel,itemId2:reserveForm.getItem("AA104").getValue(),itemId3:reserveForm.getItem("AA105").getValue()
            },headers).then((data) =>{

                if(data.status === 200){
                    if(data.data == 0){
                        DHXMessage({
                            text: "目前沒有可保留的主要產品",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }else{

                        reserveFormTree.data.removeAll();
                        reserveFormTree.data.parse(data.data);

                        dhxReserveFormTreeWindow1.attach(reserveFormTree);

                        dhxReserveFormTreeWindow1.show();
                    }
                }else{
                    console.log("Server Error")
                }
            })
        });

        let reserveFormLockStartDate = reserveForm.getItem("AA104").getWidget();
        let reserveFormLockEndDate = reserveForm.getItem("AA105").getWidget();
        reserveFormLockStartDate.link(reserveFormLockEndDate)

        reserveForm.getItem("AA100").events.on("change", function(value) {
            reserveForm.getItem("mainPrd").setValue("");
            reserveForm.getItem("AA005").setValue("0");
            if(value == 2){
                mainPrdSelectedId = "0";
                reserveForm.getItem("AA004").setValue("0");
                reserveForm.getItem("mainPrd").disable();
                reserveForm.getItem("AA005").enable();
                reserveForm.getItem("AA108").enable();
                reserveForm.getItem("remained").enable();
            }else if(value == 1){
                reserveForm.getItem("mainPrd").enable();
                reserveForm.getItem("AA005").disable();
                reserveForm.getItem("AA108").disable();
                reserveForm.getItem("remained").disable();
                reserveForm.getItem("AA108").setValue("0");
                reserveForm.getItem("remained").setValue("");
            }else{
                reserveForm.getItem("mainPrd").disable();
                reserveForm.getItem("AA005").disable();
                reserveForm.getItem("AA108").disable();
                reserveForm.getItem("remained").disable();
                reserveForm.getItem("AA108").setValue("0");
                reserveForm.getItem("remained").setValue("");
            }
        });

        dhxReserveWindow1.attach(reserveForm);

        const dhxReserveWindow2 = new DHXWindow({
            title: "刪除保留視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 640,
            footer: true,
            css: "dhxReserveWindow2"
        });

        dhxReserveWindow2.footer.data.add({type:"spacer"});
        dhxReserveWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxReserveWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxReserveWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    reserveDeleteForm.clear();
                    reserveDeleteForm.clear("validation");
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(reserveDeleteForm.getItem("AA107").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/reserve/deleteReserve",
                        reserveDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            axios.post(apiServer+"/reserve/loadReservesGrid", {
                                itemId1: hotel
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    dhxReserveGrid.data.removeAll();
                                    dhxReserveGrid.data.parse(response.data);
                                }else{
                                    console.log("Server Error")
                                }
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            dhxReserveWindow2.hide();
                            reserveDeleteForm.clear();
                            reserveDeleteForm.setValue({"AA001": "0"});
                            reserveDeleteForm.clear("validation");
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxReserveWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let reserveDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"reserveDeleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "reserveDeleteForm"},
            ]
        });

        const reserveDeleteForm = new DHXForm(null,{
            rows: deleteFormConfig
        });

        dhxReserveWindow2.attach(reserveDeleteFormLayout);
        reserveDeleteFormLayout.getCell("reserveDeleteForm").attach(reserveDeleteForm);

        return () =>{
            dhxReserveGridLayout && dhxReserveGridLayout.destructor();
        }

    },[canAccess,canEdit,canDelete,canPrint,hotel]);

    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
                <MDBox py={2}>
                    <MDBox mb={2}>
                        <Grid container>
                            <Grid item xs={12} lg={12}>
                                <Card>
                                    <CardHeader title={"保留設定"}/>
                                    <MDBox py={1} px={1}>
                                        <div ref={DHXReserveGridLayout}></div>
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            }

            {!canAccess && !isLoading &&
            <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }
        </DashboardLayout>
    );
};

export default ReserveBasicSetting;

export const orderGridColumn = [
    {
        id: "action", header: [{ text: "動作", align: "center" }],
        htmlEnable: true, align: "center", width:70,
    },
    { id: "item_no", width:50, header: [{ text: "項次" }] },
    { width: 160, id: "order_status", header: [{ text: "訂單狀態" },{content: "selectFilter"}] },
    { width:140, id: "hotel_name", header: [{ text: "飯店名稱" },{content: "inputFilter"}] },
    {
        width: 250, id: "order_no", header: [{ text: "訂單編號" },{content: "inputFilter"}],
        htmlEnable: true,
        template: function (e) {
            return "<a href='#' class='viewOrderDetail viewOrderDetail-"+e+"' style='color: blue'><u>"+e+"</u></a>"
        }
    },
    { width: 150, id: "purchaser_full_name", header: [{ text: "訂購人姓名" },{content: "inputFilter"}] },
    { width: 260, id: "check_in_out_date", header: [{ text: "入住/退房日期" },{content: "inputFilter"}] },
    { width: 80, id: "total", header: [{ text: "總金額" },{content: "inputFilter"}] },
    { width: 80, id: "discount", header: [{ text: "折扣" },{content: "inputFilter"}] },
    { width: 80, id: "paid", header: [{ text: "已付" },{content: "inputFilter"}] },
    { width: 80, id: "unpaid", header: [{ text: "未付" },{content: "inputFilter"}] },
    { width: 150, id: "order_source", header: [{ text: "訂單來源" },{content: "selectFilter"}] },
    { width: 200, id: "source_order_no", header: [{ text: "訂單來源編號" },{content: "inputFilter"}] },
    { width: 150, id: "purchaser_tel", header: [{ text: "訂購人電話" },{content: "inputFilter"}] },
    { width: 150, id: "purchaser_country", header: [{ text: "訂購人國籍" },{content: "inputFilter"}] },
    { width: 150, id: "country_code", header: [{ text: "國籍代碼" },{content: "inputFilter"}] },
    { width: 160, id: "order_date", header: [{ text: "訂購日期" },{content: "inputFilter"}] },
    { width: 80, id: "note", header: [{ text: "訂單備註" },{content: "inputFilter"}] },
    { width: 200, id: "cancel_date", header: [{ text: "取消日期" },{content: "inputFilter"}] },
    { width: 80, id: "cancel_reason", header: [{ text: "取消原因" },{content: "inputFilter"}] },
    { width:200, id: "created_by", header: [{ text: "建立人員" },{content: "inputFilter"}] },
    { width:200, id: "created_at", header: [{ text: "建立時間" },{content: "inputFilter"}] },
    { width:200, id: "updated_by", header: [{ text: "更新人員" },{content: "inputFilter"}] },
    { width:200, id: "updated_at", header: [{ text: "更新時間" },{content: "inputFilter"}] },
];
export const productInfo_payLogGridColumn = [
    {
        id: "action", header: [{ text: "動作", align: "center" }],
        htmlEnable: true, align: "center", width:100
    },
    { minWidth: 80, id: "item_no", width:80, header: [{ text: "項次" }] },
    { minWidth: 80, id: "paymentMethod", width:150, header: [{ text: "付款方式" },{content: "selectFilter"}] },
    { minWidth: 80, id: "price", width:100, header: [{ text: "金額" },{content: "inputFilter"}] },
    { minWidth: 80, id: "discount", width:100, header: [{ text: "折價" },{content: "inputFilter"}] },
    { minWidth: 80, id: "note", width:250, header: [{ text: "備註" },{content: "inputFilter"}] },
    { width:200, id: "created_by", header: [{ text: "建立人員" },{content: "inputFilter"}] },
    { width:200, id: "created_at", header: [{ text: "建立時間" },{content: "inputFilter"}] },
    { width:200, id: "updated_by", header: [{ text: "更新人員" },{content: "inputFilter"}] },
    { width:200, id: "updated_at", header: [{ text: "更新時間" },{content: "inputFilter"}] },
];
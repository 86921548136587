export const toolbar1Config = [
    {id: "reload", type: "button", circle: true, value: "重新整理", size: "small", icon: "fa-solid fa-rotate-right"},
    {
        id:"toolbar1_startTime",
        type: "datePicker",
        label: "查詢日期",
        width: "250",
        icon: "fa-solid fa-calendar-days",
        labelWidth: "50px",
        timePicker: true,
        timeFormat: 24,
        dateFormat: "%Y-%m-%d",
        valueFormat:"string",
        value:Date.now(),
    },
    // {id:"add", type: "button", value: "新增訂單", size: "small", icon: "fa-solid fa-plus", circle:true},
];

export const toolbar2Config = [
    {id: "reload", type: "button", circle: true, value: "重新整理", size: "small", icon: "fa-solid fa-rotate-right"},
    {
        id:"toolbar2_startTime",
        type: "datePicker",
        label: "查詢日期",
        width: "250",
        icon: "fa-solid fa-calendar-days",
        labelWidth: "50px",
        timePicker: true,
        timeFormat: 24,
        dateFormat: "%Y-%m-%d",
        valueFormat:"string",
        value:Date.now(),
    },
    {id:"add", type: "button", value: "新增訂單", size: "small", icon: "fa-solid fa-plus", circle:true},
];

export const toolbar3Config = [
    {id: "reload", type: "button", circle: true, value: "重新整理", size: "small", icon: "fa-solid fa-rotate-right"},
    {
        id:"toolbar3_startTime",
        type: "datePicker",
        label: "查詢月份",
        width: "250",
        icon: "fa-solid fa-calendar-days",
        labelWidth: "50px",
        timePicker: true,
        timeFormat: 24,
        dateFormat: "%Y-%m",
        valueFormat:"string",
        value:Date.now(),
    },
    {id:"add", type: "button", value: "新增訂單", size: "small", icon: "fa-solid fa-plus", circle:true},
];

export const toolbar4Config = [
    {id: "reload", type: "button", circle: true, value: "重新整理", size: "small", icon: "fa-solid fa-rotate-right"},
    {
        type: "customHTML",
        html: "<span style='color: red;'>預設列出大於目前時間六個小時及過去未確認提醒事項</span>",
    },
    {
        id:"toolbar4_startTime",
        type: "datePicker",
        label: "開始日期",
        width: "250",
        icon: "fa-solid fa-calendar-days",
        labelWidth: "50px",
        timePicker: true,
        timeFormat: 24,
        dateFormat: "%Y-%m-%d",
        valueFormat:"string",
    },
    {
        id:"toolbar4_endTime",
        type: "datePicker",
        label: "結束日期",
        width: "250",
        icon: "fa-solid fa-calendar-days",
        labelWidth: "50px",
        timePicker: true,
        timeFormat: 24,
        dateFormat: "%Y-%m-%d",
        valueFormat:"string",
    },
    {id:"search", type: "button", value: "搜尋", size: "small", icon: "fa-solid fa-magnifying-glass", circle:true},
];
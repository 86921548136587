export const doorLockSetFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {type: "input", name: "AA002", hidden: true, value: "0"},
    // {
    //     type: "select",
    //     value: "---select---",
    //     label: "Gateway Device No.",
    //     required: true,
    //     options: [
    //         {
    //             value: "0",
    //             content: "---select---",
    //         },
    //     ],
    //     name: "gatewayDeviceNo",
    // },

    {
        cols:[
            {
                type: "select",
                value: "0",
                label: "*房型：",
                width:"49.4%",
                options: [
                    {
                        value: "0",
                        content: "---請選擇---",
                    },
                ],
                name: "AA003",
                css:"rightMargin"
            },
            {
                type: "select",
                value: "0",
                label: "*房間：",
                width:"49.4%",
                disabled: true,
                options: [
                    {
                        value: "0",
                        content: "---請選擇---",
                    },
                ],
                name: "AA004",
            },
        ]
    },
    {
        type: "radioGroup",
        label: "*門鎖類別：",
        options: {
            cols: [
                {
                    type: "radioButton",
                    text: "芝麻雲",
                    value: 1,
                },
                {
                    type: "radioButton",
                    text: "通通鎖",
                    value: 2
                },
            ]
        },
        name: "AA109",
    },
    {
        type: "input",
        label: "*門鎖ID：",
        name: "AA101"
    },
    {
        type: "textarea",
        label: "備註：",
        height: 100,
        name: "AA110"
    },
];
import {localeCal} from "../../../../lang/locales/de";
export const reserveFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {type: "input", name: "AA002", hidden: true, value: "0"},
    {type: "input", name: "AA004", hidden: true, value: "0"},
    {
        cols:[
            {
                type: "datepicker",
                label: "*通報日期：",
                dateFormat: "%Y-%m-%d",
                width:"33%",
                css:"rightMargin",
                name: "AA103",
            },
            {
                type: "datepicker",
                label: "*保留開始日期：",
                dateFormat: "%Y-%m-%d",
                width:"33%",
                css:"rightMargin",
                name: "AA104",
            },
            {
                type: "datepicker",
                label: "*保留結束日期：",
                dateFormat: "%Y-%m-%d",
                width:"33%",
                css:"rightMargin",
                name: "AA105",
            },
        ],
    },
    {
        type: "radioGroup",
        // required: true,
        label: "*保留對象：",
        options: {
            cols: [
                {
                    type: "radioButton",
                    text: "主要產品",
                    width: "49.7%",
                    value: 1,
                },
                {
                    type: "radioButton",
                    text: "加價產品",
                    width: "49.7%",
                    value: 2
                },
            ]
        },
        disabled: true,
        name: "AA100",
    },
    {
        cols:[
            {
                width: "49.7%",
                rows:[
                    {
                        type: "input",
                        css:"rightMargin",
                        disabled:true,
                        placeholder: "請點我選擇",
                        readOnly: true,
                        name: "mainPrd",
                    },
                    {
                        type: "text",
                        value: "(點擊輸入框進入選擇畫面)",
                        // width:"280px",
                        css: "faultReportForm_tip",
                        name: "tip",
                    },
                ],
            },
            {
                width: "49.7%",
                rows:[
                    {
                        type: "select",
                        disabled:true,
                        // width:"280px",
                        options: [
                            {
                                value: "0",
                                content: "---請選擇---",
                            },
                        ],
                        name: "AA005",
                    },
                    {
                        cols:[
                            {
                                type: "text",
                                label: "庫存：",
                                labelPosition: "left",
                                value: "(點擊輸入框進入選擇畫面)",
                                disabled:true,
                                width: "50%",
                                name: "remained",
                            },
                            {
                                type: "input",
                                // css:"rightMargin",
                                label: "數量：",
                                value: "0",
                                inputType:"number",
                                min:0,
                                labelPosition: "left",
                                disabled:true,
                                width: "50%",
                                name: "AA108",
                            },
                        ],
                    }
                ],
            },

        ],
    },
    {
        type: "textarea",
        label: "*保留原因：",
        value: "",
        height: 100,
        name: "AA102",
    },
    {
        type: "textarea",
        label: "備註：",
        value: "",
        height: 100,
        name: "AA106",
    },
];
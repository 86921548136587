export const pricesSetGridForm1LeftConfig = [
    {type: "input", name: "BC001", hidden: true, value: "0"},
    {type: "input", name: "BC003", hidden: true, value: "0"},
    {type: "input", name: "BC100", hidden: true, value: "1"},
    {
        cols:[
            {
                type: "text",
                label: "飯店名稱：",
                width: "49.7%",
                css:"rightMargin",
                name:"hotelName",
            },
            // {
            //     type: "select",
            //     label: "*飯店名稱：",
            //     value: "0",
            //     options: [
            //         {
            //             value: "0",
            //             content: "---請選擇---",
            //         },
            //     ],
            //     width: "49.7%",
            //     css:"rightMargin",
            //     name: "BC003",
            // },
            {
                type: "select",
                label: "*房型：",
                value: "0",
                options: [
                    {
                        value: "0",
                        content: "---請選擇---",
                    },
                ],
                width: "49.7%",
                css:"rightMargin",
                name: "BC002",
            },
        ],
    },
    {
        cols:[
            {
                type: "datepicker",
                label: "*開始日期",
                dateFormat: "%Y-%m-%d",
                css:"rightMargin",
                width: "225px",
                name: "BC101",
            },
            {
                type: "datepicker",
                label: "*結束日期",
                dateFormat: "%Y-%m-%d",
                width: "225px",
                name: "BC102",
            },
        ],
    },
    {
        cols:[
            {
                type: "input",
                label: "*價格",
                css:"rightMargin",
                placeholder: "",
                width: "149px",
                inputType: "number",
                name: "BC103"
            },
            {
                type: "input",
                label: "*同業價格",
                css:"rightMargin",
                placeholder: "",
                width: "149px",
                inputType: "number",
                name: "BC104"
            },
            {
                type: "input",
                label: "*OTA平台價格",
                placeholder: "",
                width: "149px",
                inputType: "number",
                name: "BC105"
            },
        ],
    },
    {
        type: "text",
        label:"小時價格：",
        labelPosition: "left",
    },
    {
        css: "row1",
        id: "pricesSetGridForm1LeftRow1",
        name: "pricesSetGridForm1LeftRow1",
        rows:[
            {
                cols:[
                    {
                        type: "checkbox",
                        label: "",
                        labelWidth: "0px",
                        labelPosition: "left",
                        text: "每小時相同價格",
                        checked: true,
                        name: "BC106",
                        id: "equal",
                    },
                    {
                        type: "input",
                        width: "50px",
                        value: "100",
                        inputType: "number",
                        name: "BC107"
                    },
                    {
                        type: "text",
                        label:"元",
                        labelPosition: "left",
                    },
                ],
            },
            {
                cols:[
                    {
                        type: "input",
                        label: "00:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC108"
                    },
                    {
                        type: "input",
                        label: "00:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC109"
                    },
                    {
                        type: "input",
                        label: "01:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC110"
                    },
                    {
                        type: "input",
                        label: "01:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC111"
                    },
                    {
                        type: "input",
                        label: "02:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC112"
                    },
                    {
                        type: "input",
                        label: "02:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC113"
                    },
                    {
                        type: "input",
                        label: "03:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC114"
                    },
                    {
                        type: "input",
                        label: "03:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC115"
                    },
                    {
                        type: "input",
                        label: "04:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC116"
                    },
                ],
            },
            {
                cols:[
                    {
                        type: "input",
                        label: "04:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC117"
                    },
                    {
                        type: "input",
                        label: "05:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC118"
                    },
                    {
                        type: "input",
                        label: "05:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC119"
                    },
                    {
                        type: "input",
                        label: "06:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC120"
                    },
                    {
                        type: "input",
                        label: "06:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC121"
                    },
                    {
                        type: "input",
                        label: "07:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC122"
                    },
                    {
                        type: "input",
                        label: "07:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC123"
                    },
                    {
                        type: "input",
                        label: "08:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC124"
                    },
                    {
                        type: "input",
                        label: "08:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC125"
                    },
                ],
            },
            {
                cols:[
                    {
                        type: "input",
                        label: "09:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC126"
                    },
                    {
                        type: "input",
                        label: "09:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC127"
                    },
                    {
                        type: "input",
                        label: "10:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC128"
                    },
                    {
                        type: "input",
                        label: "10:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC129"
                    },
                    {
                        type: "input",
                        label: "11:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC130"
                    },
                    {
                        type: "input",
                        label: "11:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC131"
                    },
                    {
                        type: "input",
                        label: "12:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC132"
                    },
                    {
                        type: "input",
                        label: "12:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC133"
                    },
                    {
                        type: "input",
                        label: "13:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC134"
                    },
                ],
            },
            {
                cols:[
                    {
                        type: "input",
                        label: "13:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC135"
                    },
                    {
                        type: "input",
                        label: "14:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC136"
                    },
                    {
                        type: "input",
                        label: "14:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC137"
                    },
                    {
                        type: "input",
                        label: "15:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC138"
                    },
                    {
                        type: "input",
                        label: "15:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC139"
                    },
                    {
                        type: "input",
                        label: "16:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC140"
                    },
                    {
                        type: "input",
                        label: "16:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC141"
                    },
                    {
                        type: "input",
                        label: "17:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC142"
                    },
                    {
                        type: "input",
                        label: "17:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC143"
                    },
                ],
            },
            {
                cols:[
                    {
                        type: "input",
                        label: "18:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC144"
                    },
                    {
                        type: "input",
                        label: "18:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC145"
                    },
                    {
                        type: "input",
                        label: "19:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC146"
                    },
                    {
                        type: "input",
                        label: "19:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC147"
                    },
                    {
                        type: "input",
                        label: "20:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC148"
                    },
                    {
                        type: "input",
                        label: "20:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC149"
                    },
                    {
                        type: "input",
                        label: "21:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC150"
                    },
                    {
                        type: "input",
                        label: "21:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC151"
                    },
                    {
                        type: "input",
                        label: "22:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC152"
                    },
                ],
            },
            {
                cols:[
                    {
                        type: "input",
                        label: "22:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC153"
                    },
                    {
                        type: "input",
                        label: "23:00",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC154"
                    },
                    {
                        type: "input",
                        label: "23:30",
                        labelPosition: "top",
                        labelWidth: "40px",
                        width: "40px",
                        disabled: true,
                        css:"priceRightMargin hours",
                        name: "BC155"
                    },
                ],
            },
        ],
    },
    {
        type: "textarea",
        label: "備註",
        height: 100,
        name: "BC211",
    },
];
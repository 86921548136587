export const faultReportFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {type: "input", name: "AA002", hidden: true, value: "0"},
    {type: "input", name: "AA003", hidden: true, value: "0"},
    {type: "input", name: "AA004", hidden: true, value: "0"},
    {
        type: "text",
        name: "note",
        value: "此功能會扣掉庫存，請謹慎選取",
        css: "faultReportForm_tip",
    },
    {
        cols:[
            {
                type: "datepicker",
                label: "*通報日期：",
                dateFormat: "%Y-%m-%d",
                width:"33%",
                css:"rightMargin",
                name: "AA103",
                value:Date.now(),
            },
            // {
            //     type: "datepicker",
            //     label: "鎖定開始日期：",
            //     dateFormat: "%Y-%m-%d",
            //     width:"33%",
            //     editable:false,
            //     css:"rightMargin",
            //     name: "AA104",
            // },
            // {
            //     type: "datepicker",
            //     label: "鎖定結束日期：",
            //     dateFormat: "%Y-%m-%d",
            //     width:"33%",
            //     editable:false,
            //     css:"rightMargin",
            //     name: "AA105",
            // },
            {
                // type: "datepicker",
                type: "input",
                label: "鎖定開始日期：",
                width:"33%",
                readOnly: true,
                css:"rightMargin",
                name: "AA104",
            },
            {
                // type: "datepicker",
                type: "input",
                label: "鎖定結束日期：",
                width:"33%",
                readOnly: true,
                css:"rightMargin",
                name: "AA105",
            },
        ],
    },
    {
        cols:[
            {
                type: "input",
                label: "房型名稱：",
                css:"rightMargin",
                // disabled:true,
                // placeholder: "請點我選擇",
                readOnly: true,
                width: "49.7%",
                name: "roomType",
            },
            {
                type: "input",
                label: "房間名稱：",
                css:"rightMargin",
                // disabled:true,
                // placeholder: "請點我選擇",
                readOnly: true,
                width: "49.7%",
                name: "roomName",
            },
        ],
    },
    // {
    //     type: "select",
    //     label: "*房型：",
    //     labelPosition: "top",
    //     options: [
    //         {
    //             value: "0",
    //             content: "---請選擇---",
    //         },
    //     ],
    //     // disabled:true,
    //     name: "AA003"
    // },
    // {
    //     type: "select",
    //     label: "*房間名稱：",
    //     labelPosition: "top",
    //     options: [
    //         {
    //             value: "0",
    //             content: "---請選擇---",
    //         },
    //     ],
    //     // disabled:true,
    //     name: "AA004"
    // },
    {
        type: "select",
        label: "*故障原因：",
        options: [
            {
                value: "0",
                content: "---請選擇---",
                disabled: true
            },
        ],
        name: "AA006",
    },
    {
        type: "textarea",
        label: "備註：",
        value: "",
        height: 100,
        name: "AA106",
    },
]
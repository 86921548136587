export const otherPrdBasSetGridColumn = [
    { id: "action", header: [{ text: "動作", align: "center" }],
        htmlEnable: true, align: "center", width:80 },
    { id: "item_no", width:80, header: [{ text: "項次" }] },
    { id: "hotel_name", width:120, header: [{ text: "飯店名稱" },{content: "inputFilter"}] },
    { id: "other_prd_name", width:150, header: [{ text: "加價產品名稱" },{content: "inputFilter"}] },
    { id: "other_prd_status", width:100, header: [{ text: "狀態" },{content: "selectFilter"}],htmlEnable: true },
    { id: "other_prd_price", width:80, header: [{ text: "價格" },{content: "inputFilter"}] },
    { id: "other_prd_unit", width:80, header: [{ text: "單位" },{content: "inputFilter"}] },
    { id: "remains1", width:80, header: [{ text: "設定庫存" },{content: "inputFilter"}] },
    { id: "remains2", width:80, header: [{ text: "剩餘庫存" },{content: "inputFilter"}] },
    { id: "other_prd_no", width:120, header: [{ text: "加價產品編號" },{content: "inputFilter"}] },
    { id: "stop_reason", width:200, header: [{ text: "停用原因" },{content: "inputFilter"}] },
    { width:200, id: "created_by", header: [{ text: "建立人員" },{content: "inputFilter"}] },
    { width:200, id: "created_at", header: [{ text: "建立時間" },{content: "inputFilter"}] },
    { width:200, id: "updated_by", header: [{ text: "更新人員" },{content: "inputFilter"}] },
    { width:200, id: "updated_at", header: [{ text: "更新時間" },{content: "inputFilter"}] },
];